import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {
  ConfigurationInput,
  ConfigurationResponse,
} from '../../interfaces/Configuration';
import reduxActions from '../../redux/actions';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';

interface ConfigurationShaftClearenceValues {
  [key: string]: string | number | null;
}

const FIELDS_BY_SECTIONS = {
  settings: [
    'shaft.delay_after_shift_end',
    'shaft.delay_after_shift_start',
    'shaft.delay_before_shift_end',
    'shaft.delay_before_shift_start',
  ],

  events: ['shaft.disable_miners_migration_event'],
};

const FIELDS = _.flatMap(FIELDS_BY_SECTIONS);

const getFormikValues = (
  item?: ConfigurationResponse
): ConfigurationShaftClearenceValues => ({
  ..._.chain(FIELDS)
    .map((name) => ({name, value: null}))
    .keyBy('name')
    .mapValues('value')
    .value(),

  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('value')
    .value(),
});

const getFormikValuesClear = (
  item?: ConfigurationResponse
): ConfigurationShaftClearenceValues => ({
  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('default')
    .value(),
});

export interface AdminShaftClearenceConfigProps {
  disabled?: boolean;
  onChangeSubmittedInProgress?: (value: boolean) => any;
  onChangeResetInProgress?: (value: boolean) => any;
}

export interface AdminShaftClearenceConfigRef {
  fetch?: Function;
  submit?: Function;
  reset?: Function;
  clear?: Function;
}

export const AdminShaftClearenceConfig = forwardRef<
  AdminShaftClearenceConfigRef,
  React.PropsWithChildren<AdminShaftClearenceConfigProps>
>(({disabled, onChangeSubmittedInProgress, onChangeResetInProgress}, ref) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(),
    submit: () => formik.handleSubmit(),
    reset: () => handleResetData(),
    clear: () => formik.setValues(getFormikValuesClear(fetchedData)),
  }));

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<ConfigurationResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>();
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const params = {section: JSON.stringify(['shaft'])};
      const resp = await API.get<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      setFetchedErrors(error.data);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    fetchData();

    return () => {
      setFetchedData(undefined);
      setFetchedErrors(undefined);
    };
  }, []);

  /**********/
  /* submit */
  /**********/
  // const [submittedData, setSubmittedData] = useState<ConfigurationResponse>();
  // const [submittedErrors, setSubmittedErrors] = useState<string[]>();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const reduxDispatch = useAppDispatch();

  const submitData = async (data: ConfigurationInput) => {
    setSubmittedInProgress(true);

    try {
      const resp = await API.post<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        data
      );
      setFetchedData(resp.data);
      reduxDispatch(reduxActions.app.fetchMyConfigurations);
      enqueueSnackbar('Configuration successfully updated', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      // setSubmittedErrors(error.data);
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setSubmittedInProgress(false);
  };

  /**********/
  /* reset */
  /**********/
  const [resetInProgress, setResetInProgress] = useState(false);

  const resetData = async (data: ConfigurationInput) => {
    setResetInProgress(true);

    try {
      const resp = await API.patch<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        data
      );
      setFetchedData(resp.data);
      reduxDispatch(reduxActions.app.fetchMyConfigurations);
      enqueueSnackbar('Configuration successfully reset', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      // setSubmittedErrors(error.data);
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setResetInProgress(false);
  };

  const handleResetData = async () => {
    const input: ConfigurationInput = {
      section: ['shaft'],
      configuration: _.map(formik.values, (value, key) => ({
        section: key.split('.')[0],
        name: key.split('.')[1],
        value,
      })),
    };
    await resetData(input);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = yup.object();

  const formik = useFormik({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      const input: ConfigurationInput = {
        section: ['shaft'],
        configuration: _.map(values, (value, key) => ({
          section: key.split('.')[0],
          name: key.split('.')[1],
          value,
        })),
      };
      await submitData(input);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!_.isEqual(newInput, formik.values)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  /************/
  /* keysData */
  /************/
  const keysData = useMemo(
    () => ({
      ..._.chain(fetchedData)
        .keyBy((i) => `${i.section}.${i.name}`)
        .mapValues()
        .value(),
    }),
    [fetchedData]
  );

  // other
  useEffect(() => {
    onChangeSubmittedInProgress?.(submittedInProgress);
  }, [submittedInProgress]);

  useEffect(() => {
    onChangeResetInProgress?.(resetInProgress);
  }, [resetInProgress]);

  return (
    <Box position="relative">
      <Backdrop open={fetchedInProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors?.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <Paper sx={{p: 3, mb: 3}}>
        <Box fontSize={20} lineHeight={1.6} mb={3}>
          Settings
        </Box>

        <Grid container spacing={4}>
          {FIELDS_BY_SECTIONS.settings.map((name) => (
            <Grid key={name} item xs={12} lg={6}>
              <TextField
                fullWidth
                label={t(keysData[name]?.label)}
                value={formik.values[name] ?? 'None'}
                name={name}
                type="number"
                size="small"
                select
                disabled={disabled}
                onChange={(event) => {
                  const value =
                    event.target.value === 'None' ? null : event.target.value;
                  formik.setValues({...formik.values, [name]: value});
                }}
              >
                {['None', 10, 30, 60].map((i) => (
                  <MenuItem key={i ?? ''} value={i ?? ''}>
                    {i ?? 'None'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper sx={{p: 3, mb: 3}}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Events
            </Box>

            {FIELDS_BY_SECTIONS.events.map((name) => (
              <TextField
                key={name}
                fullWidth
                label={t(keysData[name]?.label)}
                value={formik.values[name] ?? ''}
                name={name}
                type="number"
                size="small"
                select
                disabled={disabled}
                onChange={(event) => {
                  const value =
                    event.target.value === '' ? null : event.target.value;
                  formik.setValues({...formik.values, [name]: value});
                }}
              >
                {[
                  {value: 0, name: 'No'},
                  {value: 1, name: 'Yes'},
                ].map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.name}
                  </MenuItem>
                ))}
              </TextField>
            ))}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
});
