import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import EmployeeItemsPurgeChatEventsButton from '../../employees/buttons/EmployeeItemsPurgeChatEventsButton';
import CommtracNodeItemsPurgeEventsButton from './CommtracNodeItemsPurgeEventsButton';
import CommtracNodeItemsPurgeNodeButton from './CommtracNodeItemsPurgeNodeButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  employeeIds: number[];
  assetIds: number[];
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const CommtracNodeItemsPurgeButton = <T extends ComponentType = typeof Button>({
  employeeIds,
  assetIds,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <CommtracNodeItemsPurgeNodeButton
          employeeIds={employeeIds}
          assetIds={assetIds}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          All
        </CommtracNodeItemsPurgeNodeButton>

        <CommtracNodeItemsPurgeEventsButton
          employeeIds={employeeIds}
          assetIds={assetIds}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          Transactions
        </CommtracNodeItemsPurgeEventsButton>

        {employeeIds.length ? (
          <EmployeeItemsPurgeChatEventsButton
            ids={employeeIds}
            component={MenuItem}
            onDone={() => {
              setMenuAnchorEl(undefined);
              onDone?.();
            }}
          >
            Chat
          </EmployeeItemsPurgeChatEventsButton>
        ) : null}
      </Menu>
    </>
  );
};

export default CommtracNodeItemsPurgeButton;
