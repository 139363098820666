import CloseIcon from '@mui/icons-material/Close';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {Box, Button, IconButton} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import ModalFixed from '../common/ModalFixed';
import AssetHumanTypeExport from './AssetHumanTypeExport';
// import AssetHumanTypeImport from "./AssetHumanTypeImport";

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
}

const AssetHumanTypeImportExportButton = <T extends ComponentType>({
  component,
  componentProps,
  children,
  // onSubmitted,
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Component {...componentProps} onClick={() => setIsOpened(true)}>
        {children}
      </Component>

      {isOpened ? (
        <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            gap={3}
            p={3}
          >
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center" width="100%" gap={1.5}>
                <ImportExportIcon sx={{color: 'primary.main'}} />
                <Box fontSize={24}>Export Employee Types</Box>
              </Box>
              <IconButton onClick={() => setIsOpened(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            {/* <Divider>Export</Divider> */}
            <AssetHumanTypeExport />
            {/* <Divider>Import</Divider>
            <AssetHumanTypeImport
              onSubmitted={() => {
                setIsOpened(false);
                onSubmitted?.();
              }}
            /> */}
          </Box>
        </ModalFixed>
      ) : null}
    </>
  );
};

export default AssetHumanTypeImportExportButton;
