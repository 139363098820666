import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadingButton} from '@mui/lab';
import {Box, ButtonGroup, MenuItem, TextField, Tooltip} from '@mui/material';
import dayjs from 'dayjs';
import {t} from 'i18next';
import update from 'immutability-helper';
import {isEmpty} from 'lodash';
import {useCallback, useEffect, useRef, useState} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {saveFile} from '../../../utils/file';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {MachineInputSelectV2} from '../../selectors/MachineInputSelectV2';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import {getTopEmployeeInteractionsData} from './data';
import TopEmployeeShiftGrid, {
  TopEmployeeShiftGridRef,
} from './TopEmployeeShiftGrid';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

export const TopEmployeeInteractions = (props: Props) => {
  const [panel] = usePanel();
  const [config, setConfig] = useState(
    !isEmpty(props.value) ? props.value : getTopEmployeeInteractionsData()
  );
  const shifts = useAppSelector(({assets}) => assets.shifts);
  const [exportInProgress, setExportInProgress] = useState(false);

  const shiftRefs = useRef<(TopEmployeeShiftGridRef | null)[]>([]);

  const refresh = useCallback(() => {
    shiftRefs.current.forEach((ref) => ref?.refresh());
  }, []);

  /****************/
  /* auto refresh */
  /****************/
  useRefreshInterval(() => refresh(), config?.refreshInterval);

  const submitExport = async () => {
    setExportInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/report/top-bottom-interactions-export`;
      const resp = await API.get(endpoint, {
        params: {
          ...config.params,
          input: JSON.stringify(config.params?.input),
        },
        responseType: 'blob',
      });
      const filename =
        resp?.headers['content-disposition']?.split('filename=')?.[1] ||
        'top_employee_interactions_report.xlsx';
      saveFile(resp.data, filename);
    } catch (error: any) {
      /* empty */
    } finally {
      setExportInProgress(false);
    }
  };

  useEffect(() => {
    props.onUpdate?.({...config});
  }, [config.params]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      gap={3}
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Box display="flex" overflow="auto" py={1} gap={1}>
            <Box minWidth={400}>
              <DateRangeSelect
                value={[
                  dayjs(config.params?.date_start).toDate(),
                  dayjs(config.params?.date_end).toDate(),
                ]}
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...config.params,
                          date_start: v?.[0]
                            ? dayjs(v?.[0]).format('YYYY-MM-DD')
                            : undefined,
                          date_end: v?.[0]
                            ? dayjs(v?.[1]).format('YYYY-MM-DD')
                            : undefined,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>

            <ZoneSelect
              value={config.params?.zone_id}
              size="small"
              nullLabel="All Sections"
              sx={{minWidth: 200}}
              onChange={(v) => {
                setConfig?.(
                  update(config, {
                    params: {
                      zone_id: {
                        $set: v,
                      },
                    },
                  })
                );
              }}
            />

            <Box width={200}>
              <MachineInputSelectV2
                value={config.params?.input}
                label="Inputs"
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...config.params,
                          input: v,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>

            <Box width={180}>
              <TextField
                value={config.params?.dir ?? 'ASC'}
                fullWidth
                select
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        dir: {
                          $set: v.target.value as any,
                        },
                      },
                    })
                  );
                }}
              >
                <MenuItem value="DESC">Top</MenuItem>
                <MenuItem value="ASC">Bottom</MenuItem>
              </TextField>
            </Box>

            <Box width={180}>
              <TextField
                value={config.params?.human_type_report ? 1 : 0}
                fullWidth
                select
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        human_type_report: {
                          $set: (v.target.value as any) === 1,
                        },
                      },
                    })
                  );
                }}
              >
                <MenuItem value={0}>Employee</MenuItem>
                <MenuItem value={1}>Employee Type</MenuItem>
              </TextField>
            </Box>
          </Box>

          <Box display="flex">
            <ButtonGroup>
              <Tooltip title="Reload">
                <LoadingButton
                  size="small"
                  variant="outlined"
                  onClick={refresh}
                >
                  <RefreshIcon />
                </LoadingButton>
              </Tooltip>

              <Tooltip title="Export to Excel">
                <LoadingButton
                  size="small"
                  loading={exportInProgress}
                  variant="outlined"
                  onClick={() => submitExport()}
                >
                  <FileDownloadIcon />
                </LoadingButton>
              </Tooltip>

              <AutoRefreshSelect
                value={config?.refreshInterval ?? null}
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      refreshInterval: {
                        $set: v,
                      },
                    })
                  );
                }}
              />
            </ButtonGroup>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        height="100%"
        gap={1}
        p={1}
        sx={{
          overflowX: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : undefined,
        }}
      >
        {shifts.map((shift, idx) => (
          <Box
            key={shift.id}
            display="flex"
            flexDirection="column"
            gap={1}
            width="33%"
            minWidth={300}
            height="100%"
          >
            <Box px={1} fontWeight={600}>
              {shift.name}
            </Box>
            <TopEmployeeShiftGrid
              ref={(el) => {
                shiftRefs.current[idx] = el;
              }}
              shiftId={shift.id}
              config={config}
              onChange={(v) => setConfig(v)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
