/* eslint-disable @typescript-eslint/no-unused-vars */
import {DivIcon} from 'leaflet';
import _ from 'lodash';
import {Marker, Popup, Tooltip} from 'react-leaflet';

import {useConfigurations} from '../../../hooks/configuration';
import {CommtracNode} from '../../../interfaces/CommtracNode';
import {
  ASSET_EVENT_COLORS,
  ASSET_EVENT_TYPES,
  MINER_EVENT_COLORS,
  MINER_EVENT_TYPES,
} from '../../../utils/commtrac-nodes';
import {COLORED_LEAFLET_ICONS} from '../../../utils/icons';
import CommtracNodeTooltipContent from './CommtracNodeTooltipContent';
const duplicateIcon = require('../../../images/other/duplicate_icon.png');

interface Props {
  type: 'employee' | 'asset';
  i: {
    key: string;
    items: CommtracNode[];
  };
  // i: CommtracNode;
  disableEditMode?: boolean;
  onOpenItem?: (
    id: number,
    type: 'cn' | 'wifi' | 'employee' | 'asset' | 'alarm'
  ) => void;
}

const EmployeeAssetsMarker = ({
  type,
  i,
  disableEditMode,
  onOpenItem,
}: Props) => {
  const configurations = useConfigurations();
  const getEmployeeIcon = (item: CommtracNode) => {
    let color: string | number | undefined;
    if (item.e_209) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_NEW_MINER_DETECTED] ?? ''
        ]?.value;
    } else if (item.e_201) {
      color =
        configurations[MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_ALARM] ?? '']
          ?.value;
    } else if (item.e_221) {
      color =
        configurations[
          MINER_EVENT_COLORS[
            MINER_EVENT_TYPES.MINER_TOO_MANY_LOCATION_MESSAGES
          ] ?? ''
        ]?.value;
    } else if (item.e_202) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_NO_LOCATION] ?? ''
        ]?.value;
    } else if (item.e_226) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_INVALID_LAST_LOCATION] ??
            ''
        ]?.value;
    } else if (item.e_203) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_LOW_BATTERY] ?? ''
        ]?.value;
    } else if (item.e_204) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_NEW_MESSAGE] ?? ''
        ]?.value;
    } else if (item.e_233) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_NOT_MOVING_STATE] ?? ''
        ]?.value;
    }
    return COLORED_LEAFLET_ICONS.miners[color ?? 'default'];
  };

  const getAssetIcon = (item: CommtracNode) => {
    let color: string | number | undefined;
    if (item.e_309) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_NEW_ASSET_DETECTED] ?? ''
        ]?.value;
    } else if (item.e_321) {
      color =
        configurations[
          ASSET_EVENT_COLORS[
            ASSET_EVENT_TYPES.ASSET_TOO_MANY_LOCATION_MESSAGES
          ] ?? ''
        ]?.value;
    } else if (item.e_301) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_NO_LOCATION] ?? ''
        ]?.value;
    } else if (item.e_326) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_INVALID_LAST_LOCATION] ??
            ''
        ]?.value;
    } else if (item.e_302) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_LOW_BATTERY] ?? ''
        ]?.value;
    }
    return COLORED_LEAFLET_ICONS.assetEvents[color ?? 'default'];
  };
  return (
    <Marker
      position={[
        i.items[0].commtrac_pos_lat ?? 0,
        i.items[0].commtrac_pos_lon ?? 0,
      ]}
      icon={
        i.items.length === 1
          ? type === 'employee'
            ? getEmployeeIcon(i.items[0])
            : getAssetIcon(i.items[0])
          : new DivIcon({
              className: 'duplicate icon',
              html: `<img src="${duplicateIcon}" /><span>${i.items.length}</span>`,
              iconAnchor: [16, 37],
            })
      }
      // icon={type === 'employee' ? getEmployeeIcon(i) : getAssetIcon(i)}
      interactive
      eventHandlers={{
        click: (event) => event.target.openPopup(),
        popupopen: (event) => {
          event.target._tooltip.options.opacity = 0;
        },
        popupclose: (event) => {
          event.target._tooltip.options.opacity = 0.9;
        },
      }}
    >
      <Popup interactive autoClose={false} offset={[0, -32]}>
        <CommtracNodeTooltipContent
          item={i.items[0]}
          items={i.items}
          disableEditMode={disableEditMode}
          onOpenItem={(item) => {
            if (item) {
              onOpenItem?.(item?.employee_asset_id ?? -1, type);
            } else {
              onOpenItem?.(i.items[0].employee_asset_id ?? -1, type);
            }
          }}
        />
      </Popup>

      <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
        <CommtracNodeTooltipContent
          disableEditMode={disableEditMode}
          item={i.items[0]}
          items={i.items}
          onOpenItem={(item) => {
            if (item) {
              onOpenItem?.(item?.employee_asset_id ?? -1, type);
            } else {
              onOpenItem?.(i.items[0].employee_asset_id ?? -1, type);
            }
          }}
        />
      </Tooltip>
    </Marker>
  );
};

export default EmployeeAssetsMarker;
