import {PlayCircle, Settings} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Slider,
  Tab,
  Tabs,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import _, {debounce} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import usePrevious from '../../../hooks/usePrevious';
import {ExportField} from '../../../interfaces/Export';
import {
  HazardHeatmapReport,
  HazardHeatmapReportListQuery,
  HazardHeatmapReportListResponse,
} from '../../../interfaces/HazardHeatmapReport';
import {SafeyeNode} from '../../../interfaces/SafeyeNode';
import reduxSelectors from '../../../redux/selectors';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {getDistance, normalizeIntensity} from '../../../utils/heatmap';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {HazardMap, MapType} from '../../common/HazardMap';
import {MapLatLangCoordinates, MapLayerId} from '../../common/Map';
import NumberTextField from '../../common/NumberTextField';
import {ResizableColumns} from '../../common/ResizableColumns';
import HazardHeatmapObjectItemEditButton from '../../hazard-heatmap/HazardHeatmapMachineItemEditButton';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {SafeyeNodeSelector} from '../../selectors/SafeyeNodeSelector';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import HeatmapExportButton from './HeatmapExportButton';
import {HeatmapObjectSelector} from './HeatmapObjectSelector';
import {HeatmapZoneSelector} from './HeatmapZoneSelector';

interface Props {
  value?: HazardHeatmapReportData;
  onUpdate?: (value?: HazardHeatmapReportData) => void;
  onRefresh?: () => void;
  onOpenEventList?: (
    pos?: MapLatLangCoordinates,
    radius?: number,
    openedItem?: HazardHeatmapReportDataTab | null
  ) => void;
  onClickMedia?: (heatmapId?: number) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'id',
  'safeye_node_id',
  'image',
  'machine_id',
  'machine_name',
  'type',
  'object',
  'zone',
  'latitude',
  'longitude',
  'speed',
  'confidence',
  'distance',
  'timestamp',
];

export interface HazardHeatmapReportDataTab {
  id: string;
  params: Partial<HazardHeatmapReportListQuery>;
  selectedIds: number[];
  selectAll: boolean;
  shownFields: string[];
  selectedMachineId: number | null | number[];
  grid: {
    pageSize: number;
    page: number;
  };
  refreshInterval?: number | null;
  exportFields: ExportField[];
  heatmapConfig: HeatmapConfig | null;
}
export interface HazardHeatmapReportData {
  activeId?: string;
  openedItems: HazardHeatmapReportDataTab[];
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  mapType?: MapType;
}

export const getHazardHeatMapReportData = (): HazardHeatmapReportData => {
  return {
    activeId: undefined,
    openedItems: [],
    mapLayers: ['street', 'heatmap'],
    mapLevel: 2,
  };
};

export const getHazardHeatmapReportDataTab = (
  id: string,
  safeye_nodes: SafeyeNode[]
): HazardHeatmapReportDataTab => {
  const allSelectedSafeNodeIds = id?.length > 0 ? id?.split('-') : [];
  let safeye_node_id = null;
  if (allSelectedSafeNodeIds) {
    safeye_node_id = allSelectedSafeNodeIds
      ?.map(
        (id) =>
          safeye_nodes.find((it) => `${it.id}` === `${id}`)?.safeye_node_id ??
          null
      )
      ?.filter((it) => !!it);
  }
  return {
    id,
    params: {
      page: 0,
      limit: 25,
      order: null,
      dir: 'ASC',
      date_start: dayjs().format('YYYY-MM-DD'),
      date_end: dayjs().format('YYYY-MM-DD'),
      object: null,
      safeye_node_id: safeye_node_id,
      zone: null,
      speed_min: 0,
      speed_max: 200,
    },
    shownFields: DEFAULT_SHOWN_FIELDS,
    selectedIds: [],
    selectAll: true,
    selectedMachineId: null,
    grid: {
      page: 0,
      pageSize: 25,
    },
    exportFields: [],
    heatmapConfig: null,
  };
};

export interface HazardHeatMapData {
  id: number | null;
  address_points: Array<[number, number, number]> | null;
  configuration: {
    blur: number;
    radius: number;
    max: number;
    color_gradients: {
      intensity: number;
      color: string;
    }[];
  } | null;
  isSelected: boolean;
}

export type HeatmapConfig = {
  density_radius: number;
  blur: number;
  radius: number;
  max: number;
  color_gradients: {
    intensity: number;
    color: string;
  }[];
} & {
  id: number | null;
};

const HazardHeatmapGrid = ({
  value,
  onUpdate,
  onRefresh,
  onClickMedia,
  onOpenEventList,
}: Props) => {
  const isOpenAwayFromConnectView = useMemo(() => {
    return (
      location.pathname.includes('/panels/') ||
      !document.querySelector('div[data-panel-code="HazardAI"]')
    );
  }, [location, value]);

  const assets = useSelector(reduxSelectors.assets.getAssets);
  const [heatMapData, setHeatMapData] = useState<HazardHeatMapData[]>([]);
  const [rangeValue, setRangeValue] = useState<{
    min: number;
    max: number;
  }>({
    min: 5,
    max: 200,
  });

  const config = useMemo(() => {
    if (!isOpenAwayFromConnectView) {
      const v = value ?? getHazardHeatMapReportData();
      return {
        ...v,
        mapLayers: v.mapLayers ?? ['heatmap', 'street'],
        mapLevel: v.mapLevel ?? null,
      };
    } else {
      const v = value ?? getHazardHeatMapReportData();
      if (value?.activeId === undefined || value?.activeId === null) {
        const openedItem: HazardHeatmapReportDataTab =
          getHazardHeatmapReportDataTab('', assets.safeye_nodes);

        return {
          ...v,
          activeId: '',
          openedItems: [openedItem],
          mapLayers: v.mapLayers ?? ['heatmap', 'street'],
          mapLevel: v.mapLevel ?? null,
        };
      } else {
        return {
          ...v,
          mapLayers: v.mapLayers ?? ['heatmap', 'street'],
          mapLevel: v.mapLevel ?? null,
        };
      }
    }
  }, [value, isOpenAwayFromConnectView]);
  const openedItemIndex = useMemo(
    () =>
      (config.activeId
        ? config.openedItems.findIndex((i) => i.id === config.activeId)
        : null) ?? config.openedItems.length - 1,
    [config.activeId, config.openedItems]
  );

  const openedItem: HazardHeatmapReportDataTab | null = useMemo(() => {
    const item =
      openedItemIndex !== -1
        ? config.openedItems[openedItemIndex] &&
          config.openedItems[openedItemIndex].params
          ? config.openedItems[openedItemIndex]
          : getHazardHeatmapReportDataTab(
              config.activeId as string,
              assets.safeye_nodes
            )
        : null;
    return item;
  }, [openedItemIndex, config.openedItems, assets.safeye_nodes]);

  useEffect(() => {
    if (openedItemIndex > -1) {
      setRangeValue({
        min: config.openedItems[openedItemIndex].params?.speed_min ?? 0,
        max: config.openedItems[openedItemIndex].params?.speed_max ?? 200,
      });
    }
  }, [openedItemIndex]);

  const tabNames = useMemo(
    () =>
      config.openedItems.map((o) => {
        const allSelectedSafeNodeIds = o.id?.length > 0 ? o.id?.split('-') : [];
        if (allSelectedSafeNodeIds?.length > 0) {
          const matchedMachineName = assets.safeye_nodes?.find(
            (it) => `${it.id}` === `${allSelectedSafeNodeIds[0]}`
          )?.asset_name;
          if (allSelectedSafeNodeIds?.length > 1) {
            return `${matchedMachineName} (+${allSelectedSafeNodeIds.length - 1})`;
          } else {
            return `${matchedMachineName}`;
          }
        } else {
          return '';
        }
      }),
    [config.openedItems, assets.safeye_nodes]
  );

  const [fetchedData, setFetchedData] =
    useState<HazardHeatmapReportListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const [prevParams, setPrevParams] =
    useState<HazardHeatmapReportListQuery | null>(null);
  const params = useMemo<HazardHeatmapReportListQuery | null>(
    () =>
      openedItem?.id !== null &&
      openedItem?.id !== undefined &&
      openedItem.params.safeye_node_id &&
      openedItem.params.safeye_node_id?.length > 0
        ? {
            safeye_node_id: openedItem.params.safeye_node_id?.includes('all')
              ? null
              : openedItem.params.safeye_node_id ?? null,
            date_start:
              openedItem.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
            date_end:
              openedItem.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
            page: openedItem.params?.page ?? 0,
            limit: openedItem.params?.limit ?? 25,
            order: null,
            object: openedItem?.params?.object ?? null,
            zone: openedItem?.params?.zone ?? null,
            dir: 'ASC',
            speed_min: openedItem.params?.speed_min ?? 0,
            speed_max: openedItem.params?.speed_max ?? 200,
          }
        : null,
    [openedItem?.id, openedItem?.params]
  );

  const fetchData = useCallback(
    async (params: HazardHeatmapReportListQuery) => {
      setPrevParams(params);
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/hazard-ai/detection-heat-map`;
        const resp = await API.get<any>(endpoint, {
          params,
        });
        setFetchedData({
          items: (resp?.data ?? [])?.map((_it: any) => {
            const machine_id =
              assets?.asset_machines?.find(
                (it) => `${it?.safeye_node_id}` === `${_it.safeye_node_id}`
              )?.id ?? 'Not defined';
            const machine_name =
              assets?.asset_machines?.find(
                (it) => `${it?.safeye_node_id}` === `${_it.safeye_node_id}`
              )?.name ?? 'Not defined';
            return {
              ..._it,
              machine_id,
              machine_name,
            };
          }),
        });
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      } finally {
        setFetchedInProgress(false);
      }
    },
    [params]
  );

  useEffect(() => {
    if (params && !_.isEqual(params, prevParams) && !fetchedInProgress) {
      fetchData(params);
    }
  }, [params, prevParams, fetchedInProgress]);

  useEffect(() => {
    if (!openedItem) {
      setFetchedData(undefined);
    }
  }, [openedItem]);

  /****************/
  /* auto refresh */
  /****************/
  const callFetchData = useCallback(() => {
    params && fetchData(params);
  }, [params]);

  useRefreshInterval(callFetchData, openedItem?.refreshInterval);

  /*************/
  /* data grid */
  /*************/

  const dataGridRef = useRef<DataGridRef>(null);
  const rows = useMemo(() => fetchedData?.items ?? [], [fetchedData]);

  const columns: DataGridColumn<HazardHeatmapReport>[] = [
    {
      field: 'select',
      type: 'select',
      doNotExport: true,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(row.id)}
          onChange={() => toggleSelectItem(row.id)}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'safeye_node_id',
      headerName: 'Safeye Node',
      sortable: true,
    },
    {
      field: 'image',
      headerName: 'Image',
      doNotExport: true,
      renderCell: ({row}) => {
        return (
          <Box width="100%" display="flex" justifyContent="center">
            <IconButton
              onClick={() => {
                onClickMedia?.(row?.id ?? '');
              }}
            >
              <PlayCircle />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: 'machine_id',
      headerName: 'Machine ID',
      sortable: true,
      doNotExport: true,
      hidden: (openedItem?.params?.safeye_node_id?.length ?? 0) <= 1,
    },
    {
      field: 'machine_name',
      sortable: true,
      headerName: 'Machine Name',
      doNotExport: true,
      hidden: (openedItem?.params?.safeye_node_id?.length ?? 0) <= 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: true,
      renderCell: ({row}) => {
        return <Box textTransform="capitalize">{row.type}</Box>;
      },
    },
    {
      field: 'object',
      headerName: 'Object',
      sortable: true,
      renderCell: ({row}) => {
        return <Box textTransform="capitalize">{row.object}</Box>;
      },
    },
    {
      field: 'zone',
      headerName: 'Zone',
      sortable: true,
      renderCell: ({row}) => {
        return <Box textTransform="capitalize">{row.zone}</Box>;
      },
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
      sortable: true,
    },
    {
      field: 'longitude',
      headerName: 'Longitude',
      sortable: true,
    },
    {
      field: 'speed',
      headerName: 'Speed',
      sortable: true,
    },
    {
      field: 'confidence',
      headerName: 'Confidence',
      sortable: true,
    },
    {
      field: 'distance',
      headerName: 'Distance',
      sortable: true,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      sortable: true,
    },
  ];

  const shownFields = useMemo(() => {
    return openedItem?.shownFields;
  }, [openedItem]);

  useEffect(() => {
    if (openedItemIndex > -1) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              exportFields: {
                $set: columns
                  .filter((col) => !col.doNotExport)
                  .map((col) => ({
                    field: col.field,
                    label: col.headerName,
                    hidden: shownFields?.indexOf(col.field) === -1,
                  })),
              },
            },
          },
        })
      );
    }
  }, [shownFields]);

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = openedItem?.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i) => openedItem?.selectedIds?.includes(i.id)),
    [rows, selectedItems]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (openedItem) {
      if (selectedItems?.includes(id)) {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: selectedItems.filter((i) => i !== id),
                  selectAll: false,
                },
              },
            },
          })
        );
      } else {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: [...(selectedItems ?? []), id],
                  selectAll:
                    selectedItems.length + 1 === fetchedData?.items.length,
                },
              },
            },
          })
        );
      }
    }
  };

  const selectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: true,
              },
              selectedIds: {
                $set: rows?.map((i) => i.id) ?? [],
              },
            },
          },
        })
      );
    }
  };

  const unselectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: false,
              },
              selectedIds: {
                $set: [],
              },
            },
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      fetchedData &&
      openedItem?.selectedIds &&
      openedItem.selectedIds.length !== selectedRows.length
    ) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectedIds: {
                $set: selectedRows.map((i) => i.id),
              },
            },
          },
        })
      );
    }
  }, [openedItem?.selectedIds, fetchedData]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  const handleChangeShownFields = (fields: string[]) => {
    onUpdate?.(
      update(config, {
        openedItems: {
          [openedItemIndex]: {
            shownFields: {$set: fields},
          },
        },
      })
    );
  };

  useEffect(() => {
    const addressPoints: Array<[number, number, number]> = [];
    selectedItems?.forEach((it) => {
      const matchedObj = fetchedData?.items?.find(
        (_it) => `${_it?.id}` === `${it}`
      );
      if (matchedObj && matchedObj.speed > 0) {
        const densityValueByDeviceCount =
          fetchedData?.items.filter((it) => {
            const distance: number = getDistance(
              matchedObj.latitude,
              matchedObj.longitude,
              it.latitude,
              it.longitude
            );
            return (
              distance <= (openedItem?.heatmapConfig?.density_radius ?? 20) &&
              distance >= 0.01
            );
          }).length ?? 1;

        addressPoints.push([
          Number(matchedObj.latitude),
          Number(matchedObj.longitude),
          densityValueByDeviceCount,
        ]);
      }
    });

    let maxIntensity = 0;
    addressPoints.forEach((it) => {
      if (it[2] > maxIntensity) {
        maxIntensity = it[2];
      }
    });

    const mapData: HazardHeatMapData[] = [
      {
        id: 1,
        address_points: addressPoints.map((it) => [
          it[0],
          it[1],
          it[2] / maxIntensity,
        ]),
        configuration: openedItem?.heatmapConfig ?? null,
        isSelected: true,
      },
    ];

    setHeatMapData(mapData);
  }, [selectedItems, fetchedData, openedItem?.heatmapConfig]);

  /********************/
  /* refresh interval */
  /********************/

  const debouncedSetData = useCallback(
    debounce((newValue: {min: number; max: number}) => {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              params: {
                $set: {
                  ...openedItem?.params,
                  speed_min: newValue.min,
                  speed_max: newValue.max,
                },
              },
            },
          },
        })
      );
    }, 500),
    [openedItem]
  );
  useEffect(() => {
    debouncedSetData(rangeValue);
  }, [rangeValue]);

  const handleChange1 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setRangeValue({
        ...rangeValue,
        min: Math.min(newValue[0], openedItem?.params.speed_max ?? 200 - 10),
      });
    } else {
      setRangeValue({
        ...rangeValue,
        max:
          Math.max(newValue[1], openedItem?.params.speed_min ?? 0 + 10) > 0
            ? Math.max(newValue[1], openedItem?.params.speed_min ?? 0 + 10)
            : 0,
      });
    }
  };

  const selectedMachines = useMemo(() => {
    return (
      openedItem?.params?.safeye_node_id
        ?.map(
          (it) =>
            assets.safeye_nodes.find((_it) => _it.safeye_node_id === it)
              ?.asset_name ?? null
        )
        ?.filter((it) => !!it) ?? []
    );
  }, [openedItem?.params?.safeye_node_id, assets.safeye_nodes]);

  const handleSafeyeNodeChange = (v: number[] | null) => {
    if (openedItemIndex > -1) {
      const node_ids =
        v && v?.length > 0
          ? v
              .map(
                (it) =>
                  assets.safeye_nodes?.find((_it) => `${_it.id}` === `${it}`)
                    ?.safeye_node_id ?? null
              )
              ?.filter((it) => !!it)
          : null;
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              params: {
                $set: {
                  ...openedItem?.params,
                  safeye_node_id: v ? node_ids : ['all'],
                },
              },
            },
          },
        })
      );
    }
  };

  return (
    <>
      <DashboardPanelTitleSlot>HazardAI Heat Map</DashboardPanelTitleSlot>
      {fetchedErrors?.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      {isOpenAwayFromConnectView && (
        <Box p={2} display="flex" flexDirection="column">
          <SafeyeNodeSelector
            size="small"
            multiple
            allOptionDisabled
            value={
              openedItem?.params.safeye_node_id?.includes('all')
                ? []
                : openedItem?.params.safeye_node_id
                  ? assets?.safeye_nodes
                      ?.filter(
                        (it) =>
                          openedItem?.params.safeye_node_id &&
                          openedItem?.params.safeye_node_id?.findIndex(
                            (_it) => _it && _it === it?.safeye_node_id
                          ) > -1
                      )
                      ?.map((it) => it.id)
                  : null
            }
            onChange={(v) => handleSafeyeNodeChange(v as number[])}
          />
        </Box>
      )}
      {!isOpenAwayFromConnectView && (
        <>
          {config.openedItems.length ? (
            <Box px={2} mb={2}>
              <Tabs
                value={openedItem?.id}
                variant="scrollable"
                onChange={(_event, v) => {
                  if (v) {
                    onUpdate?.(
                      update(config, {
                        activeId: {
                          $set: v,
                        },
                      })
                    );
                  }
                }}
              >
                {config.openedItems.map((i, idx) => (
                  <Tab
                    key={i.id}
                    value={i.id}
                    label={
                      <Box display="flex" alignItems="center" gap={1}>
                        <Box>{tabNames?.[idx] ?? 'Undefined'}</Box>
                        <CloseIcon
                          fontSize="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            onUpdate?.(
                              update(value, {
                                activeId: {
                                  $set:
                                    config.activeId === i.id
                                      ? undefined
                                      : config.activeId,
                                },
                                openedItems: {
                                  $set:
                                    config.openedItems.filter(
                                      (o) => o.id && o.id !== i.id
                                    ) ?? [],
                                },
                              })
                            );
                          }}
                        />
                      </Box>
                    }
                  />
                ))}
              </Tabs>
            </Box>
          ) : (
            <Box minWidth={400} px={1.5}>
              <Alert severity="warning">No Machines Selected</Alert>
            </Box>
          )}
        </>
      )}
      {!isOpenAwayFromConnectView && selectedMachines?.length > 0 && (
        <Box px={2} display="flex" flexWrap="wrap" columnGap="5px" rowGap="5px">
          {selectedMachines?.map((it: string | null, index: number) => (
            <Chip
              label={it}
              key={index}
              sx={{
                borderRadius: '5px',
              }}
            ></Chip>
          ))}
        </Box>
      )}

      <Box
        overflow="auto"
        height={70}
        minHeight="70px"
        sx={{
          overflowY: 'hidden',
        }}
      >
        <Box
          p={2}
          minWidth={550}
          height={70}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <HazardHeatmapObjectItemEditButton
            item={openedItem?.heatmapConfig}
            pickedProperty="speed"
            component={Button}
            componentProps={{
              color: 'primary',
              size: 'small',
              variant: 'outlined',
              sx: {
                width: '40px',
                height: '40px',
                minWidth: '40px',
              },
            }}
            onSave={(v: HeatmapConfig) => {
              openedItem &&
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          heatmapConfig: v,
                        },
                      },
                    },
                  })
                );
            }}
          >
            <Settings />
          </HazardHeatmapObjectItemEditButton>
          <Box
            display="flex"
            height="100%"
            sx={{
              marginLeft: 'auto',
            }}
          >
            <Box flexGrow={1} display="flex" height="100%">
              <Box minWidth={300} maxWidth={300}>
                <DateRangeSelect
                  value={[
                    dayjs(openedItem?.params?.date_start).toDate(),
                    dayjs(openedItem?.params?.date_end).toDate(),
                  ]}
                  size="small"
                  onChange={(v) => {
                    openedItem &&
                      onUpdate?.(
                        update(config, {
                          openedItems: {
                            [openedItemIndex]: {
                              params: {
                                $set: {
                                  ...openedItem?.params,
                                  date_start: v?.[0]
                                    ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                    : undefined,
                                  date_end: v?.[1]
                                    ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                    : undefined,
                                },
                              },
                            },
                          },
                        })
                      );
                  }}
                />
              </Box>
            </Box>
            <Box
              px={2}
              mx={2}
              minWidth={400}
              display="flex"
              alignItems="center"
              columnGap="14px"
            >
              <NumberTextField
                label="Min."
                step={10}
                min={0}
                max={rangeValue.max}
                sx={{
                  width: '80px',
                }}
                inputProps={{
                  sx: {
                    height: '40px',
                    boxSizing: 'border-box',
                    padding: '10px 5px',
                    textAlign: 'center',
                  },
                  onCut: (e) => {
                    e.preventDefault();
                  },
                  onCopy: (e) => {
                    e.preventDefault();
                  },
                  onPaste: (e) => {
                    e.preventDefault();
                  },
                  onKeyDown: (e) => {
                    if (e.key === 'Backspace' || e.key === 'Delete') {
                      if (rangeValue?.min?.toString().length < 2) {
                        e.preventDefault();
                      }
                    }
                  },
                }}
                value={rangeValue?.min}
                onChange={(v) => {
                  setRangeValue({
                    ...rangeValue,
                    min:
                      Number(v) < 0
                        ? 0
                        : Number(v) > Number(rangeValue?.max)
                          ? Number(rangeValue?.max)
                          : Number(v),
                  });
                }}
              ></NumberTextField>
              <Slider
                getAriaLabel={() => 'Minimum distance'}
                value={[rangeValue.min ?? 0, rangeValue.max ?? 200]}
                min={0}
                max={200}
                step={10}
                onChange={handleChange1}
                valueLabelDisplay="off"
                aria-labelledby="input-slider"
                disableSwap
                sx={{
                  flex: 1,
                }}
              />
              <NumberTextField
                label="Max."
                sx={{
                  width: '80px',
                }}
                step={10}
                min={rangeValue.min}
                value={rangeValue?.max ?? 200}
                max={200}
                inputProps={{
                  sx: {
                    height: '40px',
                    boxSizing: 'border-box',
                    textAlign: 'center',
                    padding: '10px 5px',
                  },
                  onCut: (e) => {
                    e.preventDefault();
                  },
                  onCopy: (e) => {
                    e.preventDefault();
                  },
                  onPaste: (e) => {
                    e.preventDefault();
                  },
                  onKeyDown: (e) => {
                    if (e.key === 'Backspace' || e.key === 'Delete') {
                      if (rangeValue?.min?.toString().length < 2) {
                        e.preventDefault();
                      }
                    }
                  },
                }}
                onChange={(v) => {
                  setRangeValue({
                    ...rangeValue,
                    max:
                      Number(v) < rangeValue.min
                        ? rangeValue.min
                        : Number(v) > 200
                          ? 200
                          : Number(v),
                  });
                }}
              ></NumberTextField>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              minWidth={150}
              gap={2}
              mr={2}
            >
              <HeatmapZoneSelector
                size="small"
                fullWidth
                value={openedItem?.params?.zone}
                onChange={(v) => {
                  if (openedItem?.params) {
                    onUpdate?.(
                      update(config, {
                        openedItems: {
                          [openedItemIndex]: {
                            params: {
                              $set: {
                                ...openedItem?.params,
                                zone: v ?? null,
                              },
                            },
                          },
                        },
                      })
                    );
                  }
                }}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              minWidth={200}
              gap={2}
              mr={2}
            >
              <HeatmapObjectSelector
                size="small"
                fullWidth
                value={openedItem?.params?.object}
                onChange={(v) => {
                  if (openedItem?.params) {
                    onUpdate?.(
                      update(config, {
                        openedItems: {
                          [openedItemIndex]: {
                            params: {
                              $set: {
                                ...openedItem?.params,
                                object: v ?? null,
                              },
                            },
                          },
                        },
                      })
                    );
                  }
                }}
              />
            </Box>
            <ButtonGroup>
              <HeatmapExportButton
                value={openedItem}
                component={Button}
                componentProps={{color: 'primary'}}
                onSubmitted={() => onRefresh?.()}
              >
                <ImportExportIcon />
              </HeatmapExportButton>
              <Button size="small" onClick={() => params && fetchData(params)}>
                <RefreshIcon />
              </Button>
              <AutoRefreshSelect
                value={openedItem?.refreshInterval ?? null}
                onChange={(v) => {
                  if (openedItem) {
                    onUpdate?.(
                      update(config, {
                        openedItems: {
                          [openedItemIndex]: {
                            $set: {
                              ...openedItem,
                              refreshInterval: v,
                            },
                          },
                        },
                      })
                    );
                  }
                }}
              />

              <Button
                size="small"
                onClick={() => dataGridRef.current?.printTable()}
              >
                <PrintIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
      <Box flex="1" height="100%" width="100%" overflow="auto">
        <ResizableColumns
          position="relative"
          left={
            <>
              <HazardMap
                panel="heatmap_report"
                heatMap={heatMapData}
                selectedMapLayers={['heatmap']}
                selectedLevel={config?.mapLevel}
                hazardMapType={config?.mapType}
                height="600px"
                minHeight="100%"
                minWidth={200}
                availableMapLayers={['heatmap']}
                onGetClickHeatmapCoordinates={(e, radius) => {
                  onOpenEventList?.(e, radius, openedItem);
                }}
                onUpdateMapType={(v) => {
                  onUpdate?.(
                    update(config, {
                      mapType: {
                        $set: v,
                      },
                    })
                  );
                }}
              />
            </>
          }
        >
          <Box display="flex" flexDirection="column" height="100%">
            <DataGrid
              ref={dataGridRef}
              rows={rows}
              columns={columns}
              size="small"
              pagination
              page={openedItem?.grid?.page ?? 0}
              pageSize={openedItem?.grid?.pageSize ?? 10}
              paginationMode="client"
              shownFields={shownFields}
              sortingMode="client"
              loading={fetchedInProgress}
              sxFooter={{
                bgcolor: (theme) =>
                  theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
              }}
              footerStart={
                selectedItems.length ? (
                  <Box display="flex" alignItems="center" gap={3} px={1}>
                    <Box
                      display="flex"
                      gap={0.5}
                      alignItems="center"
                      height="100%"
                      whiteSpace="nowrap"
                    >
                      {selectedItems.length} selected
                    </Box>
                  </Box>
                ) : null
              }
              onShownFieldsChange={handleChangeShownFields}
              onPageChange={(v) => {
                if (openedItem) {
                  onUpdate?.(
                    update(config, {
                      openedItems: {
                        [openedItemIndex]: {
                          $set: {
                            ...openedItem,
                            selectAll: true,
                            grid: {
                              page: v ?? 1,
                              pageSize: openedItem?.grid?.pageSize ?? 10,
                            },
                          },
                        },
                      },
                    })
                  );
                }
              }}
              onPageSizeChange={(v) => {
                if (openedItem) {
                  onUpdate?.(
                    update(config, {
                      openedItems: {
                        [openedItemIndex]: {
                          $set: {
                            ...openedItem,
                            selectAll: true,
                            grid: {
                              page: 0,
                              pageSize: v ?? 10,
                            },
                          },
                        },
                      },
                    })
                  );
                }
              }}
            />
          </Box>
        </ResizableColumns>
      </Box>
      <Backdrop
        open={fetchedInProgress}
        sx={{position: 'absolute', zIndex: 1199}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default HazardHeatmapGrid;
