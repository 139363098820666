/* eslint-disable @typescript-eslint/no-unused-vars */
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, IconButton, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import ModalDraggable from '../common/ModalDraggable';
import {HeatmapConfig} from '../dashboard-panels/HazardHeatmap/HazardHeatmapGrid';
import HazardHeatmapConfiguration from './HazardHeatmapConfiguration';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  setViewType?: Function;
  onSave?: Function;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  item?: HeatmapConfig | null;
  pickedProperty?: string | null;
}

const HazardHeatmapObjectItemEditButton = <
  T extends ComponentType = typeof Button,
>({
  component,
  componentProps,
  children,
  onSave,
  item,
  pickedProperty,
}: // onPurged,
Props<T>) => {
  const Component = component ?? Button;
  const [isOpenConfigModal, setIsOpenConfigModal] = useState(false);
  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => {
          setIsOpenConfigModal(!isOpenConfigModal);
        }}
      >
        {children}
      </Component>
      {isOpenConfigModal && (
        <ModalDraggable open={isOpenConfigModal}>
          <>
            <Box
              display="flex"
              position="absolute"
              right={10}
              top={10}
              zIndex={10}
            >
              <IconButton
                sx={{marginLeft: 'auto', cursor: 'pointer'}}
                onClick={() => {
                  setIsOpenConfigModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <HazardHeatmapConfiguration
              item={item}
              onSave={(e: HeatmapConfig) => {
                onSave?.(e);
                setIsOpenConfigModal(false);
              }}
              onCloseConfigModal={() => {
                setIsOpenConfigModal(false);
              }}
            />
          </>
        </ModalDraggable>
      )}
    </>
  );
};

export default HazardHeatmapObjectItemEditButton;
