import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {
  ConfigurationInput,
  ConfigurationResponse,
} from '../../interfaces/Configuration';
import reduxActions from '../../redux/actions';
import {COLORED_ICONS} from '../../utils/icons';
import {tweakLabelForMiner} from '../../utils/macAddress';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import {ColorSelect} from '../selectors/ColorSelect';
import {SoundSelect} from '../selectors/SoundSelect';

interface ConfigurationCommtracValues {
  [key: string]: string | number | null;
}

const CONFIGURATION_SECTIONS = [
  'commtrac',
  'commtrac-color',
  'auto-refresh',
  'alarm',
  'alarm-sound',
  'alarm-color',
];

const FIELDS_BY_SECTIONS = {
  autoRefresh: [
    'auto-refresh.commtrac_asset_autorefresh_rate',
    'auto-refresh.commtrac_commnode_autorefresh_rate',
    'auto-refresh.commtrac_event_autorefresh_rate',
    'auto-refresh.commtrac_miner_autorefresh_rate',
    'auto-refresh.event_summary_autorefresh_rate',
    'commtrac.grid_refresh_period',
    'commtrac.unknown_nodes_tab_refresh_period',
    'alarm.alarm_autorefresh_rate',
  ],

  automatedMinerLowBatteryMessage: ['commtrac.miner_low_battery_message'],

  minerNodeLowVattery: ['commtrac.miner_node_low_battery'],

  minerMc2NodeLowBattery: ['commtrac.mc2_node_low_battery'],

  communicationNodeLowVattery: ['commtrac.communication_node_low_battery'],

  assetLowBattery: ['commtrac.asset_node_low_battery'],

  tagLowVattery: ['commtrac.tag_node_low_battery'],

  aTestLowBattery: ['commtrac.atest_node_low_battery'],

  camLowBattery: ['commtrac.cam_node_low_battery'],

  minerNodeNoLocation: ['commtrac.miner_node_no_location'],

  communicationNodeNoHeartbeats: ['commtrac.communication_node_no_heartbeat'],

  communicationNodeFewHeartbeats: [
    'commtrac.communication_node_few_heartbeats',
  ],

  gatewayNodeNoMessages: ['commtrac.gateway_node_no_messages'],

  assetNoLocation: ['commtrac.asset_no_location'],

  communicationNodeRssiLevel: [
    'commtrac.communication_node_rssi_alert_level',
    'commtrac.communication_node_rssi_alarm_level',
  ],

  // scSelfRescuersThresholds: [
  //   'commtrac.scsr_no_env_message_period',
  //   'commtrac.scsr_removed_from_cache_distance',
  // ],

  pruneMoreThen: ['commtrac.prune_more_then'],

  // printSettings: [
  //   'commtrac.print_threshold',
  //   'commtrac.print_miner_node_low_battery',
  //   'commtrac.print_communication_node_low_battery',
  //   'commtrac.print_miner_node_no_location',
  //   'commtrac.print_communication_node_no_hearbeat',
  //   'commtrac.print_communication_node_few_heartbeats',
  //   'commtrac.print_gateway_node_no_messages',
  //   'commtrac.print_gateway_node_assigned_master',
  //   'commtrac.print_checkin_checkout',
  //   'commtrac.print_emergency_state',
  //   'commtrac.print_miner_alarm',
  //   'commtrac.print_text_state',
  //   'commtrac.print_asset_low_battery',
  //   'commtrac.print_asset_no_location',
  // ],

  soundGlobal: ['commtrac.sound_new_event', 'commtrac.commtrac_sound_interval'],

  soundCommunicationGatewayNodes: [
    'commtrac.sound_comm_new_detected',
    'commtrac.sound_comm_assigned_master',
    'commtrac.sound_comm_hbno_msgs',
    'commtrac.sound_comm_low_battery',
    'commtrac.sound_comm_few_heartbeat',
    'commtrac.sound_comm_many_heartbeat',
    'commtrac.sound_comm_version_changed',
    'commtrac.sound_comm_unregistered_network',
    'commtrac.sound_comm_config_failed',
  ],

  soundMinerNodes: [
    'commtrac.sound_miner_new_detected',
    'commtrac.sound_miner_alarm',
    'commtrac.sound_miner_no_location',
    'commtrac.sound_miner_location_derived',
    'commtrac.sound_miner_many_location',
    'commtrac.sound_miner_low_battery',
    'commtrac.sound_miner_new_message',
    'commtrac.sound_miner_config_failed',
    'commtrac.sound_miner_not_moving_state',
  ],

  soundAssets: [
    'commtrac.sound_asset_new_detected',
    'commtrac.sound_asset_no_location',
    'commtrac.sound_asset_location_derived',
    'commtrac.sound_asset_many_location',
    'commtrac.sound_asset_low_battery',
  ],

  soundAlarms: [
    'alarm-sound.am_sound_701',
    'alarm-sound.am_sound_702',
    'alarm-sound.am_sound_703',
    'alarm-sound.am_sound_704',
    'alarm-sound.am_sound_705',
    'alarm-sound.am_sound_706',
  ],

  colorCommunicationGatewayNodes: [
    'commtrac-color.color_communication_node_assigned_master',
    'commtrac-color.color_communication_node_no_hb_no_msgs',
    'commtrac-color.color_communication_node_low_battery',
    'commtrac-color.color_communication_node_few_heartbeat',
    'commtrac-color.color_communication_node_many_heartbeat',
    'commtrac-color.color_communication_node_version_changed',
    'commtrac-color.color_communication_node_unregistered_network',
  ],

  colorMinerNodes: [
    'commtrac-color.color_miner_node_new_detected',
    'commtrac-color.color_miner_node_alarm',
    'commtrac-color.color_miner_node_no_location',
    'commtrac-color.color_miner_node_location_derived',
    'commtrac-color.color_miner_node_many_location',
    'commtrac-color.color_miner_node_low_battery',
    'commtrac-color.color_miner_node_new_message',
    'commtrac-color.color_miner_not_moving_state',
  ],

  colorAssets: [
    'commtrac-color.color_asset_node_new_detected',
    'commtrac-color.color_asset_node_no_location',
    'commtrac-color.color_asset_node_location_derived',
    'commtrac-color.color_asset_node_many_location',
    'commtrac-color.color_asset_node_low_battery',
  ],

  colorAlarms: [
    'alarm-color.am_color_701',
    'alarm-color.am_color_702',
    'alarm-color.am_color_703',
    'alarm-color.am_color_704',
    'alarm-color.am_color_705',
    'alarm-color.am_color_706',
  ],
};

const FIELDS = _.flatMap(FIELDS_BY_SECTIONS);

const getFormikValues = (
  item?: ConfigurationResponse
): ConfigurationCommtracValues => ({
  ..._.chain(FIELDS)
    .map((name) => ({name, value: null}))
    .keyBy('name')
    .mapValues('value')
    .value(),

  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('value')
    .value(),
});

const getFormikValuesClear = (
  item?: ConfigurationResponse
): ConfigurationCommtracValues => ({
  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('default')
    .value(),
});

export interface AdminCommtracConfigProps {
  disabled?: boolean;
  onChangeSubmittedInProgress?: (value: boolean) => any;
  onChangeResetInProgress?: (value: boolean) => any;
}

export interface AdminCommtracConfigRef {
  fetch?: Function;
  submit?: Function;
  reset?: Function;
  clear?: Function;
}

export const AdminCommtracConfig = forwardRef<
  AdminCommtracConfigRef,
  React.PropsWithChildren<AdminCommtracConfigProps>
>(({disabled, onChangeSubmittedInProgress, onChangeResetInProgress}, ref) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(),
    submit: () => formik.handleSubmit(),
    reset: () => handleResetData(),
    clear: () => formik.setValues(getFormikValuesClear(fetchedData)),
  }));

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<ConfigurationResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>();
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const params = {section: JSON.stringify(CONFIGURATION_SECTIONS)};
      const resp = await API.get<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      setFetchedErrors(error.data);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    fetchData();

    return () => {
      setFetchedData(undefined);
      setFetchedErrors(undefined);
    };
  }, []);

  /**********/
  /* submit */
  /**********/
  // const [submittedData, setSubmittedData] = useState<ConfigurationResponse>();
  // const [submittedErrors, setSubmittedErrors] = useState<string[]>();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const reduxDispatch = useAppDispatch();

  const submitData = async (data: ConfigurationInput) => {
    setSubmittedInProgress(true);

    try {
      const resp = await API.post<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        data
      );
      setFetchedData(resp.data);
      reduxDispatch(reduxActions.app.fetchMyConfigurations);
      enqueueSnackbar('Configuration successfully updated', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      // setSubmittedErrors(error.data);
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setSubmittedInProgress(false);
  };

  /**********/
  /* reset */
  /**********/
  const [resetInProgress, setResetInProgress] = useState(false);

  const resetData = async (data: ConfigurationInput) => {
    setResetInProgress(true);

    try {
      const resp = await API.patch<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        data
      );
      setFetchedData(resp.data);
      reduxDispatch(reduxActions.app.fetchMyConfigurations);
      enqueueSnackbar('Configuration successfully reset', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      // setSubmittedErrors(error.data);
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setResetInProgress(false);
  };

  const handleResetData = async () => {
    const input: ConfigurationInput = {
      section: CONFIGURATION_SECTIONS,
      configuration: _.map(formik.values, (value, key) => ({
        section: key.split('.')[0],
        name: key.split('.')[1],
        value,
      })),
    };
    await resetData(input);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = yup.object();

  const formik = useFormik({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      const input: ConfigurationInput = {
        section: CONFIGURATION_SECTIONS,
        configuration: _.map(values, (value, key) => ({
          section: key.split('.')[0],
          name: key.split('.')[1],
          value,
        })),
      };
      await submitData(input);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!_.isEqual(newInput, formik.values)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  /************/
  /* keysData */
  /************/
  const keysData = useMemo(
    () => ({
      ..._.chain(fetchedData)
        .keyBy((i) => `${i.section}.${i.name}`)
        .mapValues()
        .value(),
    }),
    [fetchedData]
  );

  // other
  useEffect(() => {
    onChangeSubmittedInProgress?.(submittedInProgress);
  }, [submittedInProgress]);

  useEffect(() => {
    onChangeResetInProgress?.(resetInProgress);
  }, [resetInProgress]);

  return (
    <Box position="relative">
      <Backdrop open={fetchedInProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors?.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <Paper sx={{p: 3, mb: 3}}>
        <Box fontSize={20} lineHeight={1.6} mb={3}>
          Auto Refresh Settings
        </Box>

        <Grid container spacing={4}>
          {FIELDS_BY_SECTIONS.autoRefresh.map((name) => {
            return (
              <Grid key={name} item xs={12} lg={6}>
                <TextField
                  fullWidth
                  label={tweakLabelForMiner(t(keysData[name]?.label))}
                  value={formik.values[name] ?? 'None'}
                  name={name}
                  size="small"
                  type="number"
                  select
                  disabled={disabled}
                  onChange={(event) => {
                    const value =
                      event.target.value === 'None' ? null : event.target.value;
                    formik.setValues({...formik.values, [name]: value});
                  }}
                >
                  {['None', 30, 60, 120].map((i) => (
                    <MenuItem key={i ?? ''} value={i ?? ''}>
                      {i ?? 'None'}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            );
          })}
        </Grid>
      </Paper>

      <Grid container spacing={4} mb={3}>
        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Automated MC1 low battery Message
            </Box>

            {FIELDS_BY_SECTIONS.automatedMinerLowBatteryMessage.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  select
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                >
                  {[
                    {value: 0, name: 'Disabled'},
                    {value: 1, name: 'Enabled'},
                  ].map((i) => (
                    <MenuItem key={i.value ?? ''} value={i.value ?? ''}>
                      {i.name ?? 'None'}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              MC1 low battery
            </Box>

            {FIELDS_BY_SECTIONS.minerNodeLowVattery.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={tweakLabelForMiner(t(keysData[name]?.label))}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              MC2 low battery
            </Box>

            {FIELDS_BY_SECTIONS.minerMc2NodeLowBattery.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Communication node low battery
            </Box>

            {FIELDS_BY_SECTIONS.communicationNodeLowVattery.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Asset low battery
            </Box>

            {FIELDS_BY_SECTIONS.assetLowBattery.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Tag low battery
            </Box>

            {FIELDS_BY_SECTIONS.tagLowVattery.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              ATest low battery
            </Box>

            {FIELDS_BY_SECTIONS.aTestLowBattery.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              CAM low battery
            </Box>

            {FIELDS_BY_SECTIONS.camLowBattery.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Employee no location
            </Box>

            {FIELDS_BY_SECTIONS.minerNodeNoLocation.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Communication node no heartbeats
            </Box>

            {FIELDS_BY_SECTIONS.communicationNodeNoHeartbeats.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Communication node few heartbeats
            </Box>

            {FIELDS_BY_SECTIONS.communicationNodeFewHeartbeats.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Gateway node no messages
            </Box>

            {FIELDS_BY_SECTIONS.gatewayNodeNoMessages.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Asset no location
            </Box>

            {FIELDS_BY_SECTIONS.assetNoLocation.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Communication node RSSI level
            </Box>
            <Grid container spacing={4}>
              {FIELDS_BY_SECTIONS.communicationNodeRssiLevel.map((name) => (
                <Grid key={name} item xs={12} lg={6}>
                  <Box>
                    <TextField
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      type="number"
                      size="small"
                      disabled={disabled}
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} lg={6} xl={6}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Default Event Sounds
            </Box>

            <Box mb={3}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Global
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {FIELDS_BY_SECTIONS.soundGlobal.slice(0, 1).map((name) => (
                    <Box key={name} mb={2}>
                      <SoundSelect
                        fullWidth
                        label={tweakLabelForMiner(t(keysData[name]?.label))}
                        value={formik.values[name] ?? ''}
                        name={name}
                        size="small"
                        disabled={disabled}
                        onChange={(event) =>
                          formik.setValues({
                            ...formik.values,
                            [name]: event.target.value,
                          })
                        }
                      />
                    </Box>
                  ))}
                </Grid>

                <Grid item xs={6}>
                  {FIELDS_BY_SECTIONS.soundGlobal.slice(1).map((name) => (
                    <Box key={name} mb={2}>
                      <TextField
                        fullWidth
                        label={t(keysData[name]?.label)}
                        value={formik.values[name] ?? 'None'}
                        name={name}
                        type="number"
                        size="small"
                        select
                        disabled={disabled}
                        onChange={(event) => {
                          const value =
                            event.target.value === 'None'
                              ? null
                              : event.target.value;
                          formik.setValues({...formik.values, [name]: value});
                        }}
                      >
                        {['None', 10, 15, 30, 60, 120].map((i) => (
                          <MenuItem key={i ?? ''} value={i ?? ''}>
                            {i ?? 'None'}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Box>

            <Box mb={3}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Communication/Gateway Nodes
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.soundCommunicationGatewayNodes.map(
                  (name) => (
                    <Grid key={name} item xs={6}>
                      <SoundSelect
                        fullWidth
                        label={t(keysData[name]?.label)}
                        value={formik.values[name] ?? ''}
                        name={name}
                        size="small"
                        disabled={disabled}
                        onChange={(event) =>
                          formik.setValues({
                            ...formik.values,
                            [name]: event.target.value,
                          })
                        }
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Box>

            <Box mb={3}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Employees
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.soundMinerNodes.map((name) => (
                  <Grid key={name} item xs={6}>
                    <SoundSelect
                      fullWidth
                      label={tweakLabelForMiner(t(keysData[name]?.label))}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      disabled={disabled}
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box mb={3}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Assets
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.soundAssets.map((name) => (
                  <Grid key={name} item xs={6}>
                    <SoundSelect
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      disabled={disabled}
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box mb={3}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Alarm Module
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.soundAlarms.map((name) => (
                  <Grid key={name} item xs={6}>
                    <SoundSelect
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      disabled={disabled}
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6} xl={6}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Default Event Colors
            </Box>

            <Box mb={3}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Communication/Gateway Nodes
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.colorCommunicationGatewayNodes.map(
                  (name) => (
                    <Grid key={name} item xs={6}>
                      <ColorSelect
                        fullWidth
                        label={t(keysData[name]?.label)}
                        value={formik.values[name] ?? ''}
                        name={name}
                        size="small"
                        disabled={disabled}
                        icons={COLORED_ICONS.communicationNodes}
                        onChange={(event) =>
                          formik.setValues({
                            ...formik.values,
                            [name]: event.target.value,
                          })
                        }
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Box>

            <Box mb={3}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Employees
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.colorMinerNodes.map((name) => (
                  <Grid key={name} item xs={6}>
                    <ColorSelect
                      fullWidth
                      label={tweakLabelForMiner(t(keysData[name]?.label))}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      disabled={disabled}
                      icons={COLORED_ICONS.miners}
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box mb={3}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Assets
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.colorAssets.map((name) => (
                  <Grid key={name} item xs={6}>
                    <ColorSelect
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      disabled={disabled}
                      icons={COLORED_ICONS.assetEvents}
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box mb={3}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Alarm Module
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.colorAlarms.map((name) => (
                  <Grid key={name} item xs={6}>
                    <ColorSelect
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      disabled={disabled}
                      icons={COLORED_ICONS.alarm}
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
});
