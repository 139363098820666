import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {
  ConfigurationInput,
  ConfigurationResponse,
} from '../../interfaces/Configuration';
import reduxActions from '../../redux/actions';
import {COLORED_ICONS} from '../../utils/icons';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import {ColorSelect} from '../selectors/ColorSelect';
import {SoundSelect} from '../selectors/SoundSelect';

interface ConfigurationAmsValues {
  [key: string]: string | number | null;
}

const FIELDS_BY_SECTIONS = {
  autoRefreshSettings: [
    'ams.ams_grid_refresh_period',
    'ams.ams_event_refresh_period',
  ],

  sensorAlarm: [
    'ams.ams_co_value_alarm_level',
    'ams.ams_cl2_value_alarm_level',
    'ams.ams_h2_value_alarm_level',
    'ams.ams_h2s_value_alarm_level',
    'ams.ams_no_value_alarm_level',
    'ams.ams_no2_value_alarm_level',
    'ams.ams_o2_lower_alarm_level',
    'ams.ams_o2_upper_alarm_level',
    'ams.ams_so2_value_alarm_level',
    'ams.ams_nh3_value_alarm_level',
    'ams.ams_ch4_value_alarm_level',
    'ams.ams_pelch4_value_alarm_level',
    'ams.ams_ana_value_alarm_level',
    'ams.ams_sw_value_alarm_level',
    'ams.ams_amb_value_alarm_level',
    'ams.ams_tem_value_alarm_level',
    'ams.ams_hum_value_alarm_level',
    'ams.ams_flo_value_alarm_level',
    'ams.ams_pre_value_alarm_level',
  ],

  sensorAlert: [
    'ams.ams_co_value_alert_level',
    'ams.ams_cl2_value_alert_level',
    'ams.ams_h2_value_alert_level',
    'ams.ams_h2s_value_alert_level',
    'ams.ams_no_value_alert_level',
    'ams.ams_no2_value_alert_level',
    'ams.ams_o2_lower_alert_level',
    'ams.ams_o2_upper_alert_level',
    'ams.ams_so2_value_alert_level',
    'ams.ams_nh3_value_alert_level',
    'ams.ams_ch4_value_alert_level',
    'ams.ams_pelch4_value_alert_level',
    'ams.ams_ana_value_alert_level',
    'ams.ams_sw_value_alert_level',
    'ams.ams_amb_value_alert_level',
    'ams.ams_tem_value_alert_level',
    'ams.ams_hum_value_alert_level',
    'ams.ams_flo_value_alert_level',
    'ams.ams_pre_value_alert_level',
  ],

  sensorLowBattery: ['ams.ams_node_low_battery'],

  sensorNoEnvMessages: ['ams.ams_no_env_messages'],

  sensorFewEnvMessages: [
    'ams.ams_few_env_messages_time',
    'ams.ams_few_env_messages_number_of_messages',
  ],

  sensorManyEnvMessages: ['ams.ams_many_env_messages'],

  sensorCalibrationDate: ['ams.ams_calibration_date_range'],

  sensorAlertAlarmDelay: ['ams.ams_alarm_delay'],

  soundGlobal: ['ams.sound_ams_new_event', 'ams.ams_sound_interval'],

  sound: [
    'ams.sound_ams_new_detected',
    'ams.sound_ams_alarm',
    'ams.sound_ams_alert',
    'ams.sound_ams_calibration_date_expired',
    'ams.sound_ams_emodule_unregistered',
    'ams.sound_ams_env_missing_sn',
    'ams.sound_ams_few_env_messages',
    'ams.sound_ams_input_state_change',
    'ams.sound_ams_low_battery',
    'ams.sound_ams_many_env_messages',
    'ams.sound_ams_sd_no_envs_message',
  ],

  color: [
    'ams-color.color_ams_alarm',
    'ams-color.color_ams_alert',
    'ams-color.color_ams_ava_test',
    'ams-color.color_ams_calibration_date_expired',
    'ams-color.color_ams_emodule_unregistered',
    'ams-color.color_ams_env_missing_sn',
    'ams-color.color_ams_few_env_messages',
    'ams-color.color_ams_input_state_change',
    'ams-color.color_ams_low_battery',
    'ams-color.color_ams_many_env_messages',
    'ams-color.color_ams_new_detected',
    'ams-color.color_ams_sd_no_envs_message',
  ],
};

const FIELDS = _.flatMap(FIELDS_BY_SECTIONS);

const getFormikValues = (
  item?: ConfigurationResponse
): ConfigurationAmsValues => ({
  ..._.chain(FIELDS)
    .map((name) => ({name, value: null}))
    .keyBy('name')
    .mapValues('value')
    .value(),

  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('value')
    .value(),
});

const getFormikValuesClear = (
  item?: ConfigurationResponse
): ConfigurationAmsValues => ({
  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('default')
    .value(),
});

export interface AdminAmsConfigProps {
  disabled?: boolean;
  onChangeSubmittedInProgress?: (value: boolean) => any;
  onChangeResetInProgress?: (value: boolean) => any;
}

export interface AdminAmsConfigRef {
  fetch?: Function;
  submit?: Function;
  reset?: Function;
  clear?: Function;
}

export const AdminAmsConfig = forwardRef<
  AdminAmsConfigRef,
  React.PropsWithChildren<AdminAmsConfigProps>
>(({disabled, onChangeSubmittedInProgress, onChangeResetInProgress}, ref) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(),
    submit: () => formik.handleSubmit(),
    reset: () => handleResetData(),
    clear: () => formik.setValues(getFormikValuesClear(fetchedData)),
  }));

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<ConfigurationResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>();
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const params = {section: JSON.stringify(['ams', 'ams-color'])};
      const resp = await API.get<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      setFetchedErrors(error.data);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    fetchData();

    return () => {
      setFetchedData(undefined);
      setFetchedErrors(undefined);
    };
  }, []);

  /**********/
  /* submit */
  /**********/
  // const [submittedData, setSubmittedData] = useState<ConfigurationResponse>();
  // const [submittedErrors, setSubmittedErrors] = useState<string[]>();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const reduxDispatch = useAppDispatch();

  const submitData = async (data: ConfigurationInput) => {
    setSubmittedInProgress(true);

    try {
      const resp = await API.post<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        data
      );
      setFetchedData(resp.data);
      reduxDispatch(reduxActions.app.fetchMyConfigurations);
      enqueueSnackbar('Configuration successfully updated', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      // setSubmittedErrors(error.data);
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setSubmittedInProgress(false);
  };

  /**********/
  /* reset */
  /**********/
  const [resetInProgress, setResetInProgress] = useState(false);

  const resetData = async (data: ConfigurationInput) => {
    setResetInProgress(true);

    try {
      const resp = await API.patch<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        data
      );
      setFetchedData(resp.data);
      reduxDispatch(reduxActions.app.fetchMyConfigurations);
      enqueueSnackbar('Configuration successfully reset', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      // setSubmittedErrors(error.data);
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setResetInProgress(false);
  };

  const handleResetData = async () => {
    const input: ConfigurationInput = {
      section: ['ams', 'ams-color'],
      configuration: _.map(formik.values, (value, key) => ({
        section: key.split('.')[0],
        name: key.split('.')[1],
        value,
      })),
    };
    await resetData(input);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = yup.object();

  const formik = useFormik({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      const input: ConfigurationInput = {
        section: ['ams', 'ams-color'],
        configuration: _.map(values, (value, key) => ({
          section: key.split('.')[0],
          name: key.split('.')[1],
          value,
        })),
      };
      await submitData(input);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!_.isEqual(newInput, formik.values)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  /************/
  /* keysData */
  /************/
  const keysData = useMemo(
    () => ({
      ..._.chain(fetchedData)
        .keyBy((i) => `${i.section}.${i.name}`)
        .mapValues()
        .value(),
    }),
    [fetchedData]
  );

  // other
  useEffect(() => {
    onChangeSubmittedInProgress?.(submittedInProgress);
  }, [submittedInProgress]);

  useEffect(() => {
    onChangeResetInProgress?.(resetInProgress);
  }, [resetInProgress]);

  return (
    <Box position="relative">
      <Backdrop open={fetchedInProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors?.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <Paper sx={{p: 3, mb: 3}}>
        <Box fontSize={20} lineHeight={1.6} mb={3}>
          AMS Auto Refresh Settings
        </Box>

        <Grid container spacing={4}>
          {FIELDS_BY_SECTIONS.autoRefreshSettings.map((name) => (
            <Grid key={name} item xs={12} lg={6}>
              <TextField
                fullWidth
                label={t(keysData[name]?.label)}
                value={formik.values[name] ?? 'None'}
                name={name}
                type="number"
                size="small"
                select
                disabled={disabled}
                onChange={(event) => {
                  const value =
                    event.target.value === 'None' ? null : event.target.value;
                  formik.setValues({...formik.values, [name]: value});
                }}
              >
                {['None', 10, 15, 30, 60, 120].map((i) => (
                  <MenuItem key={i ?? ''} value={i ?? ''}>
                    {i ?? 'None'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper sx={{p: 3, mb: 3}}>
        <Box fontSize={20} lineHeight={1.6} mb={3}>
          AMS Sensor Alarm
        </Box>

        <Grid container spacing={4}>
          {FIELDS_BY_SECTIONS.sensorAlarm.map((name) => (
            <Grid key={name} item xs={12} lg={3}>
              <TextField
                fullWidth
                label={t(keysData[name]?.label)}
                value={formik.values[name] ?? ''}
                name={name}
                type="number"
                size="small"
                disabled={disabled}
                onChange={(event) =>
                  formik.setValues({
                    ...formik.values,
                    [name]: event.target.value,
                  })
                }
              />
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper sx={{p: 3, mb: 3}}>
        <Box fontSize={20} lineHeight={1.6} mb={3}>
          AMS Sensor Alert
        </Box>

        <Grid container spacing={4}>
          {FIELDS_BY_SECTIONS.sensorAlert.map((name) => (
            <Grid key={name} item xs={12} lg={3}>
              <TextField
                fullWidth
                label={t(keysData[name]?.label)}
                value={formik.values[name] ?? ''}
                name={name}
                type="number"
                size="small"
                disabled={disabled}
                onChange={(event) =>
                  formik.setValues({
                    ...formik.values,
                    [name]: event.target.value,
                  })
                }
              />
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper sx={{p: 3, mb: 3}}>
        <Box fontSize={20} lineHeight={1.6} mb={2}>
          AMS Sensor
        </Box>

        <Grid container spacing={4} mb={3}>
          <Grid item xs={12} lg={4}>
            <Box fontSize={14} lineHeight={1.6} mb={2}>
              Sensor Low Battery
            </Box>

            {FIELDS_BY_SECTIONS.sensorLowBattery.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Grid>

          <Grid item xs={12} lg={4}>
            <Box fontSize={14} lineHeight={1.6} mb={2}>
              Sensor Calibration Date
            </Box>

            {FIELDS_BY_SECTIONS.sensorCalibrationDate.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Grid>

          <Grid item xs={12} lg={4}>
            <Box fontSize={14} lineHeight={1.6} mb={2}>
              Sensor Alert/Alarm Delay
            </Box>

            {FIELDS_BY_SECTIONS.sensorAlertAlarmDelay.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Grid>
        </Grid>

        <Box fontSize={14} lineHeight={1.6} mb={2}>
          Few ENV Messages
        </Box>

        <Grid container spacing={4} mb={3}>
          {FIELDS_BY_SECTIONS.sensorFewEnvMessages.map((name) => (
            <Grid key={name} item xs={12} lg={6}>
              <Box>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={4} mb={3}>
          <Grid item xs={12} lg={6}>
            <Box fontSize={14} lineHeight={1.6} mb={2}>
              No ENV Messages
            </Box>

            {FIELDS_BY_SECTIONS.sensorNoEnvMessages.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Grid>

          <Grid item xs={12} lg={6}>
            <Box fontSize={14} lineHeight={1.6} mb={2}>
              Many ENV Messages
            </Box>

            {FIELDS_BY_SECTIONS.sensorManyEnvMessages.map((name) => (
              <Box key={name}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type="number"
                  size="small"
                  disabled={disabled}
                  onChange={(event) =>
                    formik.setValues({
                      ...formik.values,
                      [name]: event.target.value,
                    })
                  }
                />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={4} mb={3}>
        <Grid item xs={12} lg={6} xl={8}>
          <Paper sx={{p: 3, mb: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={2}>
              AMS Sound
            </Box>

            <Box fontSize={14} lineHeight={1.6} mb={2}>
              Global
            </Box>

            <Grid container spacing={4} mb={6}>
              <Grid item xs={6}>
                {FIELDS_BY_SECTIONS.soundGlobal.slice(0, 1).map((name) => (
                  <SoundSelect
                    key={name}
                    fullWidth
                    label={t(keysData[name]?.label)}
                    value={formik.values[name]}
                    name={name}
                    size="small"
                    disabled={disabled}
                    onChange={(event) =>
                      formik.setValues({
                        ...formik.values,
                        [name]: event.target.value,
                      })
                    }
                  />
                ))}
              </Grid>

              <Grid item xs={6}>
                {FIELDS_BY_SECTIONS.soundGlobal.slice(1, 2).map((name) => (
                  <TextField
                    key={name}
                    fullWidth
                    label={t(keysData[name]?.label)}
                    value={formik.values[name] ?? 'None'}
                    name={name}
                    type="number"
                    size="small"
                    select
                    disabled={disabled}
                    onChange={(event) => {
                      const value =
                        event.target.value === 'None'
                          ? null
                          : event.target.value;
                      formik.setValues({...formik.values, [name]: value});
                    }}
                  >
                    {['None', 10, 15, 30, 60, 120].map((i) => (
                      <MenuItem key={i ?? ''} value={i ?? ''}>
                        {i ?? 'None'}
                      </MenuItem>
                    ))}
                  </TextField>
                ))}
              </Grid>
            </Grid>

            <Grid container spacing={4} mb={3}>
              {FIELDS_BY_SECTIONS.sound.map((name) => (
                <Grid key={name} item xs={6}>
                  <SoundSelect
                    fullWidth
                    label={t(keysData[name]?.label)}
                    value={formik.values[name] ?? ''}
                    name={name}
                    size="small"
                    disabled={disabled}
                    onChange={(event) =>
                      formik.setValues({
                        ...formik.values,
                        [name]: event.target.value,
                      })
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6} xl={4}>
          <Paper sx={{p: 3, mb: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={2}>
              AMS Color
            </Box>

            <Grid container spacing={4} mb={3}>
              {FIELDS_BY_SECTIONS.color.map((name) => (
                <Grid key={name} item xs={6}>
                  <ColorSelect
                    fullWidth
                    size="small"
                    label={t(keysData[name]?.label)}
                    value={formik.values[name] ?? ''}
                    name={name}
                    disabled={disabled}
                    icons={COLORED_ICONS.amsUnknown}
                    onChange={(event) =>
                      formik.setValues({
                        ...formik.values,
                        [name]: event.target.value,
                      })
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
});
