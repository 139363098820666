import 'leaflet/dist/leaflet.css';

import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
} from '@mui/material';
import {t} from 'i18next';
import update from 'immutability-helper';
import * as _ from 'lodash';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {AlarmLogNodeListResponse} from '../../../interfaces/AlarmLogNode';
import {CommtracNodeListQuery, CommtracNodeListResponse} from '../../../interfaces/CommtracNode';
import {DashboardEntity, DashboardPanelData} from '../../../interfaces/Dashboard';
import {NodeListResponse} from '../../../interfaces/Node';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {Map as MapComponent, MapLayerId} from '../../common/Map';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import {EmployeesAssetsViewData} from '../EmployeeAssetsGrid';
import {StatusSelect} from '../EmployeeAssetsGrid/StatusSelect';
import {TypeSelect} from '../EmployeeAssetsGrid/TypeSelect';

export interface MapPanelData {
  params: Partial<CommtracNodeListQuery>;
  refreshInterval?: number;
  activeId?: number;
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  availableMapLayers: MapLayerId[];
}

export const getMapPanelData = (): MapPanelData => ({
  params: {
    miner_status: 'checked_in',
    asset_status: 'checked_in',
    node_type: ['miner', 'asset'],
    section_ids: [],
  },
  activeId: undefined,
  mapLayers: ['street', 'mine', 'nodes', 'employees', 'assets', 'alarms'],
  mapLevel: null,
  availableMapLayers: [
    'street',
    'mine',
    'nodes',
    'employees',
    'assets',
    'alarms',
  ],
});

interface Props {
  value?: DashboardPanelData;
  entities?: DashboardEntity[];
  onUpdate?: (value: DashboardPanelData) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => void;
}

export const Map = (props: Props) => {
  const [panel] = usePanel();

  const employeeAseetsPanelData = props.entities?.find(
    (e) => e.name === "Employees/Assets"
  )?.panel?.data as EmployeesAssetsViewData;

  const [fetchedCommtracNodeData, setFetchedCommtracNodeData] =
    useState<CommtracNodeListResponse>();
  const [fetchedNodesData, setFetchedNodesData] = useState<NodeListResponse>();
  const [fetchedAlarmModuleData, setFetchedAlarmModuleData] =
    useState<AlarmLogNodeListResponse>();

  const [isFetching, setIsFetching] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const employeeAseetsParams = employeeAseetsPanelData?.commtracNodes?.params;

  const [config, setConfig] = useState<MapPanelData>(
    !_.isEmpty(props.value)
      ? props.value as unknown as MapPanelData
      : getMapPanelData()
  );

  const fetchData = async () => {
    setIsFetching(true);
    setErrors([]);

    try {
      const standby = !!employeeAseetsPanelData && employeeAseetsPanelData.viewType !== 'commtracNodes';

      {
        const resp = await API.get<CommtracNodeListResponse>(
          `${apiBaseUrl}/commtrac-node`,
          {params: {...config.params, standby}}
        );
        setFetchedCommtracNodeData(resp.data);
      }
      {
        const resp = await API.get<NodeListResponse>(
          `${apiBaseUrl}/node`,
          {params: {section_ids: config.params.section_ids, standby}}
        );
        setFetchedNodesData(resp.data ?? []);
      }
      {
        const resp = await API.get<AlarmLogNodeListResponse>(
          `${apiBaseUrl}/alarm-module`,
          {params: {section_ids: config.params.section_ids, standby}}
        );
        setFetchedAlarmModuleData(resp.data ?? []);
      }
    } catch (e: any) {
      const messages = getMessagesFromApiError(e);
      setErrors(messages);
    }

    setIsFetching(false);
  };

  const [refreshInterval, setRefreshInterval] = useRefreshInterval(
    fetchData,
    props.value?.refreshInterval ?? 30000
  );

  useEffect(() => {
    if (!isFetching) {
      fetchData();
    }
  }, [config.params]);

  useEffect(() => {
    // If dashboard contains employee/asset panel we should
    // override filter values with employee/asset filters
    if (employeeAseetsParams) {
      setConfig({...config, params: employeeAseetsParams});
    }
  }, [employeeAseetsParams]);

  useEffect(() => {
    props.onUpdate?.({...props.value, ...config, refreshInterval});
  }, [config, refreshInterval]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Backdrop open={isFetching} sx={{position: 'absolute', zIndex: 1001}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        display="flex"
        justifyContent="space-between"
        gap={1}
        py={1}
      >
        <Box
          display="flex"
          height="100%"
          width="100%"
          gap={1}
          visibility={!employeeAseetsPanelData ? 'visible' : 'hidden'}
        >
          <TypeSelect
            fullWidth
            size="small"
            value={
              config.params.node_type?.length !== 1
                ? 0
                : config.params.node_type?.includes('asset')
                  ? 1
                  : 2
            }
            onChange={(v) => {
              setConfig(
                update(config, {
                  params: {
                    node_type: {
                      $set:
                        v === 0
                          ? ['asset', 'miner']
                          : v === 1
                            ? ['asset']
                            : ['miner'],
                    },
                  },
                })
              );
            }}
          />
          <StatusSelect
            fullWidth
            size="small"
            cnt={fetchedCommtracNodeData?.items?.length ?? 0}
            type={config.params.node_type}
            value={config.params.asset_status}
            onChange={(v) => {
              setConfig(
                update(config, {
                  params: {
                    asset_status: {
                      $set: v,
                    },
                    miner_status: {
                      $set: v,
                    },
                  },
                })
              );
            }}
          />
          <ZoneSelect
            size="small"
            fullWidth
            nullLabel="All Sections"
            value={config.params.section_ids?.[0]}
            onChange={(v) => {
              const sectionIds = v ? [+v] : [];
              setConfig(
                update(config, {
                  params: {
                    section_ids: {
                      $set: sectionIds,
                    },
                  },
                })
              );
            }}
          />
        </Box>

        <Box display="flex" height="100%">
          <ButtonGroup>
            <Button size="small" onClick={fetchData}>
              <RefreshIcon />
            </Button>
            <AutoRefreshSelect
              value={refreshInterval ?? null}
              onChange={setRefreshInterval}
            />
          </ButtonGroup>
        </Box>
      </Box>

      {errors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <MapComponent
        selectedMapLayers={config.mapLayers}
        selectedLevel={config.mapLevel}
        availableMapLayers={config.availableMapLayers}
        commtracNodes={fetchedCommtracNodeData?.items}
        nodes={fetchedNodesData?.items}
        alarmNodes={fetchedAlarmModuleData?.items}
        onSelectMapLayers={(v) => {
          setConfig({
            ...config,
            mapLayers: v,
          });
        }}
        onSelectLevel={(v) => {
          setConfig({
            ...config,
            mapLevel: v,
          });
        }}
        onOpenHistory={props.onOpenHistory}
        disableEditMode={true}
      />
    </Box>
  );
};
