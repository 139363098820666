/* eslint-disable @typescript-eslint/no-unused-vars */
import {Alert, Backdrop, Box, CircularProgress} from '@mui/material';
import {Howl} from 'howler';
import {t} from 'i18next';
import update from 'immutability-helper';
import {isEmpty, uniq, values} from 'lodash';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
// import { fechAlarmNodes } from "../../../constants/mockData";
import {
  useConfiguration,
  useConfigurations,
} from '../../../hooks/configuration';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {
  // AlarmLogNode,
  AlarmLogNodeListResponse,
} from '../../../interfaces/AlarmLogNode';
import {AlarmHistory} from '../../../interfaces/AlarmsHistory';
import {BroadcastMessageListResponse} from '../../../interfaces/BroadcastMessage';
import {CommtracNodeListResponse} from '../../../interfaces/CommtracNode';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  EventListResponse,
  EventSummaryResent,
  EventSummaryResponse,
} from '../../../interfaces/Event';
import {NodeListResponse} from '../../../interfaces/Node';
import reduxActions from '../../../redux/actions';
import reduxSelectors from '../../../redux/selectors';
import {
  OpenedEntity,
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import {getMsFromS} from '../../../utils/datetime';
import {CONNECT_EVENT_SOUNDS} from '../../../utils/events';
import {SOUNDS} from '../../../utils/sounds';
import {isPresent} from '../../../utils/type-guards';
import AlarmItemUpsert from '../../alarm-nodes/AlarmItemUpsert';
import AlarmSimpleAcknowledgePopup from '../../alarm-nodes/AlarmSimpleAcknowledgePopup';
import AssetHumanItemUpsert from '../../asset-human/AssetHumanItemUpsert';
import AssetHumanUnassignedList from '../../asset-human/AssetHumanUnassignedList';
import AssetHumanGroupChat from '../../asset-human-group/AssetHumanGroupChat';
import AssetMachineItemUpsert from '../../asset-machine/AssetMachineItemUpsert';
import AssetMachineUnassignedList from '../../asset-machine/AssetMachineUnassignedList';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../../common/Map';
import ModalDraggable from '../../common/ModalDraggable';
import CommunicationNodeItemUpsert from '../../communication-node/CommunicationNodeItemUpsert';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import WifiPointItemUpsert from '../../wifi-point/WifiPointItemUpsert';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import AlarmLogReport, {
  AlarmLogReportData,
  getAlarmLogReportData,
} from './AlarmLogReport';
import CommtracNodesReport, {
  CommtracNodesReportData,
  getCommtracNodesReportData,
} from './CommtracNodesReport';
import {ConnectViewStats} from './ConnectViewStats';
import EventsReport, {
  EventsReportData,
  getEventsReportData,
} from './EventsReport';
import NodesReport, {getNodesReportData, NodesReportData} from './NodesReport';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => void;
}

interface ConnectViewData {
  viewType: 'commtracNodes' | 'nodes' | 'events' | 'alarmLog';
  commtracNodes: CommtracNodesReportData;
  nodes: NodesReportData;
  events: EventsReportData;
  alarmLogs: AlarmLogReportData;
}

const getConnectViewData = (): ConnectViewData => ({
  viewType: 'commtracNodes',
  commtracNodes: getCommtracNodesReportData(),
  nodes: getNodesReportData(),
  events: getEventsReportData(),
  alarmLogs: getAlarmLogReportData(),
});

export const ConnectView = (props: Props) => {
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);
  const reduxDispatch = useDispatch();

  /****************/
  /* panel config */
  /****************/

  const [config, setConfig] = useState<ConnectViewData>(
    !isEmpty(props.value)
      ? (props.value as ConnectViewData)
      : getConnectViewData()
  );

  /*********/
  /* nodes */
  /*********/

  const [fetchedNodesData, setFetchedNodesData] = useState<NodeListResponse>();
  const [fetchedNodesErrors, setFetchedNodesErrors] = useState<string[]>([]);
  const [fetchedNodesInProgress, setFetchedNodesInProgress] = useState(false);

  const fetchNodes = useCallback(async () => {
    setFetchedNodesInProgress(true);
    setFetchedNodesErrors([]);
    try {
      const params = {
        ...config.nodes.params,
        standby: config.viewType !== 'nodes',
      };
      const resp = await API.get<NodeListResponse>(`${apiBaseUrl}/node`, {
        params,
      });
      if (config.viewType === 'nodes') {
        reduxActions.assets.setAssetNodes(
          reduxDispatch,
          resp.data?.items || []
        );
      }
      setFetchedNodesData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedNodesErrors(messages);
    }
    setFetchedNodesInProgress(false);
  }, [config.nodes.params, config.viewType]);

  useEffect(() => {
    fetchNodes();
    props.onUpdate?.({...config});
  }, [config.nodes.params]);

  const nodesRefresh = useConfiguration(
    'auto-refresh',
    'commtrac_commnode_autorefresh_rate'
  );
  const nodesRefreshInterval = nodesRefresh?.value
    ? getMsFromS(+nodesRefresh?.value)
    : null;
  useRefreshInterval(fetchNodes, nodesRefreshInterval);

  /******************/
  /* commtrac nodes */
  /******************/

  const [fetchedCommtracNodesData, setFetchedCommtracNodesData] =
    useState<CommtracNodeListResponse>();
  const [fetchedCommtracNodesErrors, setFetchedCommtracNodesErrors] = useState<
    string[]
  >([]);
  const [fetchedCommtracNodesInProgress, setFetchedCommtracNodesInProgress] =
    useState(false);

  const fetchCommtracNodes = useCallback(async () => {
    setFetchedCommtracNodesInProgress(true);
    setFetchedCommtracNodesErrors([]);
    try {
      const params = {
        ...config.commtracNodes.params,
        standby: config.viewType !== 'commtracNodes',
      };
      const resp = await API.get<CommtracNodeListResponse>(
        `${apiBaseUrl}/commtrac-node`,
        {params}
      );
      setFetchedCommtracNodesData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedCommtracNodesErrors(messages);
    }
    setFetchedCommtracNodesInProgress(false);
  }, [config.viewType, config.commtracNodes.params]);

  useEffect(() => {
    fetchCommtracNodes();
    props.onUpdate?.({...config});
  }, [config.commtracNodes.params]);

  const commtracNodeRefresh = useConfiguration(
    'auto-refresh',
    'commtrac_miner_autorefresh_rate'
  );
  const commtracNodeRefreshInterval = commtracNodeRefresh?.value
    ? getMsFromS(+commtracNodeRefresh?.value)
    : null;
  useRefreshInterval(fetchCommtracNodes, commtracNodeRefreshInterval);

  /**********/
  /* events */
  /**********/

  const [fetchedEentsData, setFetchedEentsData] = useState<EventListResponse>();
  const [fetchedEventsErrors, setFetchedEventsErrors] = useState<string[]>([]);
  const [fetchedEventsInProgress, setFetchedEventsInProgress] = useState(false);

  const fetchEvents = useCallback(async () => {
    setFetchedEventsInProgress(true);
    setFetchedEventsErrors([]);
    try {
      const params = {
        ...config.events.params,
        limit: config.viewType !== 'events' ? 1 : config.events.params.limit,
      };
      const resp = await API.get<EventListResponse>(`${apiBaseUrl}/event`, {
        params,
      });
      setFetchedEentsData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedEventsErrors(messages);
    }
    setFetchedEventsInProgress(false);
  }, [config.viewType, config.events.params]);

  useEffect(() => {
    fetchEvents();
    props.onUpdate?.({...config});
  }, [config.events.params]);

  const eventRefresh = useConfiguration(
    'auto-refresh',
    'event_summary_autorefresh_rate'
  );
  const eventRefreshInterval = eventRefresh?.value
    ? getMsFromS(+eventRefresh?.value)
    : null;
  useRefreshInterval(fetchEvents, eventRefreshInterval);

  /*****************/
  /* event summary */
  /*****************/

  const [fetchedEventSummaryData, setFetchedEventSummaryData] =
    useState<EventSummaryResponse>();
  const [fetchedEventSummaryErrors, setFetchedEventSummaryErrors] = useState<
    string[]
  >([]);
  const [fetchedEventSummaryInProgress, setFetchedEventSummaryInProgress] =
    useState(false);

  const fetchEventSummary = useCallback(async () => {
    setFetchedEventSummaryInProgress(true);
    setFetchedEventSummaryErrors([]);
    try {
      const params = {
        date_start: config.events.params.date_start,
        date_end: config.events.params.date_end,
      };
      const resp = await API.get<EventSummaryResponse>(
        `${apiBaseUrl}/event/summary`,
        {params}
      );
      setFetchedEventSummaryData(resp.data);
      setSoundFiles(getSoundFilesFromEventSummary(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedEventSummaryErrors(messages);
    }
    setFetchedEventSummaryInProgress(false);
  }, [config.events.params.date_start, config.events.params.date_end]);

  const eventSummaryRefresh = useConfiguration(
    'commtrac',
    'commtrac_sound_interval'
  );
  const eventSummaryRefreshInterval = eventSummaryRefresh?.value
    ? getMsFromS(+eventSummaryRefresh?.value)
    : null;
  useRefreshInterval(fetchEventSummary, eventSummaryRefreshInterval);

  useEffect(() => {
    fetchEventSummary();
  }, []);

  useEffect(() => {
    fetchEventSummary();
    props.onUpdate?.({...config});
  }, [config.events.params.date_start, config.events.params.date_end]);

  const configurations = useConfigurations();
  const playedEventIds = useAppSelector(({app}) => app.playedEventIds);
  const newEventSoundConfiguration = useConfiguration(
    'commtrac',
    'sound_new_event'
  );

  const getSoundFilesFromEventSummary = (
    eventSummary: EventSummaryResponse
  ) => {
    const eventTypes = uniq(eventSummary.connect.sound.map((i) => i.type));
    const soundNames = eventTypes
      .map((i) => configurations[CONNECT_EVENT_SOUNDS[i] ?? '']?.value)
      .filter(isPresent);

    const newEvents = eventSummary.connect.sound.filter(
      (i) => !playedEventIds.includes(i.id)
    );
    if (newEvents.length) {
      soundNames.unshift(newEventSoundConfiguration?.value ?? '');
      reduxActions.app.setApp(reduxDispatch, {
        playedEventIds: [...playedEventIds, ...newEvents.map((i) => i.id)],
      });
    }
    return uniq(soundNames.map((i) => SOUNDS[i]).filter(isPresent));
  };

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const muteSounds = useAppSelector(({app}) => app.muteSounds);
  const [soundFiles, setSoundFiles] = useState<any[]>([]);
  const sound = useMemo(() => {
    const src = soundFiles[0];
    return src ? new Howl({src}) : null;
  }, [soundFiles]);

  useEffect(() => {
    if (sound) {
      if (muteSounds) {
        const message =
          'New event received, please enable sounds to miss nothing.';
        enqueueSnackbar(message, {
          variant: 'warning',
          action: (key) => (
            <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
          ),
        });
        return;
      }

      if (sound.state() === 'loaded') {
        sound.play();
      } else {
        sound.once('load', () => sound.play());
      }
      sound.once('end', () => {
        setSoundFiles(soundFiles.slice(1));
      });

      return () => {
        sound.unload();
      };
    }
  }, [sound]);

  /**********************/
  /* broadcast messages */
  /**********************/

  const [fetchedBroadcastMessagesData, setFetchedBroadcastMessagesData] =
    useState<BroadcastMessageListResponse>();
  const [fetchedBroadcastMessagesErrors, setFetchedBroadcastMessagesErrors] =
    useState<string[]>([]);
  const [
    fetchedBroadcastMessagesInProgress,
    setFetchedBroadcastMessagesInProgress,
  ] = useState(false);

  const fetchBroadcastMessages = async () => {
    setFetchedBroadcastMessagesInProgress(true);
    setFetchedBroadcastMessagesErrors([]);
    try {
      const params = {status: 1};
      const resp = await API.get<BroadcastMessageListResponse>(
        `${apiBaseUrl}/commtrac-broadcast-message`,
        {params}
      );
      setFetchedBroadcastMessagesData(resp.data);
      if (!resp.data.items.some((i) => i.id === selectedBroadcastMessageId)) {
        setSelectedBroadcastMessageId(resp.data.items[0]?.id);
      }
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedBroadcastMessagesErrors(messages);
    }
    setFetchedBroadcastMessagesInProgress(false);
  };

  const broadcastMessagesRefresh = useConfiguration(
    'auto-refresh',
    'event_summary_autorefresh_rate'
  );
  const broadcastMessagesRefreshInterval = broadcastMessagesRefresh?.value
    ? getMsFromS(+broadcastMessagesRefresh?.value)
    : null;
  useRefreshInterval(fetchBroadcastMessages, broadcastMessagesRefreshInterval);

  useEffect(() => {
    fetchBroadcastMessages();
  }, []);

  const [selectedBroadcastMessageId, setSelectedBroadcastMessageId] =
    useState<number>();
  const selectedBroadcastMessage = fetchedBroadcastMessagesData?.items.find(
    (i) => i.id === selectedBroadcastMessageId
  );

  useEffect(() => {
    if (config.viewType === 'commtracNodes') {
      fetchCommtracNodes();
    } else if (config.viewType === 'nodes') {
      fetchNodes();
    } else if (config.viewType === 'events') {
      fetchEvents();
    } else if (config.viewType === 'alarmLog') {
      fetchAlarmLogs();
    }
  }, [config.viewType]);

  const [isUnassignedEmployeesOpened, setIsUnassignedEmployeesOpened] =
    useState(false);
  const [isUnassignedAssetsOpened, setIsUnassignedAssetsOpened] =
    useState(false);

  /**********************/
  /*       alarms       */
  /**********************/
  const [openedAlarmLogNode, setOpenedAlarmLogNode] = useState<OpenedEntity>(
    []
  );
  // const [openedAssets, setOpenedAssets] = useState<OpenedEntity>([]);

  const [fetchedAlarmLogData, setFetchedAlarmLogData] =
    useState<AlarmLogNodeListResponse>();
  const [fetchedAlarmLogErrors, setFetchedAlarmLogErrors] = useState<string[]>(
    []
  );
  const [fetchedAlarmLogDataInProgress, setFetchedAlarmLogDataInProgress] =
    useState(false);
  // Start Handlng Zones
  // const assets = useSelector(reduxSelectors.assets.getAssets);
  // End Handling Zones
  const fetchAlarmLogs = useCallback(async () => {
    setFetchedAlarmLogDataInProgress(true);
    setFetchedAlarmLogErrors([]);

    try {
      const params = {
        ...config.alarmLogs.params,
        standby: config.viewType !== 'alarmLog',
      };

      const resp = await API.get<AlarmLogNodeListResponse>(
        `${apiBaseUrl}/alarm-module`,
        {params}
      );
      setFetchedAlarmLogData(resp.data);
      if (
        config.viewType === 'alarmLog' &&
        config.alarmLogs.params.status === 'all'
      ) {
        reduxActions.assets.setAssets(reduxDispatch, {
          alarm_logs: resp.data.items,
        });
      }
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedAlarmLogErrors(messages);
    } finally {
      setFetchedAlarmLogDataInProgress(false);
    }
  }, [config.viewType, config.alarmLogs.params]);

  useEffect(() => {
    fetchAlarmLogs();
    props.onUpdate?.({...config});
  }, [config.alarmLogs.params]);

  const alarmRefreshPeriod = useConfiguration(
    'alarm',
    'alarm_autorefresh_rate'
  );
  const alarmRefreshInterval = alarmRefreshPeriod?.value
    ? getMsFromS(+alarmRefreshPeriod?.value)
    : null;
  useRefreshInterval(fetchAlarmLogs, alarmRefreshInterval);
  /*****************/
  /* opened modals */
  /*****************/

  const [openedEmployees, setOpenedEmployees] = useState<OpenedEntity>({});
  const [openedAssets, setOpenedAssets] = useState<OpenedEntity>([]);

  const [openedCommunicationNodes, setOpenedCommunicationNodes] =
    useState<OpenedEntity>({});
  const [openedWifiPoints, setOpenedWifiPoints] = useState<OpenedEntity>({});
  const [locationCoordinates, setLocationCoordinates] =
    useState<MapLatLangCoordinates>();

  const handleOpenItem = (
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode
  ) => {
    if (entity === 'cn') {
      if (!(id in openedCommunicationNodes)) {
        setOpenedCommunicationNodes({
          ...openedCommunicationNodes,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'wifi') {
      if (!(id in openedWifiPoints)) {
        setOpenedWifiPoints({
          ...openedWifiPoints,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'employee') {
      if (!(id in openedEmployees)) {
        setOpenedEmployees({
          ...openedEmployees,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'asset') {
      if (!(id in openedAssets)) {
        setOpenedAssets({
          ...openedAssets,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'alarm') {
      if (!(id in openedAlarmLogNode)) {
        setOpenedAlarmLogNode({
          ...openedAlarmLogNode,
          [id]: {mode, id},
        });
      }
    }
  };

  /**************/
  /* group chat */
  /**************/
  const [isShownGroupChat, setIsShownGroupChat] = useState(false);

  const [panel] = usePanel();

  // 703 & 704 Alarms
  const [_703_704_alarmModule, set_703_704_alarmModule] =
    useState<EventSummaryResent>(null);
  useEffect(() => {
    if (fetchedEventSummaryData) {
      const {alarm} = fetchedEventSummaryData;
      if (
        alarm.unacked.filter((it) => it?.type === 703 || it?.type === 704)
          .length
      ) {
        set_703_704_alarmModule(
          alarm.unacked.filter(
            (it) => it?.type === 703 || it?.type === 704
          )?.[0]
        );
      }

      if (alarm.unacked.filter((it) => it?.type === 705).length) {
        const buttonPressedEvent =
          alarm.unacked.filter((it) => it?.type === 705)?.[0] ?? null;
        if (config.alarmLogs && buttonPressedEvent) {
          const v = {
            ...config.alarmLogs,
            enableAlarm: {
              open: true,
              message_id: buttonPressedEvent.id,
              commtrac_external_id:
                buttonPressedEvent?.commtrac_external_id ?? undefined,
            },
          };

          setConfig(
            update(config, {
              viewType: {
                $set: 'alarmLog',
              },
              alarmLogs: {
                $set: v ?? getAlarmLogReportData(),
              },
            })
          );
        }
      }
    }
  }, [fetchedEventSummaryData]);

  return (
    <Box
      id="connect-view-panel"
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
      bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>
      <Box>
        <ConnectViewStats
          activeTab={config.viewType}
          commtracNodesTotal={fetchedCommtracNodesData?.total}
          commtracNodesConfig={config.commtracNodes}
          nodesTotal={fetchedNodesData?.total}
          nodesConfig={config.nodes}
          eventsCount={fetchedEentsData?.count}
          broadcastMessagesData={fetchedBroadcastMessagesData}
          selectedBroadcastMessageId={selectedBroadcastMessageId}
          eventSummaryData={fetchedEventSummaryData}
          isNodesLoading={fetchedNodesInProgress}
          isCommtracNodesLoading={fetchedCommtracNodesInProgress}
          isEventsLoading={fetchedEventsInProgress}
          isEventSummaryLoading={fetchedEventSummaryInProgress}
          isBroadcastMessagesLoading={fetchedBroadcastMessagesInProgress}
          isAlarmLogsLoading={fetchedAlarmLogDataInProgress}
          alarmLogsTotal={fetchedAlarmLogData?.total}
          alarmLogsConfig={config.alarmLogs}
          onChangeCommtracNodesConfig={(v) =>
            setConfig(
              update(config, {
                commtracNodes: {
                  $set: v ?? getCommtracNodesReportData(),
                },
              })
            )
          }
          onChangeAlertLogConfig={(v) =>
            setConfig(
              update(config, {
                alarmLogs: {
                  $set: v ?? getAlarmLogReportData(),
                },
              })
            )
          }
          onChangeNodesConfig={(v) =>
            setConfig(
              update(config, {
                nodes: {
                  $set: v ?? getNodesReportData(),
                },
              })
            )
          }
          onChangeActiveTab={(v) => {
            if (config.viewType !== v) {
              setConfig(
                update(config, {
                  viewType: {
                    $set: v,
                  },
                })
              );
            }
          }}
          onChangeSelectedBroadcastMessageId={setSelectedBroadcastMessageId}
          onBroadcastMessageCleared={(item) => {
            fetchBroadcastMessages();
            if (selectedBroadcastMessageId === item.id) {
              setSelectedBroadcastMessageId(
                fetchedBroadcastMessagesData?.items.find(
                  (i) => i.id !== selectedBroadcastMessageId
                )?.id
              );
            }
          }}
          onBroadcastMessageCreated={() => {
            fetchBroadcastMessages();
            fetchCommtracNodes();
          }}
          onEventSummaryFetch={fetchEventSummary}
          // onUnassignedEmployeesOpen={() => setIsUnassignedEmployeesOpened(true)}
          // onUnassignedAssetsOpen={() => setIsUnassignedAssetsOpened(true)}
        />
      </Box>

      <Box
        height="100%"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        position="relative"
        bgcolor={isDarkMode ? '#2E2E2E' : '#FFF'}
      >
        <Box>
          {fetchedEventSummaryErrors.map((error, idx) => (
            <Alert
              key={`error-es-${idx}`}
              severity="error"
              onClose={fetchEventSummary}
            >
              {error}
            </Alert>
          ))}
          {fetchedBroadcastMessagesErrors.map((error, idx) => (
            <Alert
              key={`error-b-${idx}`}
              severity="error"
              onClose={fetchBroadcastMessages}
            >
              {error}
            </Alert>
          ))}
          {fetchedCommtracNodesErrors.map((error, idx) => (
            <Alert
              key={`error-c-${idx}`}
              severity="error"
              onClose={fetchCommtracNodes}
            >
              {error}
            </Alert>
          ))}
          {fetchedNodesErrors.map((error, idx) => (
            <Alert key={`error-n-${idx}`} severity="error" onClose={fetchNodes}>
              {error}
            </Alert>
          ))}
          {fetchedEventsErrors.map((error, idx) => (
            <Alert
              key={`error-e-${idx}`}
              severity="error"
              onClose={fetchEvents}
            >
              {error}
            </Alert>
          ))}
          {fetchedAlarmLogErrors.map((error, idx) => (
            <Alert
              key={`error-es-${idx}`}
              severity="error"
              onClose={fetchEventSummary}
            >
              {error}
            </Alert>
          ))}
        </Box>
        <Box height="100%" overflow="hidden">
          {config.viewType === 'commtracNodes' ? (
            <>
              <Backdrop
                open={fetchedCommtracNodesInProgress}
                sx={{position: 'absolute', zIndex: 1199}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <CommtracNodesReport
                value={config.commtracNodes}
                onChange={(v) =>
                  setConfig(
                    update(config, {
                      commtracNodes: {
                        $set: v ?? getCommtracNodesReportData(),
                      },
                    })
                  )
                }
                loading={fetchedCommtracNodesInProgress}
                fetchedData={fetchedCommtracNodesData}
                selectedBroadcastMessage={selectedBroadcastMessage}
                onOpenHistory={props.onOpenHistory}
                onRefresh={fetchCommtracNodes}
                onOpenItem={handleOpenItem}
                onChangeLocationCoordinates={setLocationCoordinates}
                onChangeIsShownGroupChat={setIsShownGroupChat}
              />
            </>
          ) : config.viewType === 'nodes' ? (
            <>
              <Backdrop
                open={fetchedNodesInProgress}
                sx={{position: 'absolute', zIndex: 1199}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <NodesReport
                value={config.nodes}
                locationCoordinates={locationCoordinates}
                loading={fetchedNodesInProgress}
                fetchedData={fetchedNodesData}
                onOpenHistory={props.onOpenHistory}
                onRefresh={() => {
                  fetchNodes();
                  setLocationCoordinates(undefined);
                }}
                onChange={(v) =>
                  setConfig(
                    update(config, {
                      nodes: {
                        $set: v ?? getNodesReportData(),
                      },
                    })
                  )
                }
                onOpenItem={handleOpenItem}
                onChangeLocationCoordinates={setLocationCoordinates}
              />
            </>
          ) : config.viewType === 'events' ? (
            <>
              <Backdrop
                open={fetchedEventsInProgress}
                sx={{position: 'absolute', zIndex: 1199}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <EventsReport
                value={config.events}
                loading={fetchedEventsInProgress}
                fetchedData={fetchedEentsData}
                onRefresh={fetchEvents}
                onChange={(v) =>
                  setConfig(
                    update(config, {
                      events: {
                        $set: v ?? getEventsReportData(),
                      },
                    })
                  )
                }
              />
            </>
          ) : config.viewType === 'alarmLog' ? (
            <>
              <Backdrop
                open={fetchedAlarmLogDataInProgress}
                sx={{position: 'absolute', zIndex: 1199}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <AlarmLogReport
                value={config.alarmLogs}
                onChange={(v) => {
                  setConfig(
                    update(config, {
                      alarmLogs: {
                        $set: v ?? getAlarmLogReportData(),
                      },
                    })
                  );
                }}
                locationCoordinates={locationCoordinates}
                onOpenItem={handleOpenItem}
                onOpenHistory={props.onOpenHistory}
                onRefresh={fetchAlarmLogs}
                loading={fetchedAlarmLogDataInProgress}
                fetchedData={fetchedAlarmLogData}
                onChangeLocationCoordinates={setLocationCoordinates}
              />
            </>
          ) : null}
        </Box>
      </Box>

      {values(openedEmployees).map(({id, mode}) => (
        <ModalDraggable key={`employee-${id}`} open={true}>
          <AssetHumanItemUpsert
            pk={id}
            mode={mode === 'update' || mode === 'chat' ? mode : 'view'}
            onPurged={() => {
              fetchCommtracNodes();
              setOpenedEmployees(update(openedEmployees, {$unset: [id]}));
            }}
            onOpenHistory={(item) => {
              if (item.commtrac_node_id) {
                props.onOpenHistory?.(item.commtrac_node_id, 'employee');
                setOpenedEmployees(update(openedEmployees, {$unset: [id]}));
              }
            }}
            onSubmitted={() => {
              fetchCommtracNodes();
            }}
            onClose={() => {
              setOpenedEmployees(update(openedEmployees, {$unset: [id]}));
            }}
          />
        </ModalDraggable>
      ))}

      {values(openedAssets).map(({id, mode}) => (
        <ModalDraggable key={`asset-${id}`} open={true}>
          <AssetMachineItemUpsert
            pk={id}
            mode={mode === 'update' ? mode : 'view'}
            onPurged={() => {
              fetchCommtracNodes();
              setOpenedAssets(update(openedAssets, {$unset: [id]}));
            }}
            onOpenHistory={(item) => {
              if (item.commtrac_node_id) {
                props.onOpenHistory?.(item.commtrac_node_id, 'asset');
                setOpenedAssets(update(openedAssets, {$unset: [id]}));
              }
            }}
            onSubmitted={() => {
              fetchCommtracNodes();
            }}
            onClose={() => {
              setOpenedAssets(update(openedAssets, {$unset: [id]}));
            }}
          />
        </ModalDraggable>
      ))}

      {values(openedCommunicationNodes).map(({id, mode}) => (
        <ModalDraggable key={`cn-${id}`} open={true}>
          {({isActive}) => (
            <CommunicationNodeItemUpsert
              pk={id}
              mode={mode === 'update' || mode === 'ack' ? mode : 'view'}
              isActiveModal={isActive}
              locationCoordinates={locationCoordinates}
              onPurged={() => {
                fetchNodes();
                setOpenedCommunicationNodes(
                  update(openedCommunicationNodes, {$unset: [id]})
                );
              }}
              onOpenHistory={(externalId, type) => {
                props.onOpenHistory?.(externalId, type);
                setOpenedCommunicationNodes(
                  update(openedCommunicationNodes, {$unset: [id]})
                );
              }}
              onSubmitted={() => {
                fetchNodes();
                setLocationCoordinates(undefined);
              }}
              onClose={() => {
                setOpenedCommunicationNodes(
                  update(openedCommunicationNodes, {$unset: [id]})
                );
                setLocationCoordinates(undefined);
              }}
            />
          )}
        </ModalDraggable>
      ))}

      {values(openedWifiPoints).map(({id, mode}) => (
        <ModalDraggable key={`wifi-${id}`} open={true}>
          {({isActive}) => (
            <WifiPointItemUpsert
              pk={id}
              mode={mode === 'update' || mode === 'ack' ? mode : 'view'}
              isActiveModal={isActive}
              locationCoordinates={locationCoordinates}
              onPurged={() => {
                fetchNodes();
                setOpenedWifiPoints(update(openedWifiPoints, {$unset: [id]}));
              }}
              onOpenHistory={(type) => {
                props.onOpenHistory?.(id, type);
                setOpenedWifiPoints(update(openedWifiPoints, {$unset: [id]}));
              }}
              onSubmitted={() => {
                fetchNodes();
                setLocationCoordinates(undefined);
              }}
              onClose={() => {
                setOpenedWifiPoints(update(openedWifiPoints, {$unset: [id]}));
                setLocationCoordinates(undefined);
              }}
            />
          )}
        </ModalDraggable>
      ))}

      {values(openedAlarmLogNode).map(({id, mode}) => (
        <ModalDraggable key={`alarm-${id}`} open={true}>
          {({isActive}) => (
            <AlarmItemUpsert
              pk={id}
              isActiveModal={isActive}
              mode={mode === 'chat' ? 'view' : mode}
              onPurged={() => {
                fetchCommtracNodes();
                setOpenedAlarmLogNode(
                  update(openedAlarmLogNode, {$unset: [id]})
                );
              }}
              onOpenHistory={(item, type) => {
                props.onOpenHistory?.(item.id, type);
                setOpenedAlarmLogNode(
                  update(openedAlarmLogNode, {$unset: [id]})
                );
              }}
              onSubmitted={() => {
                fetchAlarmLogs();
              }}
              onClose={() => {
                setOpenedAlarmLogNode(
                  update(openedAlarmLogNode, {$unset: [id]})
                );
              }}
              locationCoordinates={locationCoordinates}
            />
          )}
        </ModalDraggable>
      ))}

      <ModalDraggable open={isShownGroupChat}>
        <AssetHumanGroupChat onClose={() => setIsShownGroupChat(false)} />
      </ModalDraggable>

      <ModalDraggable
        open={isUnassignedEmployeesOpened}
        sx={{
          width: 1000,
          left: 'calc(50% - 500px)',
        }}
      >
        <AssetHumanUnassignedList
          onOpenItem={(id) => handleOpenItem(id, 'employee')}
          onClose={() => setIsUnassignedEmployeesOpened(false)}
        />
      </ModalDraggable>

      <ModalDraggable
        open={isUnassignedAssetsOpened}
        sx={{
          width: 1000,
          left: 'calc(50% - 500px)',
        }}
      >
        <AssetMachineUnassignedList
          onOpenItem={(id) => handleOpenItem(id, 'asset')}
          onClose={() => setIsUnassignedAssetsOpened(false)}
        />
      </ModalDraggable>

      {_703_704_alarmModule && (
        <ModalDraggable
          open={true}
          sx={{
            width: 400,
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%) translateY(-50%) !important',
          }}
        >
          <AlarmSimpleAcknowledgePopup
            item={_703_704_alarmModule}
            onSubmitted={() => {
              set_703_704_alarmModule(null);
              fetchEventSummary();
            }}
          />
        </ModalDraggable>
      )}
    </Box>
  );
};
