/* eslint-disable @typescript-eslint/no-unused-vars */
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Paper,
} from '@mui/material';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  AlarmGroup,
  AlarmGroupListResponse,
  AlarmLogNodeListResponse,
} from '../../interfaces/AlarmLogNode';
import reduxActions from '../../redux/actions';
import reduxSelectors from '../../redux/selectors';
import AccessControl from "../common/AccessControl";
import DataGrid, {DataGridColumn, DataGridRef} from '../common/DataGrid';
import {AlarmGroupItemDeleteButton} from './AlarmGroupItemDeleteButton';
import {AlarmGroupItemUpsertButton} from './AlarmGroupItemUpsertButton';
export interface AlarmGroupListRef {
  fetch?: Function;
}

const AlarmGroupList = forwardRef<AlarmGroupListRef, React.PropsWithChildren>(
  // eslint-disable-next-line no-empty-pattern
  ({}, ref) => {
    const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);
    const paperBg = isDarkMode ? '#222222' : '#FFF';
    const dataGridRef = useRef<DataGridRef>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [fetchingErrors, setFetchingErrors] = useState<string[]>([]);
    const assets = useSelector(reduxSelectors.assets.getAssets);
    const alarm_groups = assets.alarm_groups;
    const reduxDispatch = useAppDispatch();
    useImperativeHandle(ref, () => ({
      fetch: () => {
        fetchData();
      },
    }));

    const fetchData = async () => {
      setIsFetching(true);
      try {
        const params = {};
        const alarm_logs = (
          await API.get<AlarmLogNodeListResponse>(
            `${apiBaseUrl}/alarm-module`,
            {
              params,
            }
          )
        ).data.items;
        const alarm_groups = (
          await API.get<AlarmGroupListResponse>(
            `${apiBaseUrl}/alarm-module-group`,
            {
              params,
            }
          )
        ).data.items;
        reduxActions.assets.setAssets(reduxDispatch, {alarm_logs});
        return reduxActions.assets.setAssets(reduxDispatch, {alarm_groups});
      } catch (error) {
        const messages = getMessagesFromApiError(error);
        setFetchingErrors(messages);
      } finally {
        setIsFetching(false);
      }
    };

    useEffect(() => {
      fetchData();
      reduxDispatch(reduxActions.assets.fetchAlarms);
    }, []);

    const columns: DataGridColumn<AlarmGroup>[] = [
      {
        field: 'id',
        sortable: true,
        headerName: 'ID',
      },
      {field: 'name', headerName: 'Group Names', sortable: true},
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        renderCell: ({row}) => {
          return <Box textTransform="capitalize">{row.status}</Box>;
        },
      },
      {
        field: 'description',
        headerName: 'Description',
      },
      {
        field: 'created_at',
        headerName: 'Created at',
      },
      {
        field: 'updated_at',
        headerName: 'Updated at',
      },
      {
        field: 'alarm_modules',
        headerName: 'Alarm Modules',
        renderCell: ({row}) => {
          return <Box>{row.alarm_modules?.length}</Box>;
        },
      },
      {
        field: 'action',
        headerName: 'Actions',
        type: 'actions',
        renderCell: ({row}) => {
          return (
            <Box display="flex" columnGap="10px">
              <AccessControl
                permissions={["patch::/alarm-module-group/:id(\\d+)"]}
              >
                <AlarmGroupItemUpsertButton
                  item={row}
                  component={IconButton}
                  componentProps={{
                    color: 'primary',
                    size: 'small',
                  }}
                  onSubmitted={() => fetchData()}
                >
                  <EditIcon />
                </AlarmGroupItemUpsertButton>
              </AccessControl>
              <AccessControl
                permissions={["delete::/alarm-module-group/:id(\\d+)"]}
              >
                <AlarmGroupItemDeleteButton
                  itemId={row.id}
                  component={IconButton}
                  componentProps={{
                    color: 'error',
                    size: 'small',
                  }}
                  onDeleted={() => fetchData()}
                >
                  <DeleteOutlineOutlinedIcon />
                </AlarmGroupItemDeleteButton>
              </AccessControl>
            </Box>
          );
        },
      },
    ];

    return (
      <Paper
        sx={{
          position: 'relative',
          height: '100%',
          overflow: 'hidden',
          p: 3,
          bgcolor: paperBg,
          backgroundImage: 'none',
        }}
      >
        <Backdrop open={isFetching} sx={{position: 'absolute'}}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {fetchingErrors.map((error, idx) => (
          <Alert
            key={`error-c-${idx}`}
            severity="error"
            onClose={() => {
              setFetchingErrors([]);
            }}
          >
            {error}
          </Alert>
        ))}
        <DataGrid
          ref={dataGridRef}
          rows={alarm_groups}
          columns={columns}
          loading={isFetching}
          pagination
          size="small"
          sx={{
            bgcolor: 'transparent',
            th: {
              bgcolor: paperBg,
            },
          }}
        />
      </Paper>
    );
  }
);

export default AlarmGroupList;
