import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {JsonEditor} from 'jsoneditor-react';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as yup from 'yup';

import {apiBaseUrl} from '../../api/urls';
import {useCrudItem} from '../../hooks/useCridItem';
import {
  SubscriptionReport,
  SubscriptionReportInput,
} from '../../interfaces/SubscriptionReport';
import reduxSelectors from '../../redux/selectors';

const inputValidationSchema = yup.object().shape({
  name: yup.string().nullable().required('Field is required'),

  report_code: yup.string().nullable().required('Field is required'),
});

const getInput = (item?: SubscriptionReport): SubscriptionReportInput => ({
  name: item?.name ?? '',
  report_code: item?.report_code ?? '',
  filters: item?.filters ?? {},
});

interface SubscriptionReportItemUpsertProps {
  pk?: number;
  item?: SubscriptionReport;
  onCancel?: Function;
  onSubmitted?: Function;
}

export const SubscriptionReportItemUpsert: React.FC<
  SubscriptionReportItemUpsertProps
> = ({pk, item, onCancel, onSubmitted}) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const [jsonEditorEl, setJsonEditorEl] = useState<any>(null);

  const crud = useCrudItem<SubscriptionReport>({
    pk,
    item,
    endpointBase: `${apiBaseUrl}/subscription-report`,
    onSubmitted,
  });

  const formik = useFormik({
    initialValues: getInput(item),
    validationSchema: inputValidationSchema,
    onSubmit: (values) => crud.onSubmit(values),
  });

  useEffect(() => {
    const newInput = getInput?.(crud.item);
    if (!_.isEqual(newInput, formik.values)) {
      formik.setValues(getInput?.(crud.item));
    }
  }, [crud.item]);

  const handleSubmit = () => {
    // It looks like JsonEditor doesn't have proper means to validate its contents,
    // so we must check manually if filters JSON doesn't contain errors before submit
    if (!jsonEditorEl.innerText.match(/Parse error/)) {
      formik.handleSubmit();
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Backdrop open={crud.isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {!assets.subscriptionReportType.length && (
        <Alert severity="error" sx={{mb: 2}}>
          You have no subscription reports available
        </Alert>
      )}

      {crud.errors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      {!!assets.subscriptionReportType.length && (
        <>
          <Box mb={3}>
            <TextField
              sx={{my: 2}}
              value={formik.values.name}
              fullWidth
              name="name"
              label="Name"
              type="text"
              error={!!formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
            />
          </Box>

          <Box mb={3}>
            <TextField
              value={formik.values.report_code}
              name="report_code"
              select
              fullWidth
              label="Report"
              error={
                !!formik.touched.report_code && !!formik.errors.report_code
              }
              helperText={
                formik.touched.report_code && formik.errors.report_code
              }
              onChange={formik.handleChange}
            >
              {assets.subscriptionReportType.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box sx={{my: 3}}>
            <Divider sx={{my: 2}}>FILTERS</Divider>

            <JsonEditor
              key="chart"
              value={formik.values.filters}
              mode="code"
              innerRef={(el) => setJsonEditorEl(el)}
              htmlElementProps={{style: {height: '210px'}}}
              onChange={(value) => formik.setFieldValue('filters', value)}
            />
          </Box>
        </>
      )}

      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => onCancel?.()}>Cancel</Button>

        {!!assets.subscriptionReportType.length && (
          <LoadingButton
            variant="contained"
            loading={formik.isSubmitting}
            sx={{ml: 1}}
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};
