import {RefObject} from 'react';
import {DraggableData, DraggableEvent} from 'react-draggable';

import {DraggableOrderCallbackResponse} from '../../../../interfaces/DataGrid';

export interface DraggableLayoutItem {
  position: number;
  index: number;
  item: unknown;
  ref: RefObject<HTMLTableRowElement>;
  position_shift?: number;
  new_position?: number;
}

export type DraggableLayout = DraggableLayoutItem[];

const getRowHeight = (draggableLayout: DraggableLayout): number => {
  return draggableLayout[0].ref.current?.clientHeight ?? 0;
};

const getMaxPosition = (draggableLayout: DraggableLayout) => {
  return Math.max(...draggableLayout.map((layout) => layout.position));
};

const getPositionShift = (layout: DraggableLayoutItem) => {
  if (typeof layout.position_shift !== 'number') {
    layout.position_shift = 0;
  }

  return layout.position_shift;
};

/*const getNewPosition = (layout: DraggableLayoutItem) => {
  if (typeof layout.new_position !== "number") {
    layout.new_position = layout.position;
  }

  return layout.new_position;
};*/

const between = (num1: number, num2: number, target: number): boolean => {
  if (target >= num1 && target <= num2) {
    return true;
  }

  if (target >= num2 && target <= num1) {
    return true;
  }

  return false;
};

const getDraggedPosition = (
  draggableLayout: DraggableLayout,
  layout: DraggableLayoutItem,
  data: DraggableData
): number => {
  const height = getRowHeight(draggableLayout);
  const max = getMaxPosition(draggableLayout);
  const currentPosition = layout.position;
  const {y} = data;

  const calculatedPosition =
    currentPosition + Math.sign(y / height) * Math.round(Math.abs(y / height));

  return calculatedPosition < 0
    ? 0
    : calculatedPosition > max
      ? max
      : calculatedPosition;
};

const updatePosition = (
  draggableLayout: DraggableLayout,
  layout: DraggableLayoutItem,
  data: DraggableData,
  position: number
) => {
  const height = getRowHeight(draggableLayout);
  const initialPosition = layout.position;
  const shift = initialPosition < position ? -1 : 1;

  draggableLayout.forEach((layout) => {
    if (between(initialPosition, position, layout.position)) {
      if (layout.position !== initialPosition) {
        layout.position_shift = shift;
        layout.new_position = layout.position + shift;
      } else {
        layout.position_shift = 0;
        layout.new_position = position;
      }
    } else {
      layout.position_shift = 0;
      layout.new_position = layout.position;
    }
  });

  draggableLayout.forEach((layout) => {
    const y = height * getPositionShift(layout);
    if (layout.ref && layout.ref.current) {
      if (layout.position !== initialPosition) {
        layout.ref.current.style.transform = `translate(0px, ${y}px)`;
      }
    }
  });
};

export const clearTransform = (draggableLayout: DraggableLayout) => {
  draggableLayout.forEach((layout) => {
    if (layout.ref && layout.ref.current) {
      layout.ref.current.style.transform = `translate(0px, 0px)`;
    }
  });
};

export const handleDrag = (
  e: DraggableEvent,
  data: DraggableData,
  draggableLayout: DraggableLayout,
  rowIdx: number
) => {
  const layout = draggableLayout.filter((layout) => layout.index === rowIdx)[0];
  const newPosition = getDraggedPosition(draggableLayout, layout, data);

  updatePosition(draggableLayout, layout, data, newPosition);
};

export const handleDragStop = (
  e: DraggableEvent,
  data: DraggableData,
  draggableLayout: DraggableLayout
): DraggableOrderCallbackResponse => {
  return draggableLayout.map((l) => {
    return {
      id: +(l.item as any).row?.id,
      order: l.new_position ?? 0,
    };
  });
};
