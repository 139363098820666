import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import SpeedIcon from '@mui/icons-material/Speed';
import VideocamIcon from '@mui/icons-material/Videocam';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WifiIcon from '@mui/icons-material/Wifi';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import {isNil} from 'lodash';
import capitalize from 'lodash/capitalize';
import Image from 'mui-image';
import {useEffect, useMemo, useRef} from 'react';
import {useDispatch} from 'react-redux';

import {KILOMETERS_IN_MILE, SPEED_FORMATS} from '../../../constants/common';
import {useConfiguration} from '../../../hooks/configuration';
import {useAppSelector} from '../../../hooks/redux';
import usePrevious from '../../../hooks/usePrevious';
import HeatmapIcon from '../../../images/other/heatmap_icon.svg';
import {ExportField} from '../../../interfaces/Export';
import {
  SafeyeNode,
  SafeyeNodeListQuery,
  SafeyeNodeListResponse,
} from '../../../interfaces/SafeyeNode';
import appActions from '../../../redux/app/actions';
import {eventIconsLegacy} from '../../../utils/event-icons';
import {OpenedSafeyeNodeMode} from '../../../utils/hazardAIPanel';
import AccessControl from '../../common/AccessControl';
import {AutoRefreshSettingsSelect} from '../../common/AutoRefreshSettingsSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {
  HazardMap,
  MapLatLangCoordinates,
  MapLayerId,
  MapType,
} from '../../common/HazardMap';
import {ResizableColumns} from '../../common/ResizableColumns';
import SafeyeNodeItemsEditButton from '../../safeye-node/buttons/SafeyeNodeItemsEditButton';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {SafeyeNodeStatusSelect} from '../../selectors/SafeyeNodeStatusSelect';
import {SitesSelect} from '../../selectors/SitesSelect';

interface Props {
  value?: WarningHazardsData;
  fetchedData?: SafeyeNodeListResponse;
  isLoading?: boolean;
  onChange?: (value?: WarningHazardsData) => void;
  onOpenHistory?: (
    // @todo check if this is a suitable type
    id: number | string | number[],
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => void;
  onOpenItem?: (id: number, mode?: OpenedSafeyeNodeMode) => void;
  onRefresh?: () => void;
  onChangeLocationCoordinates?: (value?: MapLatLangCoordinates) => void;
}

export interface WarningHazardsData {
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  mapType?: MapType;
  params: Partial<SafeyeNodeListQuery>;
  selectedIds: number[] | null;
  shownFields: {
    all?: string[];
  };
  grid: {
    pageSize: number;
    page: number;
  };
  exportFields: ExportField[];
}

const DEFAULT_SHOWN_FIELDS = [
  'id',
  'no_heartbeat',
  'no_location',
  'camera',
  'speed_limit',
  'asset_name',
  'type',
  'site',
  'name',
  'timestamp',
  'speed',
  'max_speed',
  'hazard_person',
  'hazard_vehicle',
  'latitude',
  'longitude',
];

export const getWarningHazardsData = (): WarningHazardsData => {
  return {
    mapLayers: ['safeye_nodes'],
    mapLevel: null,
    params: {
      status: 'active',
      site_id: null,
      limit: 2000,
      page: 0,
      date_start: dayjs().format('YYYY-MM-DD'),
      date_end: dayjs().format('YYYY-MM-DD'),
    },
    selectedIds: [],
    shownFields: {
      all: DEFAULT_SHOWN_FIELDS,
    },
    grid: {
      page: 0,
      pageSize: 25,
    },
    exportFields: [],
  };
};

const WarningHazardReport = ({
  value,
  fetchedData,
  isLoading,
  onChange,
  onOpenItem,
  onOpenHistory,
  onRefresh,
  onChangeLocationCoordinates,
}: Props) => {
  const dispatch = useDispatch();

  // Constants
  const config = useMemo(() => value ?? getWarningHazardsData(), [value]);
  const machineTypes = useAppSelector(({assets}) => assets.machine_types);
  const sites = useAppSelector(({assets}) => assets.sites);
  const speedFormat = useAppSelector(({app}) => app.speedFormat);

  const handleChangeSpeedFormat = (): void => {
    const currentSpeedFormat =
      speedFormat === SPEED_FORMATS[0] ? SPEED_FORMATS[1] : SPEED_FORMATS[0];

    appActions.setApp(dispatch, {speedFormat: currentSpeedFormat});

    onRefresh?.();
  };

  const getSpeedInActualFormat = ({
    currentFormat,
    speed,
  }: {
    currentFormat: (typeof SPEED_FORMATS)[number];
    speed: number;
  }): number => {
    return currentFormat === 'mph'
      ? speed
      : Number.parseFloat((speed * KILOMETERS_IN_MILE).toFixed(1));
  };

  /*************/
  /* data grid */
  /*************/
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];

  const columns: DataGridColumn<SafeyeNode>[] = [
    {
      field: 'select',
      type: 'select',
      doNotExport: true,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => {
        return (
          <Checkbox
            color="primary"
            checked={selectedItems.includes(row.id)}
            onChange={() => toggleSelectItem(row.id)}
          />
        );
      },
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'actions',
      renderHeader: () => (
        <Tooltip title="Edit">
          <MoreHorizIcon />
        </Tooltip>
      ),
      type: 'actions',
      doNotExport: true,
      sxHeader: {textAlign: 'center', p: 0.5},
      sxCell: {textAlign: 'center', p: 0.5},
      renderCell: ({row}) =>
        !row.acknowledge ? (
          <AccessControl
            permissions={['patch::/safeye-nano-node/:id(\\d+)/acknowledge']}
          >
            <IconButton
              color="primary"
              onClick={() => {
                if (onOpenItem) {
                  onOpenItem(row?.id, 'ack');
                }
              }}
            >
              <Tooltip title="Acknowledge">
                <WarningAmberIcon color="warning" />
              </Tooltip>
            </IconButton>
          </AccessControl>
        ) : (
          <SafeyeNodeItemsEditButton
            component={IconButton}
            componentProps={{
              color: 'primary',
            }}
            item={row}
            onOpenItem={(id: number, mode: OpenedSafeyeNodeMode | undefined) =>
              onOpenItem?.(id, mode)
            }
            onOpenHistory={onOpenHistory}
          >
            <MoreHorizIcon />
          </SafeyeNodeItemsEditButton>
        ),
    },
    {
      field: 'no_heartbeat',
      headerName: 'Heartbeat',
      sxHeader: {minWidth: 60},
      valueGetter: ({row}) => row.e_801,
      renderHeader: () => (
        <Tooltip title="Heartbeat">
          <WifiIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.e_801) {
          return (
            <Tooltip title="Safeye Node No Heartbeat">
              {eventIconsLegacy.nodes.cnNoHeartbeat}
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'no_location',
      headerName: 'No Location',
      sxHeader: {minWidth: 60},
      valueGetter: ({row}) => row.e_806 || (!row?.latitude && !row?.longitude),
      renderHeader: () => (
        <Tooltip title="No Location">
          <LocationOnIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.e_806 || (!row?.latitude && !row?.longitude)) {
          return (
            <Tooltip title="No Location">
              <LocationOffIcon sx={{color: 'red'}} />
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'camera',
      headerName: 'Camera',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Camera">
          <VideocamIcon />
        </Tooltip>
      ),
      valueGetter: ({row}) => {
        if (row.e_804) {
          return '1';
        }
        if (row.e_803) {
          return '2';
        }

        return '3';
      },
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.e_804) {
          return (
            <Tooltip
              title={`Camera ${row.e_803 ? 'impaired and ' : ''}disconnected`}
            >
              <VideocamIcon sx={{color: 'red'}} />
            </Tooltip>
          );
        }
        if (row.e_803) {
          return (
            <Tooltip title="Camera impaired">
              <VideocamIcon sx={{color: 'yellow'}} />
            </Tooltip>
          );
        }
      },
    },

    {
      field: 'speed_limit',
      headerName: 'Speed Limit',
      sxHeader: {textAlign: 'center', p: 0.5, minWidth: 60},
      sxCell: {textAlign: 'center', p: 0.5},
      renderHeader: () => (
        <Tooltip title="Speed Limit">
          <SpeedIcon />
        </Tooltip>
      ),
      sortable: true,
      valueGetter: ({row}) => (row.e_805 ? 'exceeded' : ''),
      renderCell: ({row}) => {
        if (row.e_805) {
          return (
            <Tooltip title="Speed Limit Exceeded">
              <SpeedIcon sx={{color: 'red'}} />
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'asset_name',
      headerName: 'Machine Name',
      sortable: true,
      valueGetter: ({row}) => row.asset_name,
    },
    {
      field: 'type',
      headerName: 'Machine Type',
      sortable: true,
      valueGetter: ({row}) => {
        if (!isNil(row.type_id)) {
          const matchedMachineType = machineTypes.find(
            ({id}) => id === row.type_id
          );

          return matchedMachineType?.name
            ? capitalize(matchedMachineType?.name)
            : '';
        }

        return '';
      },
    },
    {
      field: 'site',
      headerName: 'Site',
      sortable: true,
      valueGetter: ({row}) => {
        if (!isNil(row.site_id)) {
          const siteName = sites.find(({id}) => id === row.site_id);

          return siteName?.name ? capitalize(siteName?.name) : '';
        }

        return '';
      },
    },
    {
      field: 'name',
      headerName: 'Camera Name',
      sortable: true,
      valueGetter: ({row}) => row.name,
    },

    {
      field: 'timestamp',
      headerName: 'Timestamp',
      sortable: true,
      valueGetter: ({row}) => row?.timestamp,
    },

    {
      field: 'speed',
      headerName: 'Last speed',
      sortable: true,
      renderCell: ({row}) => (
        <Typography align="center">
          {row?.speed
            ? getSpeedInActualFormat({
                speed: row?.speed,
                currentFormat: speedFormat,
              })
            : '0'}
        </Typography>
      ),
    },
    {
      field: 'max_speed',
      headerName: 'Max Speed',
      sortable: true,
      renderCell: ({row}) => (
        <Typography align="center">
          {row?.max_speed
            ? getSpeedInActualFormat({
                speed: row?.max_speed,
                currentFormat: speedFormat,
              })
            : '0'}
        </Typography>
      ),
    },

    {
      field: 'hazard_person',
      headerName: 'People Hazard',
      sortable: true,
      renderCell: ({row}) => (
        <Typography align="center">{row?.hazard_person}</Typography>
      ),
    },
    {
      field: 'warning_person',
      headerName: 'People Warning',
      sortable: true,
      renderCell: ({row}) => (
        <Typography align="center">{row?.warning_person}</Typography>
      ),
    },
    {
      field: 'hazard_vehicle',
      headerName: 'Machine Hazard',
      sortable: true,
      renderCell: ({row}) => (
        <Typography align="center">{row?.hazard_vehicle}</Typography>
      ),
    },
    {
      field: 'warning_vehicle',
      headerName: 'Machine Warning',
      sortable: true,
      renderCell: ({row}) => (
        <Typography align="center">{row?.warning_vehicle}</Typography>
      ),
    },

    {
      field: 'latitude',
      headerName: 'Lat',
      sortable: true,
    },
    {
      field: 'longitude',
      headerName: 'Lon',
      sortable: true,
    },
  ];

  const shownFields = useMemo(() => {
    return config.shownFields.all;
  }, [config]);

  useEffect(() => {
    onChange?.(
      update(config, {
        exportFields: {
          $set: columns
            .filter((col) => !col.doNotExport)
            .map((col) => ({
              field: col.field,
              label: col.headerName,
              hidden: shownFields?.indexOf(col.field) === -1,
            })),
        },
      })
    );
  }, [shownFields]);

  const handleChangeShownFields = (fields: string[]) => {
    onChange?.(
      update(config, {
        shownFields: {
          all: {$set: fields},
        },
      })
    );
  };

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = config.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i: any) => config.selectedIds?.includes(i.id)),
    [rows, config.selectedIds]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (config.selectedIds?.includes(id)) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: config.selectedIds.filter((i: any) => i !== id),
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: [...(config.selectedIds ?? []), id],
          },
        })
      );
    }
  };

  const selectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: rows?.map((i: any) => i.id) ?? [],
        },
      })
    );
  };

  const unselectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: [],
        },
      })
    );
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      config.selectedIds &&
      config.selectedIds.length !== selectedRows.length
    ) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: selectedRows.map((i: any) => i.id),
          },
        })
      );
    }
  }, [config.selectedIds, selectedRows]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  // Map Data
  const mapData = useMemo(() => {
    if (fetchedData?.items && config.selectedIds?.length) {
      return fetchedData?.items.filter((item) =>
        config.selectedIds?.includes(item.id)
      );
    }
    return [];
  }, [fetchedData, config.selectedIds, selectedItems]);

  /********************/
  /* refresh interval */
  /********************/
  const refreshPeriod = useConfiguration(
    'hazard-ai',
    'hazard_ai_autorefresh_rate'
  );

  return (
    <Box
      height="100%"
      width="100%"
      position="relative"
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        p={2}
        width="100%"
        gap={2}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <AccessControl>
            <Box display="flex" gap={2}>
              <Tooltip title="Heatmap">
                <Box
                  onClick={() => {
                    onOpenHistory?.(selectedItems, 'hazard_ai_heatmap');
                  }}
                  sx={{cursor: 'pointer'}}
                >
                  <Image
                    src={HeatmapIcon}
                    alt="heat map"
                    width={40}
                    height={40}
                  />
                </Box>
              </Tooltip>

              <SitesSelect
                size="small"
                value={config.params.site_id}
                nullLabel="All sites"
                label="Sites"
                sx={{minWidth: 160}}
                onChange={(v) => {
                  onChange?.(
                    update(config, {
                      params: {
                        site_id: {
                          $set: v as number,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>
          </AccessControl>
        </Box>
        <Box minWidth={320} maxWidth={400}>
          <DateRangeSelect
            value={[
              dayjs(config.params.date_start).toDate(),
              dayjs(config.params.date_end).toDate(),
            ]}
            size="small"
            onChange={(v) => {
              onChange?.(
                update(config, {
                  params: {
                    date_start: {
                      $set: v?.[0]
                        ? dayjs(v?.[0]).format('YYYY-MM-DD')
                        : undefined,
                    },
                    date_end: {
                      $set: v?.[0]
                        ? dayjs(v?.[1]).format('YYYY-MM-DD')
                        : undefined,
                    },
                    page: {
                      $set: 0,
                    },
                  },
                })
              );
            }}
          />
        </Box>
        <Box display="flex">
          <SafeyeNodeStatusSelect
            value={config.params.status ?? 'all'}
            onChange={(value) => {
              onChange?.(
                update(config, {
                  params: {
                    status: {
                      $set: value,
                    },
                    page: {
                      $set: 0,
                    },
                  },
                })
              );
            }}
          />
        </Box>
        <Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            height={40}
            gap={2}
          >
            <Box display="flex" height="100%">
              <ButtonGroup>
                {/*  <SafeyeNodeItemUpsertButton
                  component={Button}
                  componentProps={{
                    color: 'primary',
                    startIcon: <AddIcon />,
                  }}
                  onSubmitted={() => onRefresh?.()}
                  locationCoordinates={locationCoordinates}
                >
                  <VideocamIcon />
                </SafeyeNodeItemUpsertButton> */}

                {/* // @todo Implement WarningHazardImportExportButton */}

                <Tooltip title="Refresh results">
                  <Button onClick={() => onRefresh?.()}>
                    <RefreshIcon />
                  </Button>
                </Tooltip>

                <Tooltip title="Change speed format">
                  <Button onClick={handleChangeSpeedFormat}>
                    <SpeedIcon sx={{marginRight: '6px'}} />
                    <p>{speedFormat}</p>
                  </Button>
                </Tooltip>

                {refreshPeriod ? (
                  <Tooltip title="Refresh period">
                    <AutoRefreshSettingsSelect
                      refreshPeriod={refreshPeriod}
                      isForUserConfiguration
                    />
                  </Tooltip>
                ) : null}

                <Tooltip title="Print data">
                  <Button onClick={() => dataGridRef.current?.printTable()}>
                    <PrintIcon />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>
      </Box>

      <ResizableColumns
        left={
          <>
            <HazardMap
              panel="warning_hazard_report"
              shownSafeyeNodes={mapData}
              selectedMapLayers={config.mapLayers}
              selectedLevel={config.mapLevel}
              hazardMapType={config.mapType}
              height="100%"
              minWidth={200}
              availableMapLayers={[]}
              onSelectMapLayers={(v) => {
                console.log('on map layout change', v);
                /* onChange?.(
                  update(config, {
                    mapLayers: {
                      $set: v,
                    },
                    params: {
                      node_type: {
                        $set: [
                          ...(v.includes('employees') ? ['miner'] : []),
                          ...(v.includes('assets') ? ['asset'] : []),
                        ] as WarningHazardsData['params']['node_type'],
                      },
                    },
                  })
                ); */
              }}
              onSelectLevel={(v) => {
                onChange?.(
                  update(config, {
                    mapLevel: {
                      $set: v,
                    },
                  })
                );
              }}
              onOpenHistory={onOpenHistory}
              onUpdateMapType={(v) => {
                onChange?.(
                  update(config, {
                    mapType: {
                      $set: v,
                    },
                  })
                );
              }}
              onGetClickCoordinates={onChangeLocationCoordinates}
            />
          </>
        }
      >
        <Box display="flex" flexDirection="column" height="100%">
          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            size="small"
            pagination
            page={config.grid.page}
            pageSize={config.grid.pageSize}
            loading={isLoading}
            shownFields={shownFields}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            footerStart={
              selectedItems.length ? (
                <Box display="flex" alignItems="center" gap={3} px={1}>
                  <Box
                    display="flex"
                    gap={0.5}
                    alignItems="center"
                    height="100%"
                    whiteSpace="nowrap"
                  >
                    {selectedItems.length} selected
                  </Box>
                </Box>
              ) : null
            }
            onShownFieldsChange={handleChangeShownFields}
            onPageChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    page: {
                      $set: v,
                    },
                  },
                })
              );
            }}
            onPageSizeChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    pageSize: {
                      $set: v,
                    },
                  },
                })
              );
            }}
          />
        </Box>
      </ResizableColumns>
    </Box>
  );
};

export default WarningHazardReport;
