import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import {LoadingButton} from '@mui/lab';
import {ButtonProps, Menu, MenuItem, Tooltip} from '@mui/material';
import {useMemo, useState} from 'react';

interface Props
  extends Omit<ButtonProps, 'value' | 'onChange' | 'options' | 'loading'> {
  value: number | null;
  onChange: (value: number | null) => void;
  options?: {value: null | number; label: string}[];
  loading?: boolean;
}

const defaultOptions = [
  {value: null, label: 'None'},
  {value: 10000, label: '10'},
  {value: 15000, label: '15'},
  {value: 30000, label: '30'},
  {value: 60000, label: '60'},
  {value: 120000, label: '120'},
];

export const AutoRefreshSelect = ({
  value,
  onChange,
  options = defaultOptions,
  loading,
  variant = 'outlined',
  ...props
}: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const selectedOption = useMemo(
    () => options.find((i) => i.value === value),
    [value]
  );

  return (
    <>
      <Tooltip title="Auto Reload">
        <LoadingButton
          {...props}
          loading={loading && loading}
          variant={variant}
          startIcon={<AccessAlarmIcon />}
          sx={{
            width: 90,
            ...props.sx,
          }}
          onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        >
          {selectedOption?.label ?? 'None'}
        </LoadingButton>
      </Tooltip>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(null)}
      >
        {options.map((i) => (
          <MenuItem
            key={i.value}
            selected={i.value === value}
            onClick={() => {
              onChange(i.value);
              setMenuAnchorEl(null);
            }}
          >
            {i.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
