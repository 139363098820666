import {DivIcon} from 'leaflet';
import _ from 'lodash';
import {Marker, Popup, Tooltip} from 'react-leaflet';

import {useConfigurations} from '../../../hooks/configuration';
import {Node} from '../../../interfaces/Node';
import {COLORED_LEAFLET_ICONS} from '../../../utils/icons';
import {CN_EVENT_COLORS, CN_EVENT_TYPES} from '../../../utils/nodes';
import NodeTooltipContent from './NodeTooltipContent';
const duplicateIcon = require('../../../images/other/duplicate_icon.png');

interface Props {
  topRightPosition?: any | null;
  i: {
    key: string;
    nodes: Node[];
  };
  disableEditMode?: boolean;
  onOpenItem?: (
    id: number,
    type: 'cn' | 'wifi' | 'employee' | 'asset' | 'alarm'
  ) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => void;
}

const NodeMarker = ({
  topRightPosition,
  i,
  disableEditMode,
  onOpenItem,
  onOpenHistory,
}: Props) => {
  const configurations = useConfigurations();
  const getCommunicationNodeIcon = (item: Node, isUnAck: boolean = false) => {
    let color: string | number | undefined;
    if (item.e_109) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_NEW_CN_DETECTED] ?? '']
          ?.value;
    } else if (item.status === 'inactive') {
      color = 'gray';
    } else if (item.e_101) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_ASSIGNED_MASTER] ?? '']
          ?.value;
    } else if (item.e_121) {
      color =
        configurations[
          CN_EVENT_COLORS[CN_EVENT_TYPES.CN_TOO_MANY_HEARTBEATS] ?? ''
        ]?.value;
    } else if (item.e_110) {
      color =
        configurations[
          CN_EVENT_COLORS[CN_EVENT_TYPES.CN_PIC_VERSION_HAS_CHANGED] ?? ''
        ]?.value;
    } else if (item.e_111) {
      color =
        configurations[
          CN_EVENT_COLORS[CN_EVENT_TYPES.CN_CC1110_VERSION_HAS_CHANGED] ?? ''
        ]?.value;
    } else if (item.e_102) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_NO_HEARTBEAT] ?? '']
          ?.value;
    } else if (item.e_103) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_LOW_BATTERY] ?? '']
          ?.value;
    } else if (item.e_104) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_FEW_HEARTBEATS] ?? '']
          ?.value;
    }

    if (isUnAck === true) {
      color = 'gray';
    }
    return COLORED_LEAFLET_ICONS.communicationNodes[color ?? 'default'];
  };

  const getWifiIcon = (item: Node, isUnAck: boolean = false) => {
    let color: string | number | undefined;
    if (item.wifi_connection_status === 'inactive') {
      color = 'gray';
    } else if (item.wifi_connection_status === 'disconnected') {
      color = 'red';
    }
    if (isUnAck === true) {
      color = 'gray';
    }
    return COLORED_LEAFLET_ICONS.wifi[color ?? 'blue'];
  };

  const getNodeIcon = (
    item: Node,
    isUnAck: boolean = false,
    isDuplicatedMode: boolean = false
  ) => {
    if (isDuplicatedMode === true) {
      return COLORED_LEAFLET_ICONS.communicationNodes.duplicate;
    }
    if (item.node_type === 'communication_node') {
      return getCommunicationNodeIcon(item, isUnAck);
    } else if (item.node_type === 'wifi_point') {
      return getWifiIcon(item, isUnAck);
    }
    return COLORED_LEAFLET_ICONS.communicationNodes.default;
  };
  return (
    <Marker
      position={
        i.key === 'new_devices'
          ? topRightPosition
            ? topRightPosition
            : [0, 0]
          : [+i.nodes[0].latitude, +i.nodes[0].longitude]
      }
      icon={
        i.nodes.length === 1
          ? getNodeIcon(i.nodes[0], i.key === 'new_devices')
          : new DivIcon({
              className: 'duplicate icon',
              html: `<img src="${duplicateIcon}" /><span>${i.nodes.length}</span>`,
              iconAnchor: [16, 37],
            })
      }
      interactive
      eventHandlers={{
        click: (event) => event.target.openPopup(),
        popupopen: (event) => {
          event.target._tooltip.options.opacity = 0;
        },
        popupclose: (event) => {
          event.target._tooltip.options.opacity = 0.9;
        },
      }}
    >
      <Popup interactive autoClose={false} offset={[0, -32]}>
        <NodeTooltipContent
          item={i.nodes[0]}
          items={i.nodes}
          disableEditMode={disableEditMode}
          onOpenItem={(item) => {
            if (!_.isEmpty(item)) {
              if (item.node_type === 'communication_node' && item.cn_id) {
                onOpenItem?.(item.cn_id, 'cn');
              } else if (item.node_type === 'wifi_point' && item.wifi_id) {
                onOpenItem?.(item.wifi_id, 'wifi');
              }
            } else if (_.isEmpty(item)) {
              if (
                i.nodes[0].node_type === 'communication_node' &&
                i.nodes[0].cn_id
              ) {
                onOpenItem?.(i.nodes[0].cn_id, 'cn');
              } else if (
                i.nodes[0].node_type === 'wifi_point' &&
                i.nodes[0].wifi_id
              ) {
                onOpenItem?.(i.nodes[0].wifi_id, 'wifi');
              }
            }
          }}
          onOpenHistory={(item) => {
            if (!_.isEmpty(item)) {
              if (
                item.node_type === 'communication_node' &&
                item.cn_external_id
              ) {
                onOpenHistory?.(item.cn_external_id, 'commtracNodeByCn');
              }
            } else if (_.isEmpty(item)) {
              if (
                i.nodes[0].node_type === 'communication_node' &&
                i.nodes[0].cn_external_id
              ) {
                onOpenHistory?.(i.nodes[0].cn_external_id, 'commtracNodeByCn');
              }
            }
          }}
        />
      </Popup>

      <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
        <NodeTooltipContent
          item={i.nodes[0]}
          items={i.nodes}
          disableEditMode={disableEditMode}
          onOpenItem={(item) => {
            if (!_.isEmpty(item)) {
              if (item.node_type === 'communication_node' && item.cn_id) {
                onOpenItem?.(item.cn_id, 'cn');
              } else if (item.node_type === 'wifi_point' && item.wifi_id) {
                onOpenItem?.(item.wifi_id, 'wifi');
              }
            } else if (_.isEmpty(item)) {
              if (
                i.nodes[0].node_type === 'communication_node' &&
                i.nodes[0].cn_id
              ) {
                onOpenItem?.(i.nodes[0].cn_id, 'cn');
              } else if (
                i.nodes[0].node_type === 'wifi_point' &&
                i.nodes[0].wifi_id
              ) {
                onOpenItem?.(i.nodes[0].wifi_id, 'wifi');
              }
            }
          }}
          onOpenHistory={(item) => {
            if (!_.isEmpty(item)) {
              if (
                item.node_type === 'communication_node' &&
                item.cn_external_id
              ) {
                onOpenHistory?.(item.cn_external_id, 'commtracNodeByCn');
              }
            } else if (_.isEmpty(item)) {
              if (
                i.nodes[0].node_type === 'communication_node' &&
                i.nodes[0].cn_external_id
              ) {
                onOpenHistory?.(i.nodes[0].cn_external_id, 'commtracNodeByCn');
              }
            }
          }}
        />
      </Tooltip>
    </Marker>
  );
};

export default NodeMarker;
