export const STATUSES = [
  {
    key: 'active',
    name: 'Active',
  },
  {
    key: 'inactive',
    name: 'Inactive',
  },
] as const;

export type Status = (typeof STATUSES)[number]['key'];
