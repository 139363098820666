import {PlayCircle} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {ExportField} from '../../../interfaces/Export';
import {
  HazardEventLog,
  HazardEventLogListQuery,
  HazardEventLogListResponse,
} from '../../../interfaces/HazardAILog';
import {SafeyeNode} from '../../../interfaces/SafeyeNode';
import reduxSelectors from '../../../redux/selectors';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {HazardMap, MapLayerId, MapType} from '../../common/HazardMap';
import {ResizableColumns} from '../../common/ResizableColumns';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {SafeyeNodeSelector} from '../../selectors/SafeyeNodeSelector';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import HazardAILogExportButton from './HazardAILogExportButton';

interface Props {
  value?: HazardDetectionAIReportData;
  hasConnectView?: boolean;
  onUpdate?: (value?: HazardDetectionAIReportData) => void;
  onClickMedia?: (id?: number) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'id',
  'timestamp',
  'zone',
  'object',
  'confidence',
  'raw_image',
  'distance',
  'cam_id',
  'speed',
  'latitude',
  'longitude',
  'machine_name',
];

export interface HazardDectionAILogReportDataTab {
  id: number;
  selectedMachineId: number | null;
  params: Partial<HazardEventLogListQuery>;
  selectedIds: number[] | null;
  selectAll: boolean;
  shownFields: string[];
  exportFields: ExportField[];
  refreshInterval?: number | null;
}

export interface HazardDetectionAIReportData {
  activeId?: number;
  openedItems: HazardDectionAILogReportDataTab[];
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  mapType?: MapType;
}

export const getHazardDectionAIReportData = (): HazardDetectionAIReportData => {
  return {
    activeId: undefined,
    openedItems: [],
    mapLayers: ['hazardAiLogs'],
    mapLevel: 2,
  };
};

export const getHazardDectionAIReportDataTab = (
  id: number
): HazardDectionAILogReportDataTab => {
  return {
    id,
    selectAll: true,
    selectedMachineId: null,
    params: {
      date_start: dayjs().format('YYYY-MM-DD'),
      date_end: dayjs().format('YYYY-MM-DD'),
      limit: 25,
      page: 0,
    },
    shownFields: DEFAULT_SHOWN_FIELDS,
    selectedIds: null,
    exportFields: [],
  };
};

const HazardAIDetectionLogReport = ({
  value,
  hasConnectView,
  onUpdate,
  onClickMedia,
}: Props) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);

  const config = useMemo(() => {
    if (hasConnectView) {
      const v = value ?? getHazardDectionAIReportData();
      return {
        ...v,
        mapLayers: v.mapLayers ?? ['hazardAiLogs'],
        mapLevel: v.mapLevel ?? null,
      };
    } else {
      const v = value ?? getHazardDectionAIReportData();
      if (!value?.activeId) {
        return {
          ...v,
          openedItems: [],
          mapLayers: v.mapLayers ?? ['heatmap', 'street'],
          mapLevel: v.mapLevel ?? null,
        };
      } else {
        return {
          ...v,
          mapLayers: v.mapLayers ?? ['heatmap', 'street'],
          mapLevel: v.mapLevel ?? null,
        };
      }
    }
  }, [value, hasConnectView]);

  const openedItemIndex = useMemo(
    () =>
      (config.activeId
        ? config.openedItems.findIndex((i) => i.id === config.activeId)
        : null) ?? config.openedItems.length - 1,
    [config.activeId, config.openedItems]
  );

  const openedItem = useMemo(
    () =>
      openedItemIndex !== -1
        ? config.openedItems[openedItemIndex] &&
          config.openedItems[openedItemIndex].params
          ? config.openedItems[openedItemIndex]
          : getHazardDectionAIReportDataTab(config.activeId as number)
        : null,
    [openedItemIndex, config.openedItems]
  );

  const tabNames = useMemo(
    () =>
      config.openedItems.map((o) => {
        const machine: SafeyeNode | null =
          assets.safeye_nodes?.find((it) => `${it.id}` === `${o.id}`) ?? null;
        return machine?.asset_name
          ? machine?.asset_name
          : `Machine Not Found (Summary ID: #${o?.id})`;
      }),
    [config.openedItems, assets.safeye_nodes]
  );

  const [fetchedDetectionLog, setFetchedDetectionLog] =
    useState<HazardEventLogListResponse>();
  const [fetchedDetectionLogErrors, setFetchedDetectionLogErrors] = useState<
    string[]
  >([]);
  const [fetchedDetectionLogInProgress, setFetchedDetectionLogInProgress] =
    useState(false);

  const params = useMemo<HazardEventLogListQuery | null>(
    () =>
      openedItem?.id
        ? {
            id: openedItem?.id,
            date_start:
              openedItem.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
            date_end:
              openedItem.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
            page: openedItem.params?.page ?? 0,
            limit: openedItem.params?.limit ?? 25,
            order: openedItem.params?.order ?? 'id',
            dir: openedItem.params?.dir ?? 'DESC',
          }
        : null,
    [openedItem?.id, openedItem?.params]
  );
  const fetchDetectionLogs = useCallback(
    async (params: HazardEventLogListQuery) => {
      if (params?.id) {
        setFetchedDetectionLogInProgress(true);
        setFetchedDetectionLogErrors([]);

        try {
          const resp = await API.get<HazardEventLogListResponse>(
            `${apiBaseUrl}/hazard-ai/${params?.id}/detection-event-log`,
            {params}
          );

          setFetchedDetectionLog(resp.data);
          if (openedItem && openedItem.selectAll) {
            openedItem.selectedIds = resp.data.items.map((el) => el.id);
          }
        } catch (error: any) {
          const messages = getMessagesFromApiError(error);
          setFetchedDetectionLogErrors(messages);
        } finally {
          setFetchedDetectionLogInProgress(false);
        }
      }
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchDetectionLogs(params);
    }
  }, [params]);

  useEffect(() => {
    if (!openedItem) {
      setFetchedDetectionLog(undefined);
    }
  }, [openedItem]);

  /****************/
  /* auto refresh */
  /****************/
  const callFetchData = useCallback(() => {
    params && fetchDetectionLogs(params);
  }, [params]);

  useRefreshInterval(callFetchData, openedItem?.refreshInterval);

  /*************/
  /* data grid */
  /*************/
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedDetectionLog?.items ?? [];
  const columns: DataGridColumn<HazardEventLog>[] = [
    {
      field: 'select',
      type: 'select',
      doNotExport: true,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(row.id)}
          onChange={() => toggleSelectItem(row.id)}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      sortable: true,
    },
    {
      field: 'zone',
      headerName: 'Zone',
      sortable: true,
      renderCell: ({row}) => {
        return <Box textTransform="capitalize">{row.zone}</Box>;
      },
    },
    {
      field: 'object',
      headerName: 'Object',
      sortable: true,
      renderCell: ({row}) => {
        return <Box textTransform="capitalize">{row.object}</Box>;
      },
    },
    {
      field: 'confidence',
      headerName: 'Detection Confidence',
      sortable: true,
      renderCell: ({row}) => {
        return <Box textTransform="capitalize">{row.confidence}</Box>;
      },
    },
    {
      field: 'raw_image',
      headerName: 'Detection Video',
      sortable: true,
      doNotExport: true,
      renderCell: ({row}) => {
        return (
          <Box width="100%" display="flex" justifyContent="center">
            <IconButton
              onClick={() => {
                onClickMedia?.(row.id);
              }}
            >
              <PlayCircle />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: 'distance',
      headerName: 'Detection distance',
      sortable: true,
      renderCell: ({row}) => {
        return row.distance;
      },
    },
    {
      field: 'cam_id',
      headerName: 'Camera Position',
      sortable: true,
      renderCell: ({row}) => {
        return row.cam_id;
      },
    },
    {
      field: 'speed',
      headerName: 'Speed',
      sortable: true,
      renderCell: ({row}) => {
        return row.speed;
      },
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
      sortable: true,
      renderCell: ({row}) => {
        return row.latitude;
      },
    },
    {
      field: 'longitude',
      headerName: 'Longitude',
      sortable: true,
      renderCell: ({row}) => {
        return row.longitude;
      },
    },
  ];

  const shownFields = useMemo(() => {
    return openedItem?.shownFields ?? DEFAULT_SHOWN_FIELDS;
  }, [openedItem]);

  useEffect(() => {
    if (openedItemIndex > -1 && openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              $set: {
                ...openedItem,
                exportFields: columns
                  .filter((col) => !col.doNotExport)
                  .map((col) => ({
                    field: col.field,
                    label: col.headerName,
                    hidden: shownFields?.indexOf(col.field) === -1,
                  })),
              },
            },
          },
        })
      );
    }
  }, [shownFields, openedItemIndex]);
  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = useMemo(() => {
    return openedItem?.selectedIds ?? [];
  }, [openedItem]);

  const selectedRows = useMemo(
    () => rows.filter((i) => openedItem?.selectedIds?.includes(i.id)),
    [rows, selectedItems]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (openedItem) {
      if (selectedItems?.includes(id)) {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: selectedItems.filter((i) => i !== id),
                  selectAll: false,
                },
              },
            },
          })
        );
      } else {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: [...(selectedItems ?? []), id],
                  selectAll:
                    selectedItems.length + 1 ===
                    fetchedDetectionLog?.items.length,
                },
              },
            },
          })
        );
      }
    }
  };

  const selectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: true,
              },
              selectedIds: {
                $set: rows?.map((i) => i.id) ?? [],
              },
            },
          },
        })
      );
    }
  };

  const unselectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: false,
              },
              selectedIds: {
                $set: [],
              },
            },
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (rows?.length > 0 && openedItem?.selectAll) {
      selectAll();
    }
  }, [rows]);

  const handleChangeShownFields = (fields: string[]) => {
    onUpdate?.(
      update(config, {
        openedItems: {
          [openedItemIndex]: {
            shownFields: {$set: fields},
          },
        },
      })
    );
  };

  const handleSafeyeNodeChange = (v: any) => {
    if (openedItemIndex > -1 && openedItem) {
      onUpdate?.(
        update(config, {
          activeId: {
            $set: v,
          },
          openedItems: {
            [openedItemIndex]: {
              id: {$set: v},
            },
          },
        })
      );
    } else {
      const openedItem: HazardDectionAILogReportDataTab =
        getHazardDectionAIReportDataTab(v);

      onUpdate?.(
        update(config, {
          activeId: {
            $set: v,
          },
          openedItems: {
            $set: [openedItem],
          },
        })
      );
    }
  }

  /************/
  /* map data */
  /************/
  const mapData = useMemo(() => {
    if (fetchedDetectionLog?.items && openedItem?.selectedIds?.length) {
      return fetchedDetectionLog?.items.filter((i) =>
        openedItem?.selectedIds?.includes(i.id)
      );
    }
    return [];
  }, [fetchedDetectionLog, openedItem?.selectedIds, selectedItems]);

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column" overflow="auto">
      <DashboardPanelTitleSlot>HazardAI Detection Log</DashboardPanelTitleSlot>
      {hasConnectView && (
        <>
          {config.openedItems.length ? (
            <Box px={2} mb={2}>
              <Tabs
                value={openedItem?.id}
                variant="scrollable"
                onChange={(_event, v) => {
                  if (v) {
                    onUpdate?.(
                      update(config, {
                        activeId: {
                          $set: v,
                        },
                      })
                    );
                  }
                }}
              >
                {config.openedItems.map((i, idx) => (
                  <Tab
                    key={i.id}
                    value={i.id}
                    label={
                      <Box display="flex" alignItems="center" gap={1}>
                        <Box>{tabNames?.[idx] ?? 'Undefined'}</Box>
                        <CloseIcon
                          fontSize="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            onUpdate?.(
                              update(value, {
                                activeId: {
                                  $set:
                                    config.activeId === i.id
                                      ? undefined
                                      : config.activeId,
                                },
                                openedItems: {
                                  $set:
                                    config.openedItems.filter(
                                      (o) => o.id && o.id !== i.id
                                    ) ?? [],
                                },
                              })
                            );
                          }}
                        />
                      </Box>
                    }
                  />
                ))}
              </Tabs>
            </Box>
          ) : (
            <Box minWidth={400} px={1.5}>
              <Alert severity="warning">No Machine Selected</Alert>
            </Box>
          )}
        </>
      )}
      {fetchedDetectionLogErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchDetectionLogs(params)}
        >
          {error}
        </Alert>
      ))}
      <Box
        overflow="auto"
        height={70}
        minHeight="70px"
        sx={{
          overflowY: 'hidden',
        }}
      >
        <Box
          p={2}
          minWidth={550}
          height={70}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          {!hasConnectView && (
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <SafeyeNodeSelector
                size="small"
                allOptionDisabled
                value={config?.activeId}
                onChange={handleSafeyeNodeChange}
              />
            </Box>
          )}
          <Box display="flex" height="100%" marginLeft="auto">
            <Box minWidth={400}>
              <DateRangeSelect
                value={[
                  dayjs(openedItem?.params.date_start).toDate(),
                  dayjs(openedItem?.params.date_end).toDate(),
                ]}
                size="small"
                onChange={(v) => {
                  openedItem &&
                    onUpdate?.(
                      update(config, {
                        openedItems: {
                          [openedItemIndex]: {
                            params: {
                              $set: {
                                ...openedItem?.params,
                                date_start: v?.[0]
                                  ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                  : undefined,
                                date_end: v?.[1]
                                  ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                  : undefined,
                              },
                            },
                          },
                        },
                      })
                    );
                }}
              />
            </Box>
          </Box>
          <Box display="flex" height="100%">
            <ButtonGroup>
              <HazardAILogExportButton
                value={config.openedItems[openedItemIndex]}
                component={Button}
                componentProps={{color: 'primary'}}
                onSubmitted={() => {
                  if (params) {
                    fetchDetectionLogs?.(params);
                  }
                }}
              >
                <ImportExportIcon />
              </HazardAILogExportButton>
              <Button
                onClick={() => {
                  if (params) {
                    fetchDetectionLogs?.(params);
                  }
                }}
              >
                <RefreshIcon />
              </Button>
              <AutoRefreshSelect
                value={openedItem?.refreshInterval ?? null}
                onChange={(v) => {
                  if (openedItem) {
                    onUpdate?.(
                      update(config, {
                        openedItems: {
                          [openedItemIndex]: {
                            $set: {
                              ...openedItem,
                              refreshInterval: v,
                            },
                          },
                        },
                      })
                    );
                  }
                }}
              />

              <Button onClick={() => dataGridRef.current?.printTable()}>
                <PrintIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        {/* </Box> */}
      </Box>
      <ResizableColumns
        position="relative"
        left={
          <>
            <HazardMap
              panel="detection_log_report"
              hazardDetectionLogs={mapData}
              selectedMapLayers={['hazardAiLogs']}
              selectedLevel={config.mapLevel}
              hazardMapType={config.mapType}
              minWidth={200}
              availableMapLayers={['hazardAiLogs']}
              onSelectLevel={(v) => {
                onUpdate?.(
                  update(config, {
                    mapLevel: {
                      $set: v,
                    },
                  })
                );
              }}
              onSelectMapLayers={(v) => {
                onUpdate?.(
                  update(config, {
                    mapLayers: {
                      $set: v,
                    },
                  })
                );
              }}
              onUpdateMapType={(v) => {
                onUpdate?.(
                  update(config, {
                    mapType: {
                      $set: v,
                    },
                  })
                );
              }}
              onClickMedia={(id) => {
                onClickMedia?.(id);
              }}
            />
          </>
        }
      >
        <Box display="flex" flexDirection="column" height="100%">
          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            size="small"
            pagination
            paginationMode="server"
            page={openedItem?.params?.page ?? 0}
            pageSize={openedItem?.params?.limit ?? 0}
            rowCount={fetchedDetectionLog?.count}
            shownFields={shownFields}
            loading={fetchedDetectionLogInProgress}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            footerStart={
              selectedItems.length ? (
                <Box display="flex" alignItems="center" gap={3} px={1}>
                  <Box
                    display="flex"
                    gap={0.5}
                    alignItems="center"
                    height="100%"
                    whiteSpace="nowrap"
                  >
                    {selectedItems.length} selected
                  </Box>
                </Box>
              ) : null
            }
            onShownFieldsChange={handleChangeShownFields}
            onPageChange={(v) => {
              openedItem &&
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        params: {
                          $set: {
                            ...openedItem?.params,
                            page: v ?? 0,
                          },
                        },
                      },
                    },
                  })
                );
            }}
            onPageSizeChange={(v) => {
              openedItem &&
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        params: {
                          $set: {
                            ...openedItem?.params,
                            page: 0,
                            limit: v,
                          },
                        },
                      },
                    },
                  })
                );
            }}
          />
        </Box>
        <Backdrop
          open={fetchedDetectionLogInProgress}
          sx={{position: 'absolute', zIndex: 1199}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </ResizableColumns>
    </Box>
  );
};

export default HazardAIDetectionLogReport;
