import {Autocomplete, TextField, TextFieldProps} from '@mui/material';
import isNil from 'lodash/isNil';
import {useSnackbar} from 'notistack';
import React, {useMemo} from 'react';

import {CommtracNodeListQuery} from '../../../interfaces/CommtracNode';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'sx'
> & {
  value?:
    | CommtracNodeListQuery['miner_status']
    | CommtracNodeListQuery['asset_status'];
  cnt: number;
  type: ('miner' | 'asset')[] | undefined;
  onChange?: (
    value?:
      | CommtracNodeListQuery['miner_status']
      | CommtracNodeListQuery['asset_status']
  ) => void;
  onUnassignedEmployeesOpen?: () => void;
  onUnassignedAssetsOpen?: () => void;
};

export const StatusSelect = ({
  value,
  disabled,
  fullWidth,
  size,
  cnt,
  type,
  onChange,
  onUnassignedEmployeesOpen,
  onUnassignedAssetsOpen,
  ...props
}: Props) => {
  const nullValue = 'all';
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const statusOptions: {
    value:
      | CommtracNodeListQuery['miner_status']
      | CommtracNodeListQuery['asset_status']
      | 'unassigned';
    label: string;
  }[] = useMemo(
    () => [
      {value: 'checked_in', label: 'Checked In'},
      {value: 'checked_out', label: 'Checked Out'},
      {value: 'active', label: 'Active'},
      {value: 'inactive', label: 'Inactive'},
      {value: 'unacknowledged', label: 'Unacknowledged'},
      {value: 'surface', label: 'Surface'},
      {value: 'underground', label: 'Underground'},
      {value: 'all', label: 'All'},
      {value: 'unassigned', label: 'Unassigned'},
    ],
    []
  );

  const selectedOptionId = isNil(value) ? nullValue : value;
  const selectedOption =
    statusOptions.find((i) => i.value === selectedOptionId) ?? null;

  const openUnAssignedModal = () => {
    if (type?.length !== 1) {
      const message = (
        <div>
          <div>
            To properly, check the unassigned items, please select the type.
          </div>
        </div>
      );
      enqueueSnackbar(message, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    } else if (type.includes('asset')) {
      onUnassignedAssetsOpen?.();
    } else if (type.includes('miner')) {
      onUnassignedEmployeesOpen?.();
    }
  };

  return disabled ? (
    <TextField
      {...props}
      value={`${
        statusOptions.find((i) => i.value === value)?.label ?? ''
      } (${cnt})`}
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
    />
  ) : (
    <Autocomplete
      value={selectedOption ?? null}
      disabled={disabled}
      fullWidth={fullWidth}
      options={statusOptions}
      size={size}
      disableClearable={selectedOptionId === nullValue}
      isOptionEqualToValue={(o, v) => o.value === v.value}
      getOptionLabel={(o) => o.label ?? ''}
      inputValue={`${selectedOption?.label} (${cnt})`}
      filterOptions={(options) => options}
      renderInput={(params) => (
        <TextField label="Status" {...props} {...params} />
      )}
      renderOption={(props, option) => {
        return (
          <React.Fragment key={option.value}>
            {option.value === 'unassigned' && (
              <hr style={{borderColor: '#333'}} />
            )}
            <li {...props}>{option.label}</li>
          </React.Fragment>
        );
      }}
      onChange={(_, option) => {
        const v =
          !option || isNil(option.value) || option.value === nullValue
            ? 'all'
            : option.value;
        v !== 'unassigned' ? onChange?.(v) : openUnAssignedModal();
      }}
    />
  );
};
