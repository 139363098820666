import {InputProps, ListSubheader, MenuItem, TextField} from '@mui/material';
import _ from 'lodash';
import {useMemo, useState} from 'react';

import {useAppSelector} from '../../hooks/redux';
import {eventBaseTypesSelect} from '../../redux/assets/selectors';
import {tweakLabelForMiner} from '../../utils/macAddress';
import {PanelProductCode} from '../../utils/panels';

interface FilterOptionGroup {
  groupLabel: string;
  ids: Array<{type: number}>;
  restrictedByProducts?: PanelProductCode[];
}

const eventsFiltersConfig: FilterOptionGroup[] = [
  {
    groupLabel: 'Communication Nodes Events',
    ids: [
      {type: 101},
      {type: 102},
      {type: 103},
      {type: 104},
      {type: 109},
      {type: 110},
      {type: 111},
      {type: 112},
      {type: 121},
      {type: 122},
      {type: 132},
    ],
  },
  {
    groupLabel: 'Employees Events',
    ids: [
      {type: 201},
      {type: 202},
      {type: 203},
      {type: 204},
      {type: 205},
      {type: 206},
      {type: 207},
      {type: 208},
      {type: 209},
      {type: 221},
      {type: 222},
      {type: 226},
      {type: 232},
      {type: 233},
    ],
  },
  {
    groupLabel: 'Assets Events',
    ids: [
      {type: 301},
      {type: 302},
      {type: 309},
      {type: 321},
      {type: 322},
      {type: 326},
      {type: 332},
    ],
  },
  {
    groupLabel: 'HazardAI Events',
    ids: [
      {type: 804},
      {type: 803},
      {type: 801},
      {type: 806},
      {type: 805},
      {type: 802},
    ],
    restrictedByProducts: ['hazard'],
  },
  {
    groupLabel: 'Alarm Events',
    ids: [{type: 701}, {type: 702}, {type: 703}, {type: 704}, {type: 705}, {type: 706}],
  },
];

type Props = {
  value?: string[] | undefined;
  onChange: (v: string[]) => void;
  restrictedByProducts?: PanelProductCode[];
};

export const EventTypeSelect = ({
  restrictedByProducts = [],
  ...props
}: Props) => {
  const eventBaseTypes = useAppSelector(eventBaseTypesSelect);
  const [selected, setSelected] = useState<string[]>(props.value ?? []);

  const eventsFilterOptions = useMemo(() => {
    return eventsFiltersConfig.reduce(
      (acc: any[], {ids, groupLabel, restrictedByProducts}) => {
        acc.push({
          label: groupLabel,
          options: _.intersectionBy(eventBaseTypes, ids, 'type'),
          restrictedByProducts,
        });
        return acc;
      },
      []
    );
  }, [eventBaseTypes, eventsFiltersConfig]);

  const eventsFilterOptionsRestrictedByProduct = useMemo(() => {
    return eventsFilterOptions.filter((filterOption) =>
      restrictedByProducts?.length
        ? filterOption.restrictedByProducts?.some((product: PanelProductCode) =>
            restrictedByProducts.includes(product)
          )
        : !filterOption.restrictedByProducts?.length
    );
  }, [eventsFilterOptions, restrictedByProducts]);

  const handleChange: InputProps['onChange'] = (event: any) => {
    const isSelectAll = _.last(event.target.value) === 'all';
    event.target.value = isSelectAll
      ? []
      : event.target.value.filter((i: any) => i !== 'all');
    setSelected(() => event.target.value);
  };

  const handleClose = () => {
    props.onChange(selected);
  };

  return (
    <TextField
      fullWidth
      label="Event Types"
      {...props}
      select
      size="small"
      SelectProps={{
        multiple: true,
        onClose: handleClose,
      }}
      value={!selected?.length ? ['all'] : selected}
      onChange={handleChange}
    >
      {[
        <MenuItem key="all" value="all">
          All Events
        </MenuItem>,
        ...eventsFilterOptionsRestrictedByProduct.map(({label, options}) => [
          <ListSubheader key={label}>{label}</ListSubheader>,
          options.map((option: {type: number; name: string}) => (
            <MenuItem
              key={`${option.type}-${option.name}`}
              value={`${option.type}`}
            >
              {tweakLabelForMiner(option.name)}
            </MenuItem>
          )),
        ]),
      ]}
    </TextField>
  );
};
