import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CampaignIcon from '@mui/icons-material/Campaign';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import {
  Backdrop,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import {capitalize} from 'lodash';
import {useEffect, useMemo, useState} from 'react';
import {useComponentSize} from 'react-use-size';

import {SafeNodeListResponseDetectionData} from '../../../interfaces/SafeyeNode';
import TruncatedText from '../../common/TruncantedText';

interface Propx {
  activeTab?: HazardTab;
  objectType?: string;
  isEventSummaryLoading?: boolean;
  isEventsLoading?: boolean;
  detectionData?: SafeNodeListResponseDetectionData;
  onChangeActiveTab?: (value: HazardTab) => void;
  onChangeObjectType?: (value: string) => void;
  totalEventsNumber?: number;
  unacknowledgedEventsNumber?: number;
}

export type HazardTab = 'warnings-hazards' | 'events';

const boxStatSx: SxProps<Theme> = {
  bgcolor: (theme) =>
    theme.palette.mode === 'dark' ? 'background.default' : 'primary.main',
  color: (theme) => (theme.palette.mode === 'dark' ? 'primary.main' : '#FFF'),
  borderColor: (theme) =>
    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.200',
  height: '100%',
  px: 1,
  py: 2,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

const boxStatActiveSx: SxProps<Theme> = {
  ...boxStatSx,
  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'),
  color: (theme) =>
    theme.palette.mode === 'dark' ? 'primary.main' : 'primary.main',
};

export const HazardAITabs = ({
  activeTab,
  objectType,
  isEventSummaryLoading = false,
  isEventsLoading = false,
  onChangeActiveTab,
  onChangeObjectType,
  detectionData,
  totalEventsNumber,
  unacknowledgedEventsNumber,
}: Propx) => {
  // DETECTED OBJECTS RELATED DATA

  // Constants
  const detectedObjectsTypes = detectionData?.object?.key;
  const detectedObjectsMenuItems =
    detectedObjectsTypes?.map((object: string) => ({
      value: object,
      label: capitalize(object),
    })) || [];
  const detectedObjectsMenuWithTotal = [
    {value: 'total', label: 'Total'},
    ...detectedObjectsMenuItems,
  ];

  // State
  const [
    warningHazardObjectTypeMenuAnchorEl,
    setWarningHazardObjectTypeMenuAnchorEl,
  ] = useState<null | HTMLElement>(null);
  const isObjectTypeMenuOpened = Boolean(warningHazardObjectTypeMenuAnchorEl);

  const [selectedDetectedObjectType, setSelectedDetectedObjectType] = useState(
    objectType ?? 'total'
  );

  // Helpers
  const getWarningsAndHazardsForObjectType = ({
    selectedDetectedObjectType,
    detectionData,
  }: {
    selectedDetectedObjectType: string;
    detectionData?: SafeNodeListResponseDetectionData;
  }) => {
    if (!detectionData) {
      return {warnings: 0, hazards: 0};
    }

    const isTotalData = selectedDetectedObjectType === 'total';

    if (isTotalData) {
      const {hazard, warning} = detectionData.total.data;

      return {hazard, warning};
    }

    if (!isTotalData) {
      const {hazard, warning} =
        detectionData.object.data[selectedDetectedObjectType].data;

      return {hazard, warning};
    }

    return {warning: 0, hazard: 0};
  };

  const selectedDetectedObjectMenuItem = useMemo(
    () =>
      detectedObjectsMenuWithTotal.find(
        (item) => item.value === selectedDetectedObjectType
      ),
    [detectedObjectsMenuItems, detectedObjectsMenuWithTotal]
  );

  // Constants
  const {warning, hazard} = useMemo(
    () =>
      getWarningsAndHazardsForObjectType({
        selectedDetectedObjectType,
        detectionData,
      }),
    [
      selectedDetectedObjectType,
      detectionData?.total.data.warning,
      detectionData?.total.data.hazard,
    ]
  );

  useEffect(() => {
    onChangeObjectType?.(selectedDetectedObjectType);
  }, [selectedDetectedObjectType]);

  // EVENTS RELATED DATA

  // Constants
  const {ref, width} = useComponentSize();
  const iconFontSize = 46;
  const numberFontSize = useMemo(() => {
    if (width < 1700) {
      return 40;
    }
    return 60;
  }, [width]);

  return (
    <Box ref={ref} sx={{overflowX: 'auto'}}>
      <Grid container minWidth={1200}>
        <Grid item xs={6} position="relative">
          <Backdrop
            open={!!isEventSummaryLoading}
            sx={{position: 'absolute'}}
          />
          <Box
            sx={{
              ...(activeTab === 'warnings-hazards'
                ? boxStatActiveSx
                : boxStatSx),
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
              cursor: 'pointer',
            }}
            onClick={() => onChangeActiveTab?.('warnings-hazards')}
          >
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  height="100%"
                >
                  <ShareLocationIcon sx={{fontSize: 40}} />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-around"
                    height="100%"
                    width="100%"
                    paddingTop="8px"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      minWidth="100px"
                    >
                      <Typography fontSize={numberFontSize}>
                        {warning ?? '-'}
                      </Typography>
                      <Typography textTransform="uppercase">
                        warnings
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      minWidth="100px"
                    >
                      <Typography fontSize={numberFontSize}>
                        {hazard ?? '-'}
                      </Typography>
                      <Typography textTransform="uppercase">Hazards</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* Object Options */}
              <Grid item xs={12} textAlign="center">
                <Button
                  sx={{mt: 'auto', maxWidth: 1}}
                  variant="text"
                  color="inherit"
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(event) =>
                    setWarningHazardObjectTypeMenuAnchorEl(event.currentTarget)
                  }
                >
                  <TruncatedText>
                    {selectedDetectedObjectMenuItem?.label}
                  </TruncatedText>
                </Button>
              </Grid>
            </Grid>

            <Menu
              anchorEl={warningHazardObjectTypeMenuAnchorEl}
              open={isObjectTypeMenuOpened}
              sx={{zIndex: 10000, minWidth: '240px'}}
              onBackdropClick={() =>
                setWarningHazardObjectTypeMenuAnchorEl(null)
              }
            >
              {detectedObjectsMenuWithTotal.map((menuItem) => (
                <MenuItem
                  key={menuItem.value}
                  selected={menuItem.value === selectedDetectedObjectType}
                  onClick={() => {
                    setSelectedDetectedObjectType?.(menuItem.value);
                    setWarningHazardObjectTypeMenuAnchorEl(null);
                  }}
                >
                  {menuItem.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Grid>

        {/* Events tab */}
        <Grid item xs={6} position="relative">
          <Backdrop open={!!isEventsLoading} sx={{position: 'absolute'}} />
          <Box
            sx={{
              ...(activeTab === 'events' ? boxStatActiveSx : boxStatSx),
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
              cursor: 'pointer',
              ...(totalEventsNumber
                ? {color: (theme) => theme.palette.error.main}
                : {}),
            }}
            onClick={() => onChangeActiveTab?.('events')}
            justifyContent="center"
          >
            <CampaignIcon sx={{fontSize: iconFontSize}} />
            <TruncatedText sx={{fontSize: 40}}>
              {unacknowledgedEventsNumber || '- '}/{totalEventsNumber || ' -'}
            </TruncatedText>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
