import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Tooltip,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import {t} from 'i18next';
import update from 'immutability-helper';
import {isEmpty} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip as TooltipRecharts,
  XAxis,
  YAxis,
} from 'recharts';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {usePrint} from '../../../hooks/print';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  InteractionsLastDaysQuery,
  InteractionsLastDaysResponse,
} from '../../../interfaces/InteractionsLastDays';
// import {CHART_COLORS} from '../../../utils/colors';
import {saveFile} from '../../../utils/file';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {DateSelect} from '../../selectors/DateSelect';
import {MachineInputSelectV2} from '../../selectors/MachineInputSelectV2';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

export interface InteractionsLastDaysData {
  refreshInterval?: number | null;
  params?: {
    zone_id?: number | null;
    date_end?: string | null;
    input?: {
      [key: number]: 0 | 1 | null | undefined;
    };
  };
}

export const getInteractionsLastDaysData = (): InteractionsLastDaysData => ({
  params: {},
});

const InteractionsLastDays = (props: Props) => {
  const [panel] = usePanel();
  const [config, setConfig] = useState(
    !isEmpty(props.value) ? props.value : getInteractionsLastDaysData()
  );
  const [exportInProgress, setExportInProgress] = useState(false);

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] =
    useState<InteractionsLastDaysResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<InteractionsLastDaysQuery>(
    () => ({
      zone_id: config.params?.zone_id ?? null,
      date_end: config.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
      input: config.params?.input ?? {},
    }),
    [config]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
      props.onUpdate?.({...props.value, params});
    }
  }, [params]);

  const fetchData = useCallback(
    async (params: InteractionsLastDaysQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/report/interactions-last-10-days`;
        const resp = await API.get<InteractionsLastDaysResponse>(endpoint, {
          params: {
            ...params,
            input: JSON.stringify(params.input),
          },
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  /****************/
  /* auto refresh */
  /****************/
  useRefreshInterval(() => fetchData(params), config?.refreshInterval);

  /*********/
  /* chart */
  /*********/

  const theme = useTheme();

  const submitExport = async () => {
    setExportInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/report/interactions-last-10-days-export`;
      const resp = await API.get(endpoint, {
        params: {
          ...params,
          input: JSON.stringify(params.input),
        },
        responseType: 'blob',
      });
      const filename =
        resp?.headers['content-disposition']?.split('filename=')?.[1] ||
        'trend_interactions_report.xlsx';
      saveFile(resp.data, filename);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    } finally {
      setExportInProgress(false);
    }
  };

  const bars = [
    {key: 'warning_total', label: 'Warnings', color: 'yellow'},
    {key: 'hazard_total', label: 'Hazards', color: 'red'},
    {key: 'remote_total', label: 'Remote Stops', color: 'purple'},
  ];

  const lines = [
    {
      key: 'warning_total_regression',
      label: 'Linear (Warnings)',
      color: 'yellow',
    },
    {key: 'hazard_total_regression', label: 'Linear (Hazards)', color: 'red'},
    {
      key: 'remote_total_regression',
      label: 'Linear (Remote Stops)',
      color: 'purple',
    },
  ];

  const [hiddenKeys, setHiddenKeys] = useState<string[]>([]);
  const toggleKey = (key: string) => {
    setHiddenKeys(
      hiddenKeys.includes(key)
        ? hiddenKeys.filter((i) => i !== key)
        : [...hiddenKeys, key]
    );
  };

  /*********/
  /* print */
  /*********/
  const chartRef = useRef<HTMLDivElement>();
  const [printChart] = usePrint(chartRef.current);
  const printTheme = useAppSelector(({app}) => app.printTheme);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      gap={3}
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)} Over Period -{' '}
        {dayjs(params.date_end).subtract(9, 'd').format('YYYY-MM-DD')} to{' '}
        {dayjs(params.date_end).format('YYYY-MM-DD')}
      </DashboardPanelTitleSlot>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Box display="flex" overflow="auto" py={1} gap={1}>
            <Box minWidth={200}>
              <DateSelect
                value={dayjs(config.params?.date_end).toDate()}
                renderInput={{
                  size: 'small',
                  fullWidth: true,
                  label: 'Date End',
                }}
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...config.params,
                          date_end: v
                            ? dayjs(v).format('YYYY-MM-DD')
                            : undefined,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>

            <ZoneSelect
              value={config.params?.zone_id}
              size="small"
              nullLabel="All Sections"
              sx={{minWidth: 200}}
              onChange={(v) => {
                setConfig?.(
                  update(config, {
                    params: {
                      zone_id: {
                        $set: v,
                      },
                    },
                  })
                );
              }}
            />

            <Box width={200}>
              <MachineInputSelectV2
                value={config.params?.input}
                label="Inputs"
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...config.params,
                          input: v,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>
          </Box>

          <Box display="flex">
            <ButtonGroup>
              <Tooltip title="Reload">
                <LoadingButton
                  size="small"
                  loading={fetchedInProgress}
                  variant="outlined"
                  onClick={() => params && fetchData(params)}
                >
                  <RefreshIcon />
                </LoadingButton>
              </Tooltip>

              <AutoRefreshSelect
                value={config?.refreshInterval ?? null}
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      refreshInterval: {
                        $set: v,
                      },
                    })
                  );
                }}
              />

              <Tooltip title="Export to Excel">
                <LoadingButton
                  size="small"
                  loading={exportInProgress}
                  variant="outlined"
                  onClick={() => submitExport()}
                >
                  <FileDownloadIcon />
                </LoadingButton>
              </Tooltip>

              <Tooltip title="Print">
                <Button onClick={() => printChart()}>
                  <PrintIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>

      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      <Box
        ref={chartRef}
        overflow="hidden"
        position="relative"
        display="flex"
        flexDirection="column"
        gap={3}
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <Box
          height={printTheme ? '500px' : '100%'}
          width={printTheme ? '700px' : '100%'}
        >
          {fetchedData?.results ? (
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart data={fetchedData.results}>
                <XAxis dataKey="interactions_date" />
                <YAxis />
                <TooltipRecharts
                  shared={false}
                  labelStyle={{
                    color: theme.palette.text.secondary,
                  }}
                  contentStyle={{
                    backgroundColor: theme.palette.background.default,
                  }}
                  cursor={false}
                />
                <Legend onClick={(item) => toggleKey(item.dataKey)} />

                {bars.map(({key, label, color}) => (
                  <Bar
                    key={key}
                    dataKey={key}
                    name={label}
                    hide={hiddenKeys.includes(key)}
                    label={{
                      position: 'top',
                      fill: theme.palette.text.secondary,
                    }}
                    fill={color}
                    isAnimationActive={!printTheme}
                  />
                ))}

                {lines?.map(({key, label, color}) => (
                  <Line
                    key={key}
                    dataKey={key}
                    name={label}
                    hide={hiddenKeys.includes(key)}
                    stroke={color}
                    isAnimationActive={!printTheme}
                  />
                ))}
              </ComposedChart>
            </ResponsiveContainer>
          ) : (
            <Box
              display="flex"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              {fetchedInProgress ? 'Loading...' : 'No data to display'}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default InteractionsLastDays;
