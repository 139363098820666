import {get} from 'lodash';
export const legacyApiBaseUrl = get(window, 'strata.vars.legacyApiBaseUrl', '');
export const checkIsLegacyUrl = (url?: string) =>
  url?.startsWith(legacyApiBaseUrl) ?? false;

const apiUrlPort = get(window, 'strata.vars.apiUrlPort', '')
  ? `:${get(window, 'strata.vars.apiUrlPort', '')}`
  : '';
const apiUrlSuffix = get(window, 'strata.vars.apiUrlSuffix', '')
  ? `/${get(window, 'strata.vars.apiUrlSuffix', '')}`
  : '';
const mapUrlPort = get(window, 'strata.vars.mapUrlPort', '')
  ? `:${get(window, 'strata.vars.mapUrlPort', '')}`
  : '';
const mapUrlSuffix = get(window, 'strata.vars.mapUrlSuffix', '')
  ? `/${get(window, 'strata.vars.mapUrlSuffix', '')}`
  : '';
//
const apiHost = apiUrlPort
  ? `${window.location.protocol}//${window.location.hostname}`
  : document.location.origin;
const mapHost = mapUrlPort
  ? `${window.location.protocol}//${window.location.hostname}`
  : document.location.origin;
//
export const apiBaseUrl = `${apiHost}${apiUrlPort}${apiUrlSuffix}`;
// export const apiBaseUrl = `http://localhost:3001`;
export const mineMapBaseUrl = `${mapHost}${mapUrlPort}${mapUrlSuffix}`;
// export const mineMapBaseUrl = `https://stratadevv2.zgtec.com/api`;
