/* eslint-disable @typescript-eslint/no-unused-vars */
import {ZoomInMapTwoTone} from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import {
  Box,
  BoxProps,
  Button,
  Menu,
  MenuItem,
  Tooltip as MuiTooltip,
} from '@mui/material';
import {LatLng, LatLngBounds, latLngBounds} from 'leaflet';
import {MousePosition} from 'leaflet.mouseposition.ts/dist/src';
import _ from 'lodash';
import {useEffect, useMemo, useState} from 'react';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  useMapEvents,
} from 'react-leaflet';
import {useSelector} from 'react-redux';
import {useComponentSize} from 'react-use-size';

import {useConfigurations} from '../../../hooks/configuration';
import {AlarmLogNode} from '../../../interfaces/AlarmLogNode';
import {CommtracNode} from '../../../interfaces/CommtracNode';
import {CommtracNodeByCnEvent} from '../../../interfaces/CommtracNodeByCnEvent';
import {CommtracNodeHistory} from '../../../interfaces/CommtracNodeHistory';
import {HazardEventLog} from '../../../interfaces/HazardAILog';
import {Node} from '../../../interfaces/Node';
import {SafeyeNode} from '../../../interfaces/SafeyeNode';
import reduxSelectors from '../../../redux/selectors';
import {
  ALARM_MODULE_COLORS,
  ALARM_MODULE_TYPES,
} from '../../../utils/alarm-modules';
import {
  ASSET_EVENT_COLORS,
  ASSET_EVENT_TYPES,
} from '../../../utils/commtrac-nodes';
import {generatePickColorSet} from '../../../utils/heatmap';
import {COLORED_LEAFLET_ICONS} from '../../../utils/icons';
import {fetchMapConfig, saveMapConfig} from '../../../utils/map';
import {
  getCommtracNodeByCnHistoryId,
  getCommtracNodeHistoryId,
} from '../../../utils/message-types';
import {
  CN_EVENT_COLORS,
  CN_EVENT_TYPES,
  getNodeKey,
} from '../../../utils/nodes';
import {
  SAFEYE_NANO_EVENT_COLORS,
  SAFEYE_NANO_EVENT_TYPES,
} from '../../../utils/safeyeNodes';
import {HazardHeatMapData} from '../../dashboard-panels/HazardHeatmap/HazardHeatmapGrid';
import {HeatMapLayer} from '../../dashboard-panels/HazardHeatmap/HeatMapLayer';
import {MapEmbedConfiguration} from '../Map';
import AlarmTooltipContent from './AlarmTooltipContent';
import CommtracNodeHistoryTooltipContent from './CommtracNodeHistoryTooltipContent';
import CommtracNodeTooltipContent from './CommtracNodeTooltipContent';
import HazardAIDetectionLogTooltipContent from './HazardAIDetectionLogTooltipContent';
import NodeTooltipContent from './NodeTooltipContent';
import SafeyeNodeTooltipContent from './SafeyeNodeTooltipContent';
import {
  calculateMinimumBoundingCircleRadius,
  getDistanceFromLatLngInMeters,
  pixelsToMeters,
} from './utils';

interface Props extends BoxProps {
  panel?:
    | 'warning_hazard_report'
    | 'detection_log_report'
    | 'heatmap_report'
    | 'heatmap_event_logs';
  shownSafeyeNodes?: SafeyeNode[];
  alarmNodes?: AlarmLogNode[];
  commtracNodes?: CommtracNode[];
  hazardDetectionLogs?: HazardEventLog[];
  heatMap?: HazardHeatMapData[];
  nodes?: Node[] | null;
  employeeHistoryData?: CommtracNodeHistory[] | null;
  assetHistoryData?: CommtracNodeHistory[] | null;
  commtracNodeByCnEventsData?: CommtracNodeByCnEvent[] | null;
  selectedMapLayers?: MapLayerId[];
  availableMapLayers?: MapLayerId[];
  hazardMapType?: MapType;
  selectedLevel?: number | null;
  disableEditMode?: boolean;
  onSelectMapLayers?: (value: MapLayerId[]) => void;
  onSelectLevel?: (value: number | null) => void;
  onGetClickCoordinates?: (value: MapLatLangCoordinates) => void;
  onGetClickHeatmapCoordinates?: (
    value: MapLatLangCoordinates,
    radius: number
  ) => void;
  onUpdateMapType?: (value?: MapType) => void;
  onOpenItem?: (
    id: number,
    type: 'cn' | 'wifi' | 'employee' | 'asset' | 'alarm'
  ) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => void;
  onClickMedia?: (id?: number) => void;
}

const mousePosition = new MousePosition({
  position: 'bottomleft',
  customComponent: ({latlng}) => {
    return latlng.lat || latlng.lng ? (
      <div style={{color: 'black'}}>
        {latlng.lng.toFixed(6)}, {latlng.lat.toFixed(6)}
      </div>
    ) : null;
  },
});

const mapLayers = [
  {id: 'mine', label: 'Mine'},
  {id: 'nodes', label: 'Nodes'},
  {id: 'alarms', label: 'Alarm Modules'},
  {id: 'assets', label: 'Assets'},
  {id: 'employee_history', label: 'Employee History'},
  {id: 'asset_history', label: 'Asset History'},
  {id: 'node_history', label: 'Nodes'},
  {id: 'commtrac_node_by_cn_history', label: 'Employee/Asset History'},
  {id: 'hazardAiLogs', label: 'HazardAI Logs'},
  {id: 'safeye_nodes', label: 'Safeye Nodes'},
  {id: 'heatmap', label: 'HeatMap'},
] as const;

export type MapLayerId = (typeof mapLayers)[number]['id'];
export type MapLatLangCoordinates = {
  lat: number | null;
  lng: number | null;
};

export type MapType = 'street' | 'satelite';

export const HazardMap = ({
  panel,
  heatMap,
  alarmNodes: shownAlarmNodes,
  commtracNodes: shownCommtracNodes,
  hazardDetectionLogs: shownHazardDetectionLogs,
  nodes: shownNodes,
  employeeHistoryData,
  assetHistoryData,
  commtracNodeByCnEventsData,
  selectedMapLayers,
  selectedLevel,
  disableEditMode,
  hazardMapType,
  onGetClickCoordinates,
  onGetClickHeatmapCoordinates,
  onOpenItem,
  onOpenHistory,
  onUpdateMapType,
  shownSafeyeNodes,
  onClickMedia,
  ...props
}: Props) => {
  const [mapType, setMapType] = useState<MapType>(hazardMapType ?? 'street');
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const [isEnableAutoFocusMode, setIsEnableAutoFocusMode] = useState(
    fetchMapConfig(panel)?.autoFocus ?? true
  );
  const [mapEmbedConfig, setMapEmbedConfig] =
    useState<MapEmbedConfiguration | null>(fetchMapConfig(panel));

  const commtracNodes = shownCommtracNodes ?? assets.commtrac_nodes;
  const hazardAiLogs = shownHazardDetectionLogs ?? [];
  const nodes = shownNodes ?? assets.nodes;
  const alarmNodes = shownAlarmNodes ?? assets.alarm_logs;
  const acknowledgedOrInvalidPostionNodes = alarmNodes?.filter(
    (it) => it?.acknowledged === 0 || !it?.latitude || !it?.longitude
  );
  const safeyeNodes = shownSafeyeNodes ?? assets.safeye_nodes;

  // Layers
  const [internalSelectedMapLayers] = useState(mapLayers.map((i) => i.id));
  const [realFitBounds, setRealFitBound] = useState<LatLngBounds | null>(null);
  const computedSelectedMapLayers =
    selectedMapLayers ?? internalSelectedMapLayers;

  // Levels
  const [internalSelectedLevel] = useState<number | null>(null);
  const computedSelectedLevel = selectedLevel ?? internalSelectedLevel;
  const selectedLevelOption = useMemo(
    () =>
      computedSelectedLevel
        ? assets.layers?.find((i) => i.id === computedSelectedLevel)
        : null,
    [computedSelectedLevel, assets.layers]
  );

  useEffect(() => {
    setMapEmbedConfig(fetchMapConfig(panel));
    setIsEnableAutoFocusMode(fetchMapConfig(panel)?.autoFocus ?? true);
  }, [panel]);

  const commtracNodeEmployees = useMemo(
    () => commtracNodes?.filter((i) => i.type === 'miner'),
    [commtracNodes]
  );

  const commtracNodeAssets = useMemo(
    () => commtracNodes?.filter((i) => i.type === 'asset'),
    [commtracNodes]
  );

  // Leeflet
  const [map, setMap] = useState<any>(null);
  const {ref, width, height} = useComponentSize();

  const minLayerBounds = useMemo(
    () =>
      selectedLevelOption
        ? latLngBounds(
            [+selectedLevelOption.map.minx, +selectedLevelOption.map.miny],
            [+selectedLevelOption.map.maxx, +selectedLevelOption.map.maxy]
          )
        : undefined,
    [selectedLevel]
  );

  const [topRightPositon, setTopRightPosition] = useState<any | null>(null);
  const MapEvents = () => {
    useMapEvents({
      click(e) {
        onGetClickCoordinates?.(e.latlng);
        if (
          computedSelectedMapLayers.includes('heatmap') &&
          heatMap &&
          heatMap[0]
        ) {
          fetchRadius(e.latlng);
        }
      },
      moveend() {
        if (map) {
          saveMapConfig(map, panel, isEnableAutoFocusMode);
        }
      },
    });
    return null;
  };

  const fetchRadius = (pos: LatLng) => {
    const rangeMeter: number = pixelsToMeters(
      (heatMap && heatMap[0].configuration?.radius) ?? 25,
      pos.lat,
      map.getZoom()
    );

    if (heatMap && heatMap?.length > 0) {
      if (heatMap[0]) {
        const points = heatMap[0].address_points?.filter((point) => {
          return (
            getDistanceFromLatLngInMeters(
              point[0],
              point[1],
              pos.lat,
              pos.lng
            ) < rangeMeter
          );
        });

        if (points && points?.length > 0) {
          const pointArray: [number, number][] = points?.map((it) => [
            it[0],
            it[1],
          ]);

          const minimumRadius =
            (calculateMinimumBoundingCircleRadius(pointArray) ?? 0) +
            rangeMeter / 2;
          onGetClickHeatmapCoordinates?.(pos, minimumRadius);
        }
      }
    }
  };

  useEffect(() => {
    const updateMarkerPosition = () => {
      // const mapSize = map.getSize();
      if (selectedLevelOption) {
        const topRightPoint = [
          +selectedLevelOption?.map.maxx,
          +selectedLevelOption?.map.maxy,
        ];
        // Set marker position
        setTopRightPosition(topRightPoint);
      } else {
        setTopRightPosition(null);
      }
    };

    if (map) {
      map.on('resize move', updateMarkerPosition);
      updateMarkerPosition();
    }
    return () => {
      if (map) {
        map.off('resize move', updateMarkerPosition);
      }
    };
  }, [map, selectedLevelOption]);

  useEffect(() => {
    onUpdateMapType?.(mapType);
  }, [mapType]);

  // map icons
  const configurations = useConfigurations();

  const getCommunicationNodeIcon = (item: Node) => {
    let color: string | number | undefined;
    if (item.e_109) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_NEW_CN_DETECTED] ?? '']
          ?.value;
    } else if (item.status === 'inactive') {
      color = 'gray';
    } else if (item.e_101) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_ASSIGNED_MASTER] ?? '']
          ?.value;
    } else if (item.e_121) {
      color =
        configurations[
          CN_EVENT_COLORS[CN_EVENT_TYPES.CN_TOO_MANY_HEARTBEATS] ?? ''
        ]?.value;
    } else if (item.e_110) {
      color =
        configurations[
          CN_EVENT_COLORS[CN_EVENT_TYPES.CN_PIC_VERSION_HAS_CHANGED] ?? ''
        ]?.value;
    } else if (item.e_111) {
      color =
        configurations[
          CN_EVENT_COLORS[CN_EVENT_TYPES.CN_CC1110_VERSION_HAS_CHANGED] ?? ''
        ]?.value;
    } else if (item.e_102) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_NO_HEARTBEAT] ?? '']
          ?.value;
    } else if (item.e_103) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_LOW_BATTERY] ?? '']
          ?.value;
    } else if (item.e_104) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_FEW_HEARTBEATS] ?? '']
          ?.value;
    }
    return COLORED_LEAFLET_ICONS.communicationNodes[color ?? 'default'];
  };

  const getWifiIcon = (item: Node) => {
    let color: string | number | undefined;
    if (item.wifi_connection_status === 'inactive') {
      color = 'gray';
    } else if (item.wifi_connection_status === 'disconnected') {
      color = 'red';
    }
    return COLORED_LEAFLET_ICONS.wifi[color ?? 'blue'];
  };

  const getNodeIcon = (item: Node) => {
    if (item.node_type === 'communication_node') {
      return getCommunicationNodeIcon(item);
    } else if (item.node_type === 'wifi_point') {
      return getWifiIcon(item);
    }
    return COLORED_LEAFLET_ICONS.communicationNodes.default;
  };

  const getAlarmLogIcon = (item: AlarmLogNode) => {
    let color: string | number | undefined;
    if (item?.status === 'active') {
      color = 'blue';
    }
    if (item?.status === 'inactive') {
      color = 'gray';
    }
    if (item?.e_701) {
      color =
        configurations[
          ALARM_MODULE_COLORS[ALARM_MODULE_TYPES.ALARM_NO_HEARTBEAT] ?? ''
        ]?.value ?? 'default';
    }
    if (item?.e_702) {
      color =
        configurations[
          ALARM_MODULE_COLORS[ALARM_MODULE_TYPES.ALARM_NEW_MODULE_DETECTED] ??
            ''
        ]?.value ?? 'gray';
    }
    if (item?.e_704) {
      color =
        configurations[
          ALARM_MODULE_COLORS[ALARM_MODULE_TYPES.ALARM_ENABLED_WARNING_TYPES] ??
            ''
        ]?.value ?? 'yellow';
    }
    if (item?.e_703) {
      color =
        configurations[
          ALARM_MODULE_COLORS[ALARM_MODULE_TYPES.ALARM_ENABLED_ALARM_TYPES] ??
            ''
        ]?.value ?? 'red';
    }

    return COLORED_LEAFLET_ICONS.alarm[color ?? 'gray'];
  };

  const getHazardAIDetectionLogIcon = (log: HazardEventLog) => {
    let color: string = 'blue';
    if (log.zone === 'warning') {
      color = 'yellow';
    } else if (log.zone === 'hazard') {
      color = 'red';
    } else if (log.zone === 'id') {
      color = 'blue';
    }
    if (log.object === 'vehicle') {
      return COLORED_LEAFLET_ICONS.assets[color];
    } else if (log.object === 'person') {
      return COLORED_LEAFLET_ICONS.person[color];
    } else if (log.object === 'vision impaired') {
      return COLORED_LEAFLET_ICONS.vision[color];
    }

    return COLORED_LEAFLET_ICONS.amsUnknown.blue;
  };
  const getAssetIcon = (item: CommtracNode) => {
    let color: string | number | undefined;
    if (item.e_309) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_NEW_ASSET_DETECTED] ?? ''
        ]?.value;
    } else if (item.e_321) {
      color =
        configurations[
          ASSET_EVENT_COLORS[
            ASSET_EVENT_TYPES.ASSET_TOO_MANY_LOCATION_MESSAGES
          ] ?? ''
        ]?.value;
    } else if (item.e_301) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_NO_LOCATION] ?? ''
        ]?.value;
    } else if (item.e_326) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_INVALID_LAST_LOCATION] ??
            ''
        ]?.value;
    } else if (item.e_302) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_LOW_BATTERY] ?? ''
        ]?.value;
    }
    return COLORED_LEAFLET_ICONS.assetEvents[color ?? 'default'];
  };

  const getEmployeeHistoryIcon = () => {
    return COLORED_LEAFLET_ICONS.miners.default;
  };

  const getAssetHistoryIcon = () => {
    return COLORED_LEAFLET_ICONS.assetEvents.default;
  };

  const getCommtracNodeByCnHistoryIcon = (item: CommtracNodeByCnEvent) => {
    if (item.type === 0) {
      return COLORED_LEAFLET_ICONS.assets.default;
    }
    return COLORED_LEAFLET_ICONS.miners.default;
  };

  const getSafeyeNodeIcon = (item: SafeyeNode) => {
    if (item?.e_804) {
      const color =
        configurations[
          SAFEYE_NANO_EVENT_COLORS[
            SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_CAMERA_DISCONNECTED
          ] ?? ''
        ]?.value;

      return COLORED_LEAFLET_ICONS.safeyeNodes[color ?? 'default'];
    }

    if (item?.e_803) {
      const color =
        configurations[
          SAFEYE_NANO_EVENT_COLORS[
            SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_CAMERA_IMPAIRED
          ] ?? ''
        ]?.value;

      return COLORED_LEAFLET_ICONS.safeyeNodes[color ?? 'default'];
    }

    if (item?.e_801) {
      const color =
        configurations[
          SAFEYE_NANO_EVENT_COLORS[
            SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_NO_HEARTBEAT
          ] ?? ''
        ]?.value;

      return COLORED_LEAFLET_ICONS.safeyeNodes[color ?? 'default'];
    }

    if (item?.e_806) {
      const color =
        configurations[
          SAFEYE_NANO_EVENT_COLORS[
            SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_GPS_FAILURE
          ] ?? ''
        ]?.value;

      return COLORED_LEAFLET_ICONS.safeyeNodes[color ?? 'default'];
    }

    if (item?.e_805) {
      const color =
        configurations[
          SAFEYE_NANO_EVENT_COLORS[
            SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_SPEED_LIMIT_EXCEEDED
          ] ?? ''
        ]?.value;

      return COLORED_LEAFLET_ICONS.safeyeNodes[color ?? 'default'];
    }

    if (item?.e_802) {
      const color =
        configurations[
          SAFEYE_NANO_EVENT_COLORS[
            SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_NEW_HAZARD_AI_SYSTEM
          ] ?? ''
        ]?.value;

      return COLORED_LEAFLET_ICONS.safeyeNodes[color ?? 'default'];
    }

    return COLORED_LEAFLET_ICONS.safeyeNodes.default;
  };

  useEffect(() => {
    if (map) {
      mousePosition.addTo(map);
    }
    return () => {
      mousePosition.remove();
    };
  }, [map]);

  useEffect(() => {
    map?.invalidateSize();
  }, [width, height]);

  // Layer and level
  const [layerMenuAnchorEl, setLayerMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const layerMenuOpen = Boolean(layerMenuAnchorEl);

  const fitBoundRange = useMemo(() => {
    let minLng: number = 180;
    let maxLng: number = -180;
    let minLat: number = 90;
    let maxLat: number = -90;
    let totalMarkers: number = 0;

    if (computedSelectedMapLayers?.includes('alarms')) {
      alarmNodes?.forEach((it: AlarmLogNode) => {
        minLng = Math.min(minLng, +(it?.longitude ?? 0));
        maxLng = Math.max(maxLng, +(it?.longitude ?? 0));
        minLat = Math.min(minLat, +(it?.latitude ?? 0));
        maxLat = Math.max(maxLat, +(it?.latitude ?? 0));
      });
      totalMarkers += alarmNodes.length;
    }

    if (computedSelectedMapLayers?.includes('hazardAiLogs')) {
      hazardAiLogs?.forEach((it: HazardEventLog) => {
        minLng = Math.min(minLng, +(it?.longitude ?? 0));
        maxLng = Math.max(maxLng, +(it?.longitude ?? 0));
        minLat = Math.min(minLat, +(it?.latitude ?? 0));
        maxLat = Math.max(maxLat, +(it?.latitude ?? 0));
      });
      totalMarkers += hazardAiLogs.length;
    }

    if (computedSelectedMapLayers?.includes('safeye_nodes')) {
      safeyeNodes?.forEach((it: SafeyeNode) => {
        minLng = Math.min(minLng, +(it?.longitude ?? 0));
        maxLng = Math.max(maxLng, +(it?.longitude ?? 0));
        minLat = Math.min(minLat, +(it?.latitude ?? 0));
        maxLat = Math.max(maxLat, +(it?.latitude ?? 0));
      });
      totalMarkers += safeyeNodes.length;
    }

    if (computedSelectedMapLayers?.includes('assets')) {
      commtracNodeAssets?.forEach((it) => {
        minLng = Math.min(minLng, +(it?.commtrac_pos_lon ?? 0));
        maxLng = Math.max(maxLng, +(it?.commtrac_pos_lon ?? 0));
        minLat = Math.min(minLat, +(it?.commtrac_pos_lat ?? 0));
        maxLat = Math.max(maxLat, +(it?.commtrac_pos_lat ?? 0));
      });
      totalMarkers += commtracNodeAssets.length;
    }

    if (computedSelectedMapLayers?.includes('nodes')) {
      nodes?.forEach((it) => {
        minLng = Math.min(minLng, +(it?.longitude ?? 0));
        maxLng = Math.max(maxLng, +(it?.longitude ?? 0));
        minLat = Math.min(minLat, +(it?.latitude ?? 0));
        maxLat = Math.max(maxLat, +(it?.latitude ?? 0));
      });
      totalMarkers += nodes.length;
    }

    if (computedSelectedMapLayers?.includes('employee_history')) {
      employeeHistoryData?.forEach((it) => {
        minLng = Math.min(
          minLng,
          +(it.longitude || (selectedLevelOption?.map.maxy ?? 0))
        );
        maxLng = Math.max(
          maxLng,
          +(it.longitude || (selectedLevelOption?.map.maxy ?? 0))
        );
        minLat = Math.min(
          minLat,
          +(it.latitude || (selectedLevelOption?.map.maxx ?? 0))
        );
        maxLat = Math.max(
          maxLat,
          +(it.latitude || (selectedLevelOption?.map.maxx ?? 0))
        );
      });
      totalMarkers += employeeHistoryData?.length ?? 0;
    }

    if (computedSelectedMapLayers?.includes('asset_history')) {
      assetHistoryData?.forEach((it) => {
        minLng = Math.min(
          minLng,
          +(it.longitude || (selectedLevelOption?.map.maxy ?? 0))
        );
        maxLng = Math.max(
          maxLng,
          +(it.longitude || (selectedLevelOption?.map.maxy ?? 0))
        );
        minLat = Math.min(
          minLat,
          +(it.latitude || (selectedLevelOption?.map.maxx ?? 0))
        );
        maxLat = Math.max(
          maxLat,
          +(it.latitude || (selectedLevelOption?.map.maxx ?? 0))
        );
      });
      totalMarkers += assetHistoryData?.length ?? 0;
    }

    if (computedSelectedMapLayers?.includes('commtrac_node_by_cn_history')) {
      commtracNodeByCnEventsData?.forEach((it) => {
        minLng = Math.min(
          minLng,
          +(it.pos_lon || (selectedLevelOption?.map.maxy ?? 0))
        );
        maxLng = Math.max(
          maxLng,
          +(it.pos_lon || (selectedLevelOption?.map.maxy ?? 0))
        );
        minLat = Math.min(
          minLat,
          +(it.pos_lat || (selectedLevelOption?.map.maxx ?? 0))
        );
        maxLat = Math.max(
          maxLat,
          +(it.pos_lat || (selectedLevelOption?.map.maxx ?? 0))
        );
      });
      totalMarkers += commtracNodeByCnEventsData?.length ?? 0;
    }

    if (
      computedSelectedMapLayers?.includes('heatmap') &&
      heatMap &&
      heatMap?.length > 0
    ) {
      heatMap[0].address_points?.forEach((it) => {
        minLng = Math.min(
          minLng,
          +(it[1] || (selectedLevelOption?.map.maxy ?? 0))
        );
        maxLng = Math.max(
          maxLng,
          +(it[1] || (selectedLevelOption?.map.maxy ?? 0))
        );
        minLat = Math.min(
          minLat,
          +(it[0] || (selectedLevelOption?.map.maxx ?? 0))
        );
        maxLat = Math.max(
          maxLat,
          +(it[0] || (selectedLevelOption?.map.maxx ?? 0))
        );
      });
      totalMarkers += heatMap[0].address_points?.length ?? 0;
    }

    const mapConfig = fetchMapConfig(panel);
    if (totalMarkers < 1) {
      minLng = Number(assets?.company?.miny ?? -180);
      maxLng = Number(assets?.company?.maxy ?? 180);
      minLat = Number(assets?.company?.minx ?? -90);
      maxLat = Number(assets?.company?.maxx ?? 90);
    }

    if (isEnableAutoFocusMode || !mapConfig) {
      if (
        (acknowledgedOrInvalidPostionNodes.length > 0 || totalMarkers < 1) &&
        computedSelectedMapLayers.includes('mine')
      ) {
        return (
          minLayerBounds ?? latLngBounds([minLat, minLng], [maxLat, maxLng])
        );
      } else if (totalMarkers < 1) {
        return latLngBounds([minLat, minLng], [maxLat, maxLng]);
      }
    } else if (mapConfig && !isEnableAutoFocusMode) {
      return latLngBounds(mapConfig?.bounds[0], mapConfig?.bounds[1]);
    }

    return latLngBounds([minLat, minLng], [maxLat, maxLng]);
    // if (
    //   (acknowledgedOrInvalidPostionNodes.length > 0 || totalMarkers < 1) &&
    //   computedSelectedMapLayers.includes('mine')
    // ) {
    //   return minLayerBounds ?? latLngBounds([minLat, minLng], [maxLat, maxLng]);
    // } else if (
    //   totalMarkers < 1 &&
    //   !computedSelectedMapLayers?.includes('mine')
    // ) {
    //   minLng = Number(assets?.company?.miny ?? 180);
    //   maxLng = Number(assets?.company?.maxy ?? -180);
    //   minLat = Number(assets?.company?.minx ?? 90);
    //   maxLat = Number(assets?.company?.maxx ?? -90);
    //   return minLayerBounds ?? latLngBounds([minLat, minLng], [maxLat, maxLng]);
    // }
    // return latLngBounds([maxLat, maxLng], [minLat, minLng]);
  }, [
    assets.company,
    minLayerBounds,
    computedSelectedMapLayers,
    commtracNodeEmployees,
    commtracNodeAssets,
    nodes,
    alarmNodes,
    acknowledgedOrInvalidPostionNodes,
    employeeHistoryData,
    assetHistoryData,
    commtracNodeByCnEventsData,
    heatMap,
    hazardAiLogs,
  ]);
  useEffect(() => {
    setRealFitBound((prev) => {
      if (_.isEqual(prev, fitBoundRange)) {
        return prev;
      }

      return fitBoundRange;
    });
  }, [fitBoundRange]);
  useEffect(() => {
    if (map) {
      if (realFitBounds) {
        map ? map.fitBounds(realFitBounds) : '';
      }
    }
  }, [map, realFitBounds]);

  useEffect(() => {
    if (map) {
      saveMapConfig(map, panel, isEnableAutoFocusMode);
    }
  }, [isEnableAutoFocusMode]);

  const onFitSites = () => {
    let minLng: number = 180;
    let maxLng: number = -180;
    let minLat: number = 90;
    let maxLat: number = -90;
    const machines = assets.safeye_nodes;
    machines?.forEach((it: SafeyeNode) => {
      if (it.asset_machine_id) {
        minLng = Math.min(minLng, +(it?.longitude ?? 0));
        maxLng = Math.max(maxLng, +(it?.longitude ?? 0));
        minLat = Math.min(minLat, +(it?.latitude ?? 0));
        maxLat = Math.max(maxLat, +(it?.latitude ?? 0));
      }
    });

    map.fitBounds(latLngBounds([minLat, minLng], [maxLat, maxLng]));
  };

  return (
    <Box
      ref={ref}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      {...props}
    >
      <Box
        width="100%"
        height="100%"
        position="relative"
        sx={{
          '.leaflet-top': {top: 45},
          '.leaflet-tooltip-pane': {zIndex: 750},
        }}
      >
        <Box position="absolute" zIndex={500} top={11} left={11} display="flex">
          <Button
            variant="outlined"
            sx={{
              color: '#000',
              backgroundColor: '#FFF',
              borderColor: 'lightgray !important',
              ':hover': {
                backgroundColor: '#FFF',
              },
              py: 0,
              pl: 2,
              pr: 0.5,
              textTransform: 'capitalize',
            }}
            onClick={(event) => setLayerMenuAnchorEl(event.currentTarget)}
          >
            {mapType === null ? 'Map Type' : mapType}
            <ArrowDropDownIcon
              sx={{
                ml: 1.5,
                borderStyle: 'solid',
                borderWidth: 0,
                borderLeftWidth: 1,
                pl: 0.5,
                borderColor: 'lightgray',
                height: 30,
              }}
            />
          </Button>
          <Menu
            anchorEl={layerMenuAnchorEl}
            open={layerMenuOpen}
            onBackdropClick={() => setLayerMenuAnchorEl(null)}
          >
            {['street', 'satelite'].map((i) => (
              <MenuItem
                key={i}
                selected={mapType === i}
                onClick={() => {
                  setMapType(i as MapType);
                  setLayerMenuAnchorEl(null);
                }}
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                {i}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <MuiTooltip title="All Sites Zoom">
          <Button
            variant="contained"
            size="small"
            sx={{
              position: 'absolute',
              zIndex: 500,
              top: 130,
              left: 11,
              p: 0.5,
              borderRadius: 2,
              minWidth: 'auto',
              color: '#000',
              backgroundColor: '#FFF',
            }}
            onClick={() => onFitSites()}
          >
            <LocationSearchingIcon />
          </Button>
        </MuiTooltip>
        <MuiTooltip title="Auto Zoom">
          <Button
            variant="contained"
            size="small"
            sx={{
              position: 'absolute',
              zIndex: 500,
              top: 170,
              left: 11,
              p: 0.5,
              borderRadius: 2,
              minWidth: 'auto',
              color: '#000',
              backgroundColor: !isEnableAutoFocusMode ? '#FFF' : 'main',
            }}
            onClick={() => setIsEnableAutoFocusMode(!isEnableAutoFocusMode)}
          >
            <ZoomInMapTwoTone />
          </Button>
        </MuiTooltip>
        <MapContainer
          ref={setMap}
          bounds={fitBoundRange}
          zoom={12}
          maxZoom={18}
          style={{width: '100%', height: '100%'}}
        >
          {mapType === 'street' && (
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          )}
          {mapType === 'satelite' && (
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              subdomains={['mt1', 'mt2', 'mt3']}
            />
          )}

          {computedSelectedMapLayers.includes('hazardAiLogs') &&
            hazardAiLogs?.map((i) => (
              <Marker
                key={`hazard-ai-detection-log-${i.id}`}
                position={[i.latitude ?? 0, i.longitude ?? 0]}
                icon={getHazardAIDetectionLogIcon(i)}
                interactive
                eventHandlers={{
                  click: (event) => event.target.openPopup(),
                  popupopen: (event) => {
                    event.target._tooltip.options.opacity = 0;
                  },
                  popupclose: (event) => {
                    event.target._tooltip.options.opacity = 0.9;
                  },
                }}
              >
                <Popup interactive autoClose={false} offset={[0, -32]}>
                  <HazardAIDetectionLogTooltipContent
                    item={i}
                    onClickMedia={onClickMedia}
                  />
                </Popup>

                <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
                  <HazardAIDetectionLogTooltipContent
                    item={i}
                    onClickMedia={onClickMedia}
                  />
                </Tooltip>
              </Marker>
            ))}
          {computedSelectedMapLayers?.includes('heatmap') &&
            heatMap &&
            heatMap?.length > 0 &&
            heatMap
              ?.filter((it) => !!it.isSelected)
              .map((it, index: number) => {
                return (
                  <HeatMapLayer
                    id={`map-${index}`}
                    data={it.address_points}
                    options={{
                      radius: it.configuration?.radius ?? 10,
                      blur: it.configuration?.blur ?? 15,
                      max: it.configuration?.max ?? 0.001,
                      scaleRadius: true,
                      gradient: it.configuration?.color_gradients
                        ? generatePickColorSet(it.configuration.color_gradients)
                        : null,
                    }}
                    key={index}
                    map={map}
                  />
                );
              })}

          {computedSelectedMapLayers.includes('assets') &&
            commtracNodeAssets?.map((i) => (
              <Marker
                key={`commtrac-node-asset-${i.id}`}
                position={[i.commtrac_pos_lat ?? 0, i.commtrac_pos_lon ?? 0]}
                icon={getAssetIcon(i)}
                interactive
                eventHandlers={{
                  click: (event) => event.target.openPopup(),
                  popupopen: (event) => {
                    event.target._tooltip.options.opacity = 0;
                  },
                  popupclose: (event) => {
                    event.target._tooltip.options.opacity = 0.9;
                  },
                }}
              >
                <Popup interactive autoClose={false} offset={[0, -32]}>
                  <CommtracNodeTooltipContent
                    disableEditMode={disableEditMode}
                    item={i}
                    onOpenItem={() => onOpenItem?.(i.id, 'asset')}
                  />
                </Popup>

                <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
                  <CommtracNodeTooltipContent
                    disableEditMode={disableEditMode}
                    item={i}
                    onOpenItem={() => onOpenItem?.(i.id, 'asset')}
                  />
                </Tooltip>
              </Marker>
            ))}

          {computedSelectedMapLayers.includes('nodes') &&
            nodes?.map((i) => (
              <Marker
                key={`node-${getNodeKey(i)}`}
                position={[+i.latitude, +i.longitude]}
                icon={getNodeIcon(i)}
                interactive
                eventHandlers={{
                  click: (event) => event.target.openPopup(),
                  popupopen: (event) => {
                    event.target._tooltip.options.opacity = 0;
                  },
                  popupclose: (event) => {
                    event.target._tooltip.options.opacity = 0.9;
                  },
                }}
              >
                <Popup interactive autoClose={false} offset={[0, -32]}>
                  <NodeTooltipContent
                    item={i}
                    disableEditMode={disableEditMode}
                    onOpenItem={() => {
                      if (i.node_type === 'communication_node' && i.cn_id) {
                        onOpenItem?.(i.cn_id, 'cn');
                      } else if (i.node_type === 'wifi_point' && i.wifi_id) {
                        onOpenItem?.(i.wifi_id, 'wifi');
                      }
                    }}
                    onOpenHistory={() => {
                      if (
                        i.node_type === 'communication_node' &&
                        i.cn_external_id
                      ) {
                        onOpenHistory?.(i.cn_external_id, 'commtracNodeByCn');
                      }
                    }}
                  />
                </Popup>

                <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
                  <NodeTooltipContent
                    item={i}
                    disableEditMode={disableEditMode}
                    onOpenItem={() => {
                      if (i.node_type === 'communication_node' && i.cn_id) {
                        onOpenItem?.(i.cn_id, 'cn');
                      } else if (i.node_type === 'wifi_point' && i.wifi_id) {
                        onOpenItem?.(i.wifi_id, 'wifi');
                      }
                    }}
                    onOpenHistory={() => {
                      if (
                        i.node_type === 'communication_node' &&
                        i.cn_external_id
                      ) {
                        onOpenHistory?.(i.cn_external_id, 'commtracNodeByCn');
                      }
                    }}
                  />
                </Tooltip>
              </Marker>
            ))}
          <MapEvents />
          {/* {(computedSelectedMapLayers.includes('nodes') ||
            computedSelectedMapLayers.includes('alarms') ||
            computedSelectedMapLayers.includes('heatmap')) && <MapEvents />} */}

          {computedSelectedMapLayers.includes('alarms') &&
            alarmNodes
              ?.filter(
                (it) => it?.acknowledged && it?.latitude && it?.longitude
              )
              .map((i: AlarmLogNode) => (
                <Marker
                  key={`node-${i.id}`}
                  position={[
                    i?.latitude ?? minLayerBounds?.getNorthEast()?.lat ?? 0,
                    i?.longitude ?? minLayerBounds?.getNorthEast()?.lng ?? 0,
                  ]}
                  icon={getAlarmLogIcon(i)}
                  interactive
                  eventHandlers={{
                    click: (event) => event.target.openPopup(),
                    popupopen: (event) => {
                      event.target._tooltip.options.opacity = 0;
                    },
                    popupclose: (event) => {
                      event.target._tooltip.options.opacity = 0.9;
                    },
                  }}
                >
                  <Popup interactive autoClose={false} offset={[0, -32]}>
                    <AlarmTooltipContent
                      item={i}
                      disableEditMode={disableEditMode}
                      onOpenItem={() => {
                        onOpenItem?.(i.id, 'alarm');
                      }}
                      onOpenHistory={() => {
                        onOpenHistory?.(i.id, 'alarm');
                      }}
                    />
                  </Popup>

                  <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
                    <AlarmTooltipContent
                      item={i}
                      disableEditMode={disableEditMode}
                      onOpenItem={() => {
                        onOpenItem?.(i.id, 'alarm');
                      }}
                      onOpenHistory={() => {
                        onOpenHistory?.(i.id, 'alarm');
                      }}
                    />
                  </Tooltip>
                </Marker>
              ))}

          {computedSelectedMapLayers.includes('alarms') &&
            computedSelectedMapLayers.includes('mine') &&
            acknowledgedOrInvalidPostionNodes.map((i: AlarmLogNode) => {
              return (
                <Marker
                  key={`node-${i.id}`}
                  position={topRightPositon ? topRightPositon : [0, 0]}
                  icon={getAlarmLogIcon(i)}
                  interactive
                  eventHandlers={{
                    click: (event) => event.target.openPopup(),
                    popupopen: (event) => {
                      event.target._tooltip.options.opacity = 0;
                    },
                    popupclose: (event) => {
                      event.target._tooltip.options.opacity = 0.9;
                    },
                  }}
                >
                  <Popup interactive autoClose={false} offset={[0, -32]}>
                    <AlarmTooltipContent
                      item={i}
                      disableEditMode={disableEditMode}
                      onOpenItem={() => {
                        onOpenItem?.(i.id, 'alarm');
                      }}
                      onOpenHistory={() => {
                        onOpenHistory?.(i.id, 'alarm');
                      }}
                    />
                  </Popup>

                  <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
                    <AlarmTooltipContent
                      item={i}
                      disableEditMode={disableEditMode}
                      onOpenItem={() => {
                        onOpenItem?.(i.id, 'alarm');
                      }}
                      onOpenHistory={() => {
                        onOpenHistory?.(i.id, 'alarm');
                      }}
                    />
                  </Tooltip>
                </Marker>
              );
            })}

          {computedSelectedMapLayers.includes('employee_history') &&
            employeeHistoryData?.map((i) => (
              <Marker
                key={`commtrac-node-history-employee-${getCommtracNodeHistoryId(
                  i
                )}`}
                position={[
                  +(i?.latitude || selectedLevelOption?.map?.maxx || 0),
                  +(i?.longitude || selectedLevelOption?.map?.maxy || 0),
                ]}
                icon={getEmployeeHistoryIcon()}
                eventHandlers={{
                  click: (event) => event.target.openPopup(),
                  popupopen: (event) => {
                    event.target._tooltip.options.opacity = 0;
                  },
                  popupclose: (event) => {
                    event.target._tooltip.options.opacity = 0.9;
                  },
                }}
              >
                <Popup interactive autoClose={false} offset={[0, -32]}>
                  <CommtracNodeHistoryTooltipContent timestamp={i.timestamp} />
                </Popup>

                <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
                  <CommtracNodeHistoryTooltipContent timestamp={i.timestamp} />
                </Tooltip>
              </Marker>
            ))}

          {computedSelectedMapLayers.includes('asset_history') &&
            assetHistoryData?.map((i) => (
              <Marker
                key={`commtrac-node-history-asset-${getCommtracNodeHistoryId(
                  i
                )}`}
                position={[
                  +(i.latitude || selectedLevelOption?.map?.maxx || 0),
                  +(i.longitude || selectedLevelOption?.map?.maxy || 0),
                ]}
                icon={getAssetHistoryIcon()}
                eventHandlers={{
                  click: (event) => event.target.openPopup(),
                  popupopen: (event) => {
                    event.target._tooltip.options.opacity = 0;
                  },
                  popupclose: (event) => {
                    event.target._tooltip.options.opacity = 0.9;
                  },
                }}
              >
                <Popup interactive autoClose={false} offset={[0, -32]}>
                  <CommtracNodeHistoryTooltipContent timestamp={i.timestamp} />
                </Popup>

                <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
                  <CommtracNodeHistoryTooltipContent timestamp={i.timestamp} />
                </Tooltip>
              </Marker>
            ))}

          {computedSelectedMapLayers.includes('commtrac_node_by_cn_history') &&
            commtracNodeByCnEventsData?.map((i) => (
              <Marker
                key={`commtrac-node-by-cn-history-${getCommtracNodeByCnHistoryId(
                  i
                )}`}
                position={[
                  +(i.pos_lat || selectedLevelOption?.map?.maxx || 0),
                  +(i.pos_lon || selectedLevelOption?.map?.maxy || 0),
                ]}
                icon={getCommtracNodeByCnHistoryIcon(i)}
                eventHandlers={{
                  click: (event) => event.target.openPopup(),
                  popupopen: (event) => {
                    event.target._tooltip.options.opacity = 0;
                  },
                  popupclose: (event) => {
                    event.target._tooltip.options.opacity = 0.9;
                  },
                }}
              >
                <Popup interactive autoClose={false} offset={[0, -32]}>
                  <CommtracNodeHistoryTooltipContent timestamp={i.date} />
                </Popup>

                <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
                  <CommtracNodeHistoryTooltipContent timestamp={i.date} />
                </Tooltip>
              </Marker>
            ))}

          {computedSelectedMapLayers.includes('safeye_nodes') &&
            safeyeNodes?.map((item) => (
              <Marker
                key={`safeye_node-${item.id}`}
                position={[
                  +(item.latitude || selectedLevelOption?.map?.maxx || 0),
                  +(item.longitude || selectedLevelOption?.map?.maxy || 0),
                ]}
                icon={getSafeyeNodeIcon(item)}
                eventHandlers={{
                  click: (event) => event.target.openPopup(),
                  popupopen: (event) => {
                    event.target._tooltip.options.opacity = 0;
                  },
                  popupclose: (event) => {
                    event.target._tooltip.options.opacity = 0.9;
                  },
                }}
              >
                <Popup interactive autoClose={false} offset={[0, -32]}>
                  <SafeyeNodeTooltipContent item={item} />
                </Popup>

                <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
                  <SafeyeNodeTooltipContent item={item} />
                </Tooltip>
              </Marker>
            ))}
        </MapContainer>
      </Box>
    </Box>
  );
};
