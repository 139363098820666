import {Alert, Backdrop, CircularProgress} from '@mui/material';
import {t} from 'i18next';
import update from 'immutability-helper';
import {isEmpty, values} from 'lodash';
import {useCallback, useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useConfiguration} from '../../../hooks/configuration';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {CommtracNodeListResponse} from '../../../interfaces/CommtracNode';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  OpenedEntity,
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import {getMsFromS} from '../../../utils/datetime';
import AssetHumanItemUpsert from '../../asset-human/AssetHumanItemUpsert';
import AssetHumanUnassignedList from '../../asset-human/AssetHumanUnassignedList';
import AssetHumanGroupChat from '../../asset-human-group/AssetHumanGroupChat';
import AssetMachineItemUpsert from '../../asset-machine/AssetMachineItemUpsert';
import AssetMachineUnassignedList from '../../asset-machine/AssetMachineUnassignedList';
import ModalDraggable from '../../common/ModalDraggable';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {
  CommtracNodesReportData,
  getCommtracNodesReportData,
} from '../ConnectView/CommtracNodesReport';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import EmployeeAssetsGridReport from './EmployeeAssetsGridReport';

export interface EmployeesAssetsViewData {
  viewType: 'commtracNodes';
  commtracNodes: CommtracNodesReportData;
}

const getConnectViewData = (): EmployeesAssetsViewData => ({
  viewType: 'commtracNodes',
  commtracNodes: getCommtracNodesReportData(),
});

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => void;
}

export const EmployeeAssetsGrid = ({value, onUpdate, onOpenHistory}: Props) => {
  const [panel] = usePanel();
  const [config, setConfig] =
    useState<EmployeesAssetsViewData>(
      !isEmpty(value) ? value as EmployeesAssetsViewData : getConnectViewData()
    );

  const [isShownGroupChat, setIsShownGroupChat] = useState(false);
  const [fetchedCommtracNodesInProgress, setFetchedCommtracNodesInProgress] =
    useState(false);
  const [fetchedCommtracNodesData, setFetchedCommtracNodesData] =
    useState<CommtracNodeListResponse>();
  const [fetchedCommtracNodesErrors, setFetchedCommtracNodesErrors] = useState<
    string[]
  >([]);

  const [isUnassignedEmployeesOpened, setIsUnassignedEmployeesOpened] =
    useState(false);
  const [isUnassignedAssetsOpened, setIsUnassignedAssetsOpened] =
    useState(false);

  const fetchCommtracNodes = useCallback(async () => {
    setFetchedCommtracNodesInProgress(true);
    setFetchedCommtracNodesErrors([]);
    try {
      const params = {
        ...config.commtracNodes.params,
        standby: config.viewType !== 'commtracNodes',
      };
      const resp = await API.get<CommtracNodeListResponse>(
        `${apiBaseUrl}/commtrac-node`,
        {params}
      );
      setFetchedCommtracNodesData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedCommtracNodesErrors(messages);
    }
    setFetchedCommtracNodesInProgress(false);
  }, [config.commtracNodes.params]);

  const [openedEmployees, setOpenedEmployees] = useState<OpenedEntity>({});
  const [openedAssets, setOpenedAssets] = useState<OpenedEntity>([]);
  const handleOpenItem = (
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode
  ) => {
    if (entity === 'employee') {
      if (!(id in openedEmployees)) {
        setOpenedEmployees({
          ...openedEmployees,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'asset') {
      if (!(id in openedAssets)) {
        setOpenedAssets({
          ...openedAssets,
          [id]: {mode, id},
        });
      }
    }
  };

  useEffect(() => {
    fetchCommtracNodes();
    onUpdate?.({...config});
  }, [config.commtracNodes.params]);

  const commtracNodeRefresh = useConfiguration(
    'auto-refresh',
    'commtrac_miner_autorefresh_rate'
  );
  const commtracNodeRefreshInterval = commtracNodeRefresh?.value
    ? getMsFromS(+commtracNodeRefresh?.value)
    : null;
  useRefreshInterval(fetchCommtracNodes, commtracNodeRefreshInterval);

  return (
    <>
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Backdrop
        open={fetchedCommtracNodesInProgress}
        sx={{position: 'absolute', zIndex: 1199}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedCommtracNodesErrors.map((error, idx) => (
        <Alert
          key={`error-c-${idx}`}
          severity="error"
          onClose={fetchCommtracNodes}
        >
          {error}
        </Alert>
      ))}
      <EmployeeAssetsGridReport
        value={config.commtracNodes}
        onChange={(v) =>
          setConfig(
            update(config, {
              commtracNodes: {
                $set: v ?? getCommtracNodesReportData(),
              },
            })
          )
        }
        loading={fetchedCommtracNodesInProgress}
        fetchedData={fetchedCommtracNodesData}
        onOpenHistory={onOpenHistory}
        onRefresh={fetchCommtracNodes}
        onOpenItem={handleOpenItem}
        onChangeIsShownGroupChat={setIsShownGroupChat}
        onUnassignedEmployeesOpen={() => {
          setIsUnassignedEmployeesOpened(true);
        }}
        onUnassignedAssetsOpen={() => {
          setIsUnassignedAssetsOpened(true);
        }}
      />
      <ModalDraggable open={isShownGroupChat}>
        <AssetHumanGroupChat onClose={() => setIsShownGroupChat(false)} />
      </ModalDraggable>
      {values(openedEmployees).map(({id, mode}) => (
        <ModalDraggable key={`employee-${id}`} open={true}>
          <AssetHumanItemUpsert
            pk={id}
            mode={mode === 'update' || mode === 'chat' ? mode : 'view'}
            onPurged={() => {
              fetchCommtracNodes();
              setOpenedEmployees(update(openedEmployees, {$unset: [id]}));
            }}
            onOpenHistory={(item) => {
              if (item.commtrac_node_id) {
                onOpenHistory?.(item.commtrac_node_id, 'employee');
                setOpenedEmployees(update(openedEmployees, {$unset: [id]}));
              }
            }}
            onSubmitted={() => {
              fetchCommtracNodes();
            }}
            onClose={() => {
              setOpenedEmployees(update(openedEmployees, {$unset: [id]}));
            }}
          />
        </ModalDraggable>
      ))}

      {values(openedAssets).map(({id, mode}) => (
        <ModalDraggable key={`asset-${id}`} open={true}>
          <AssetMachineItemUpsert
            pk={id}
            mode={mode === 'update' ? mode : 'view'}
            onPurged={() => {
              fetchCommtracNodes();
              setOpenedAssets(update(openedAssets, {$unset: [id]}));
            }}
            onOpenHistory={(item) => {
              if (item.commtrac_node_id) {
                onOpenHistory?.(item.commtrac_node_id, 'asset');
                setOpenedAssets(update(openedAssets, {$unset: [id]}));
              }
            }}
            onSubmitted={() => {
              fetchCommtracNodes();
            }}
            onClose={() => {
              setOpenedAssets(update(openedAssets, {$unset: [id]}));
            }}
          />
        </ModalDraggable>
      ))}
      <ModalDraggable
        open={isUnassignedEmployeesOpened}
        sx={{
          width: 1000,
          left: 'calc(50% - 500px)',
        }}
      >
        <AssetHumanUnassignedList
          onOpenItem={(id) => handleOpenItem(id, 'employee')}
          onClose={() => setIsUnassignedEmployeesOpened(false)}
        />
      </ModalDraggable>

      <ModalDraggable
        open={isUnassignedAssetsOpened}
        sx={{
          width: 1000,
          left: 'calc(50% - 500px)',
        }}
      >
        <AssetMachineUnassignedList
          onOpenItem={(id) => handleOpenItem(id, 'asset')}
          onClose={() => setIsUnassignedAssetsOpened(false)}
        />
      </ModalDraggable>
    </>
  );
};
