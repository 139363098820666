import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {
  AssetMachine,
  AssetMachineListQuery,
  AssetMachineListResponse,
} from '../../interfaces/AssetMachineAdmin';
import reduxSelectors from '../../redux/selectors';
import {getHumanReadable} from '../../utils/macAddress';
import AccessControl from '../common/AccessControl';
import DataGrid, {DataGridColumn, DataGridRef} from '../common/DataGrid';
import AssetMachineItemUpsertButton from './AssetMachineItemUpsertButton';

interface Props {}

export interface AssetMachineListRef {
  fetch?: () => void;
  dataGridRef: MutableRefObject<DataGridRef | null>;
}

const AssetMachineList = forwardRef<AssetMachineListRef, Props>((__, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(formik.values),
    dataGridRef,
  }));

  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);
  const paperBg = isDarkMode ? '#222222' : '#FFF';
  const zones = useAppSelector(({assets}) => assets.zones);
  const sites = useAppSelector(({assets}) => assets.sites);
  const machineTypes = useAppSelector(({assets}) => assets.machine_types);
  const machineInputs = useAppSelector(({assets}) => assets.machine_inputs);
  const productsEnabled = useAppSelector(reduxSelectors.assets.productsEnabled);

  const statusOptions: {
    value: AssetMachineListQuery['status'];
    label: string;
  }[] = [
    {value: 'all', label: 'All'},
    {value: 'active', label: 'Active'},
    {value: 'inactive', label: 'Inactive'},
  ];

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<AssetMachineListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async (params: AssetMachineListQuery) => {
    setFetchedInProgress(true);
    try {
      const resp = await API.get<AssetMachineListResponse>(
        `${apiBaseUrl}/asset-machine`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  /*************/
  /* data grid */
  /*************/
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<AssetMachine>[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Edit',
      sxHeader: {textAlign: 'center'},
      sxCell: {textAlign: 'center'},
      renderCell: ({row}) => {
        return (
          <Box display="flex" gap={1} justifyContent="center">
            <AccessControl permissions={['patch::/asset-machine/:id']}>
              <AssetMachineItemUpsertButton
                pk={row.id}
                item={row}
                prefetch
                component={IconButton}
                componentProps={{color: 'primary', size: 'small'}}
                onSubmitted={() => fetchData(formik.values)}
                mode="update"
              >
                <EditIcon />
              </AssetMachineItemUpsertButton>
            </AccessControl>
          </Box>
        );
      },
    },
    {
      field: 'name',
      sortable: true,
    },
    {
      field: 'zone_id',
      headerName: 'Section',
      sortable: true,
      valueGetter: ({row}) => zones.find((i) => i.id === row.zone_id)?.name,
      hidden: !(
        productsEnabled.includes('connect') ||
        productsEnabled.includes('proximity')
      ),
    },
    {
      field: 'site_id',
      headerName: 'Site',
      sortable: true,
      valueGetter: ({row}) => sites.find((i) => i.id === row.site_id)?.name,
      hidden: !productsEnabled.includes('hazard'),
    },
    {
      field: 'type_id',
      headerName: 'Type',
      sortable: true,
      valueGetter: ({row}) =>
        machineTypes.find((i) => i.id === row.type_id)?.name,
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Network ID',
      sortable: true,
      hidden: !productsEnabled.includes('connect'),
    },
    {
      field: 'mac_address',
      headerName: 'MAC-address',
      sortable: true,
      hidden: !productsEnabled.includes('connect'),
      valueGetter: ({row}) => {
        if (row.mac_address) {
          return getHumanReadable(row.mac_address);
        }
      },
    },
    {
      field: 'external_id',
      headerName: 'Proximity ID',
      sortable: true,
      hidden: !productsEnabled.includes('proximity'),
    },
    {
      field: 'safeye_node_id',
      headerName: 'Safeye ID',
      sortable: true,
      hidden: !productsEnabled.includes('hazard'),
    },
    {
      field: 'status',
      sortable: true,
      renderCell: ({row}) => {
        return row.status === 'active' ? (
          <ToggleOnIcon color="success" />
        ) : (
          <ToggleOffIcon color="error" />
        );
      },
    },
    {
      field: 'commtrac_report_frequency',
      headerName: 'Reporting Frequency',
      hidden: !productsEnabled.includes('connect'),
      valueGetter: ({row}) => row?.commtrac_node?.commtrac_report_frequency,
    },
    {
      field: 'input_1_type',
      headerName: 'Input 1',
      sortable: true,
      hidden: !productsEnabled.includes('proximity'),
      valueGetter: ({row}) =>
        machineInputs.find((i) => i.id === row.input_1_type)?.name,
    },
    {
      field: 'input_2_type',
      headerName: 'Input 2',
      sortable: true,
      hidden: !productsEnabled.includes('proximity'),
      valueGetter: ({row}) =>
        machineInputs.find((i) => i.id === row.input_2_type)?.name,
    },
    {
      field: 'input_3_type',
      headerName: 'Input 3',
      sortable: true,
      hidden: !productsEnabled.includes('proximity'),
      valueGetter: ({row}) =>
        machineInputs.find((i) => i.id === row.input_3_type)?.name,
    },
    {
      field: 'input_4_type',
      headerName: 'Input 4',
      sortable: true,
      hidden: !productsEnabled.includes('proximity'),
      valueGetter: ({row}) =>
        machineInputs.find((i) => i.id === row.input_4_type)?.name,
    },
    {
      field: 'input_5_type',
      headerName: 'Input 5',
      sortable: true,
      hidden: !productsEnabled.includes('proximity'),
      valueGetter: ({row}) =>
        machineInputs.find((i) => i.id === row.input_5_type)?.name,
    },
    {
      field: 'date_activated',
      headerName: 'Activated Timestamp',
      sortable: true,
    },
    {
      field: 'date_deactivated',
      headerName: 'Deactivated Timestamp',
      sortable: true,
    },
  ];

  const formik = useFormik<AssetMachineListQuery>({
    initialValues: {
      page: 0,
      limit: 25,
      order: 'id',
      dir: 'ASC',
      status: 'all',
      filter: null,
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    fetchData(formik.values);
  }, [formik.values]);

  return (
    <Paper
      sx={{
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        p: 3,
        bgcolor: paperBg,
        backgroundImage: 'none',
      }}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" height="100%">
        <Box pb={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="left"
            height={40}
            width={200}
            gap={2}
          >
            <Box flexGrow={1} display="flex" width="200px" height="100%">
              <TextField
                fullWidth
                label="Status"
                select
                SelectProps={{
                  multiple: false,
                }}
                value={formik.values.status}
                onChange={(el) => {
                  formik.setFieldValue('status', el.target.value);

                  if (el.target.value === 'all') {
                    formik.setFieldValue('filter', null);
                  } else {
                    formik.setFieldValue(
                      'filter',
                      JSON.stringify({
                        type: 'and',
                        filter: [
                          {
                            field: 'status',
                            op: 'in',
                            val: [el.target.value],
                          },
                        ],
                      })
                    );
                  }
                }}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.value ?? ''} value={option.value ?? ''}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </Box>
        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          loading={fetchedInProgress}
          pagination
          paginationMode="server"
          size="small"
          sx={{
            backgroundColor: 'transparent',
            th: {
              bgcolor: paperBg,
            },
          }}
          sortBy={{
            field: formik.values.order,
            dir: formik.values.dir === 'DESC' ? 'desc' : 'asc',
          }}
          sortingMode="server"
          page={formik.values.page}
          pageSize={formik.values.limit}
          rowCount={fetchedData?.count}
          onPageChange={(v) => formik.setFieldValue('page', v)}
          onPageSizeChange={(v) => {
            formik.setFieldValue('limit', v);
            formik.setFieldValue('page', 0);
          }}
          onSort={(v) => {
            if (v) {
              formik.setFieldValue('order', v.field);
              formik.setFieldValue('dir', v.dir.toUpperCase());
            }
          }}
        />
      </Box>
    </Paper>
  );
});

export default AssetMachineList;
